import MUISelect from "@material-ui/core/Select";
import React, { forwardRef } from "react";
import styled from "styled-components";

import { primaryBlack } from "constants/color";
import { Icon } from "components";

import "./style.css";

const CustomSelect = styled(MUISelect)`
  & .MuiSelect-select {
    position: relative;
    min-width: 190px;
    border: 0.5px solid #dadde4;
    border-radius: 6px;
    background: #ffffff;
    box-sizing: border-box;
    max-height: 32px;
    font-size: 12px;
    padding-left: 16px;

    &:focus {
      border-radius: 6px;
      background: #ffffff;
      border: 0.5px solid #dadde4;
    }
  }

  & .MuiSelect-icon {
    top: 50%;
    margin-top: -0.45em;
    margin-right: 16px;
  }
`;

type Props = {
  value?: any;
  defaultValue?: any;
  onChange?: (event: any) => void;
  className?: string;
  disabled?: boolean;
};

const Select: React.FC<Props> = forwardRef(
  ({ children, value, onChange, className, disabled }, ref) => {
    return (
      <CustomSelect
        disabled={disabled}
        className={className}
        IconComponent={(props) => (
          <Icon
            icon="SelectArrowIcon"
            size="s"
            color={primaryBlack}
            {...props}
          />
        )}
        ref={ref}
        onChange={onChange}
        value={value}
        disableUnderline
        MenuProps={{
          classes: { paper: "select-paper", list: "select-list" },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {children}
      </CustomSelect>
    );
  }
);

export default Select;
