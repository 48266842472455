import {
  CloseIcon,
  Container,
  Description,
  Name,
  NotificationsIcon,
} from "./styledComponents";

type Props = {
  name: string;
  description: string;
};

const NotificationListItem = ({ name, description }: Props) => {
  return (
    <Container>
      <NotificationsIcon icon="InformationIcon" />
      <Name>{name}</Name>
      <Description>{description}</Description>
      <CloseIcon size="s" icon="CloseIcon" />
    </Container>
  );
};

export default NotificationListItem;
