import { Button } from "components";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading5FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const ControlPanel = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)`
  margin-left: 24px;
  height: 32px;
`;

export const AllPayments = styled.div`
  color: #1f2021;
  font-size: 1em;
  font-weight: 500;
  margin-top: 24px;
  text-align: center;
  padding: 0 0 20px 0;
`;
