import {
  removePromoLecture,
  setCabinetData,
  setStatusWebinar,
} from "pages/Cabinet/redux/reducer";
import {
  setIsLectureComing,
  setLectureId,
  setLectureTeacherName,
  setOnlineLectureLoaded,
  setOnlineLectureLoading,
  setLectureName,
  setLectureStartDate,
  setLectureStartTime,
} from "pages/OnlineLecture/redux";
import { TThunkDispatch } from "store";

import centrifuge from "./Centrifuge";
import { subscribeToTaskChannel } from "./InteractiveTaskAPI";
import { ILecture } from "./models/lecture";
import { subscribeToLectureChatChannel } from "./UserLectureChatAPI";

type Res = {
  data: {
    webinar: ILecture;
  };
};

export const getOnlineLectureActionCreator = () => {
  return (dispatch: TThunkDispatch) => {
    dispatch(setOnlineLectureLoading(true));

    const intervalId = setInterval(() => {
      centrifuge.rpc({ method: "webinar/get", data: {} }).then(
        function (res: Res) {
          clearInterval(intervalId);
          const webinar = res.data.webinar;

          const webinar_status = webinar.webinar_status;
          const isLectureComing = webinar_status.id === 1;

          if (isLectureComing) {
            dispatch(setOnlineLectureIsComing());
          }

          dispatch(setOnlineLectureInfo(webinar));

          dispatch(setOnlineLectureIsLoaded());

          const webinarChannel = "webinar" + webinar.id;
          const chatChannel = "chat" + webinar.id;
          const taskChannel = "task" + webinar.id;

          dispatch(subscribeToWebinarChannel(webinarChannel));
          dispatch(subscribeToLectureChatChannel(chatChannel));
          dispatch(subscribeToTaskChannel(taskChannel));
        },
        function (err) {
          if (err.code === 1404) {
            clearInterval(intervalId);
            dispatch(setOnlineLectureLoading(false));
            dispatch(setOnlineLectureLoaded(false));
            dispatch(setIsLectureComing(false));
          }
          console.log("rpc error", err);
        }
      );
    }, 300);
  };
};

const setOnlineLectureInfo =
  (webinar: ILecture) => (dispatch: TThunkDispatch) => {
    const {
      topic: { name },
      lesson_date,
      time_start,
      id,
      teacher,
    } = webinar;

    const teacherName = teacher.username;

    dispatch(setLectureStartDate(lesson_date));
    dispatch(setLectureStartTime(time_start));
    dispatch(setLectureName(name));
    dispatch(setLectureId(id));
    dispatch(setLectureTeacherName(teacherName));
  };

const setOnlineLectureIsLoaded = () => (dispatch: TThunkDispatch) => {
  dispatch(setOnlineLectureLoading(false));
  dispatch(setOnlineLectureLoaded(true));
};

const setOnlineLectureIsComing = () => (dispatch: TThunkDispatch) => {
  dispatch(setIsLectureComing(true));
};

export const subscribeToWebinarChannel =
  (webinarChannel: string) => (dispatch: TThunkDispatch) => {
    centrifuge.subscribe(webinarChannel, function (res) {
      if (res.data.action === "start") {
        dispatch(setCabinetData(res.data.webinar));
      }
      if (res.data.action === "webinar/pause") {
        dispatch(setStatusWebinar(res.data.pause));
      }
      if (res.data.action === "webinar/start") {
        dispatch(setStatusWebinar(null));
      }
      if (res.data.action === "finish") {
        dispatch(removePromoLecture());
      }
      // if (res.data.action === "webinar/presence") {
      //     dispatch(setLoadLecturePromoUsersOnline(res.data.users_online));
      // }
    });
  };
