import styled from "styled-components";

import Button from "./CommonButton";

const SecondaryButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.secondaryGreyBorder};
  font-size: ${(props) => props.theme.fontSizes.subtitle1FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  padding: 20px 24px;
  border-radius: 12px;

  &:hover {
    background-color: ${(props) => props.theme.colors.secondaryGreyHover};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.secondaryGreyActive};
  }
`;

export default SecondaryButton;
