import { ContentContainer } from "containers";
import styled from "styled-components";

export const Container = styled(ContentContainer)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 98px 120px;
  color: ${(props) => props.theme.colors.primaryBlack};

  span {
    display: block;
    font-size: ${(props) => props.theme.fontSizes.heading1FontSize};
    color: ${(props) => props.theme.colors.primaryGreen};
    font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
    margin-top: 16px;
  }
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading3FontSize};
  font-weight: ${(props) => props.theme.fontWeights.skinnyFontWeight};
  margin-bottom: 28px;
`;

export const Name = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading2FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  margin-bottom: 56px;
`;

export const Begin = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading4FontSize};
  font-weight: ${(props) => props.theme.fontWeights.skinnyFontWeight};
  margin-bottom: 32px;
`;

export const Left = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading4FontSize};
  font-weight: ${(props) => props.theme.fontWeights.skinnyFontWeight};
`;
