import { Icon } from "components";
import styled from "styled-components";

export const AccountCardContainer = styled.div`
  border: 0.5px solid ${(props) => props.theme.colors.secondaryLightGray};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 12px 24px 12px 16px;
  max-width: 292px;
  width: 100%;

  @media screen and (max-width: 500px) {
    width: 120px;
  }
`;

export const FullName = styled.div`
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  margin-left: 16px;

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  color: ${(props) => props.theme.colors.secondaryGrey};
  margin-left: 16px;
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
