import styled from "styled-components";
import { Item } from "./styledComponents";

const Container = styled.div``;

const DocumentsList = () => {
  return (
    <Container>
      <Item name="Договор" />
      <Item name="Правила оказания образовательных услуг" />
    </Container>
  );
};

export default DocumentsList;
