import { FoldedHeader, HeaderControls, HeaderList } from "../../components";
import { HeaderContainer } from "./styledComponents";

type Props = {
  isOpen: boolean;
  onTabClick: (tab: string) => void;
  onArrowClick: () => void;
  onMouseDown: (e: any) => void;
  tab: string;
};

const Header = ({
  isOpen,
  onTabClick,
  onArrowClick,
  tab,
  onMouseDown,
}: Props) => {
  const handleHeaderContainerClick = () => {
    if (!isOpen) {
      onArrowClick();
    }
  };

  const renderContent = () => {
    if (isOpen) {
      return (
        <>
          <HeaderControls
            onMouseDown={onMouseDown}
            onArrowClick={onArrowClick}
          />
          <HeaderList
            onTabClick={onTabClick}
            tab={tab}
            onMouseDown={onMouseDown}
          />
        </>
      );
    }

    return <FoldedHeader />;
  };

  return (
    <HeaderContainer isOpen={isOpen} onClick={handleHeaderContainerClick}>
      {renderContent()}
    </HeaderContainer>
  );
};

export default Header;
