import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import Button from "./CommonButton";
import { ButtonSize, ButtonTheme } from "./types";

type Props = {
  onClick?: (event: React.SyntheticEvent) => void;
  className?: string;
  leftIcon?: string;
  leftIconColor?: string;
  leftIconSize?: "s" | "m" | "l" | "xl";
  size?: ButtonSize;
  buttonTheme?: ButtonTheme;
};

const StyledButton = styled(Button)`
  background: ${(props) => props.theme.colors.primaryWhite};
  border: 1px solid ${(props) => props.theme.colors.secondaryRed};
  border-radius: 9px;
  color: ${(props) => props.theme.colors.secondaryRed};
  font-size: ${(props) => props.theme.fontSizes.body1FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  width: 100%;
  padding: 12px 24px;

  /* &:hover {
    background: ${(props) => props.theme.colors.secondaryRed};
  }

  &:active {
    background: ${(props) => props.theme.colors.secondaryRed};
  } */
`;

const ErrorButtonSecondary = ({
  children,
  className,
  onClick,
}: PropsWithChildren<Props>) => {
  return (
    <StyledButton className={className} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default ErrorButtonSecondary;
