import { useSelector } from "react-redux";
import { RootState } from "store";
import { PropsWithChildren } from "react";

type Props = {
  minWidth?: number;
  maxWidth?: number;
};

const MediaQueryContainer = ({
  minWidth = Number.MIN_VALUE,
  maxWidth = Number.MAX_VALUE,
  children,
}: PropsWithChildren<Props>) => {
  const width = useSelector((state: RootState) => state.clientOptions.width);

  if (minWidth > width || maxWidth <= width) {
    return null;
  }

  return <>{children}</>;
};

export default MediaQueryContainer;
