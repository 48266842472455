import { Option } from "components";
import { ServicesTitle, Container, StyledSelect } from "./styledComponents";

type Props = {
  className?: string;
  selectValue: string;
  onChange: (e: any) => void;
  service?: string;
};

const Services = ({ className, selectValue, onChange }: Props) => {
  return (
    <Container className={className}>
      <ServicesTitle>Выберите услугу</ServicesTitle>
      <StyledSelect
        value={selectValue}
        onChange={onChange}
        disabled={Boolean(selectValue)}
      >
        <Option value={selectValue}>{selectValue}</Option>
      </StyledSelect>
    </Container>
  );
};

export default Services;
