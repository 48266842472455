import { ProgressCircle } from "components";
import { Button, Select } from "components";
import { LabelContainer } from "containers";
import styled from "styled-components";

export const Progress = styled(ProgressCircle)`
  width: 40px;
  height: 40px;
  display: block;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

export const StyledSelect = styled(Select)`
  margin-bottom: 16px;
  align-self: flex-end;
  width: 190px;
`;

export const DateBlock = styled.div`
  font-size: 14px;
`;

export const MainText = styled.div`
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const StyledButton = styled(Button)`
  height: 32px;
  margin: 0 auto;
`;

interface StatusProps {
  status: number;
}

export const Label = styled(LabelContainer)<StatusProps>`
  color: ${(props) =>
    props.status === 3
      ? props.theme.colors.primaryGreen
      : props.theme.colors.secondaryYellow};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  height: 32px;
  padding: 0 8px;
  text-align: center;
  line-height: 14px;
  font-size: 12px;
  background: ${(props) =>
    props.status === 3
      ? props.theme.colors.opacityGreen10
      : props.theme.colors.opacityYellow10};
  border: 1px solid
    ${(props) =>
      props.status === 3
        ? props.theme.colors.primaryGreen
        : props.theme.colors.secondaryYellow};
  box-sizing: border-box;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Status = styled.div`
  font-size: ${(props) => props.theme.fontSizes.captionFontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;
