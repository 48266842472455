import { Tabs } from "components";
import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin-right: 32px;
`;

export const TabsPanelContainer = styled.div`
  border: 0.5px solid ${(props) => props.theme.colors.secondaryLightGray};
  border-radius: 0 16px 16px 16px;
  height: 840px;
`;

export const StyledTabs = styled(Tabs)`
  width: 449px;
`;
