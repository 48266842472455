import {
  DefaultTableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components";
import moment from "moment";
import {
  getInteractiveTaskStats,
  LectureVideo,
} from "pages/LectureArchive/redux";
import { useSelector } from "react-redux";
import { DateBlock, Theme, Title, Status, Label } from "./styledComponents";
import { StyledButton } from "../../../LectureArchive/components/ArchiveTable/styledComponents";
import { toLectureVideoPath } from "../../../../constants/paths";
import { useHistory } from "react-router";
import { PromoBannerContainer } from "../PromoBanner/styledComponents";

type Props = {
  archiveLessonList: LectureVideo[];
};

const ArchivesTable = ({ archiveLessonList }: Props) => {
  const history = useHistory();
  const interactiveTaskStats = useSelector(getInteractiveTaskStats);
  const stats = interactiveTaskStats?.lessons;

  if (!archiveLessonList.length) {
    return (
      <PromoBannerContainer appBarIsOpen={true} hideBackgroundImage={true}>
        <Title>Архив лекций пока пуст</Title>
      </PromoBannerContainer>
    );
  }

  return (
    <DefaultTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Дата</TableCell>
            <TableCell align="left">№</TableCell>
            <TableCell align="left">Статус</TableCell>
            <TableCell scope="row">Тема лекции</TableCell>
            <TableCell align="center">Интерактивные задания</TableCell>
            {/*<TableCell align="center">Билеты ПДД</TableCell>
            <TableCell align="center">Психофизиологические тесты</TableCell> */}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {archiveLessonList.map((item, index) => (
            <TableRow key={item.id}>
              <TableCell align="left">
                <DateBlock>
                  {moment(item.webinar_finished_at * 1000).format("DD.MM.YY")}
                </DateBlock>
              </TableCell>
              <TableCell scope="row">
                <Theme>{++index}</Theme>
              </TableCell>
              <TableCell align="left">
                <Status>
                  <Label status={3}>Просмотрено</Label>
                </Status>
              </TableCell>
              <TableCell scope="row">
                <Theme>{item.topic.name} 1</Theme>
              </TableCell>
              <TableCell align="center" valign="middle">
                {stats && (
                  <>
                    {stats[item.id].interactive_tasks_success} /{" "}
                    {stats[item.id].interactive_tasks_total}
                  </>
                )}
                {/*<Progress*/}
                {/*  progress={*/}
                {/*    stats && stats[item.id]*/}
                {/*      ? +(*/}
                {/*          (stats[item.id].interactive_tasks_success * 100) /*/}
                {/*          stats[item.id].interactive_tasks_total*/}
                {/*        ).toFixed(0)*/}
                {/*      : 0*/}
                {/*  }*/}
                {/*  tasksCount={*/}
                {/*    stats && stats[item.id]*/}
                {/*      ? +stats[item.id].interactive_tasks_success.toFixed(0) +*/}
                {/*        "/" +*/}
                {/*        stats[item.id].interactive_tasks_total.toFixed(0)*/}
                {/*      : 0*/}
                {/*  }*/}
                {/*/>*/}
              </TableCell>
              {/*<TableCell align="center" valign="middle">
                <Progress progress={Math.round(Math.random() * 100)}></Progress>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Progress progress={Math.round(Math.random() * 100)}></Progress>
              </TableCell> */}

              <TableCell align="center" valign="middle">
                <StyledButton
                  size="small"
                  onClick={() => history.push(toLectureVideoPath(item.id))}
                >
                  Смотреть
                </StyledButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DefaultTableContainer>
  );
};

export default ArchivesTable;
