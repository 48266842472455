import React from "react";
import styled from "styled-components";
import * as icons from "icons";
import { primaryBlack } from "constants/color";

const iconCollection: { [key: string]: React.ComponentType } = { ...icons };

type Props = {
  color?: string;
  size?: "s" | "m" | "l" | "xl";
  icon: string;
  onClick?: (e: any) => void;
  onMouseDown?: (e: any) => void;
  onMouseUp?: () => void;
};

type StyledProps = {
  color?: string;
  size: string;
};

const IconContainer = styled.svg`
  fill: ${(props: StyledProps) => props.color};
  width: ${(props: StyledProps) => props.size};
  height: ${(props: StyledProps) => props.size};
`;

const Icon = ({ icon, size = "m", color = primaryBlack, ...props }: Props) => {
  const CurrentIcon: React.ComponentType = iconCollection[icon];

  let pixelSize = "16px";

  if (size === "m") pixelSize = "24px";
  if (size === "l") pixelSize = "32px";
  if (size === "xl") pixelSize = "72px";

  return (
    <IconContainer size={pixelSize} color={color} {...props}>
      <CurrentIcon />
    </IconContainer>
  );
};

export default Icon;
