import { getToken } from "utils";
import { isDev } from "utils";

export const API_URL = isDev()
  ? `https://api.${process.env.REACT_APP_DOMAIN}/`
  : process.env.REACT_APP_APIURL;
export const ONLINE_CHECKER_API_URL = `https://ch.${process.env.REACT_APP_DOMAIN}`;

export const getHeaderWithAuthorization = () => {
  return { Authorization: "Bearer ".concat(getToken()!) };
};
