import styled from "styled-components";
import { PropsWithChildren, ReactNode } from "react";

type Props = {
  value?: any;
  index: any;
  onClick?: (value: any) => void;
  title?: string | ReactNode;
};

const TabsItemContainer = styled.div<{ active?: boolean }>`
  border-radius: 16px 16px 0 0;
  width: 274px;
  font-size: 18px;

  background-color: ${(props) =>
    props.active ? props.theme.colors.primaryGreen : "none"};
  font-weight: ${(props) =>
    props.active
      ? props.theme.fontWeights.normalFontWeight
      : props.theme.fontWeights.superSkinnyFontWeight};
  color: ${(props) =>
    props.active
      ? props.theme.colors.primaryWhite
      : props.theme.colors.primaryBlack};

  @media screen and (max-width: 910px) {
    font-size: 0.75em;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const TabsItem = ({
  value,
  onClick,
  index,
  children,
  title,
}: PropsWithChildren<Props>) => {
  const handleOnClick = () => {
    onClick!(index);
  };

  return (
    <TabsItemContainer active={value === index} onClick={handleOnClick}>
      {children || title}
    </TabsItemContainer>
  );
};

export default TabsItem;
