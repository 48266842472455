import { getLecturePromo, getLoadLecturePromoUsers } from "pages";
import { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "layout";
import { Container, StyledButton, Subtitle, Title } from "./styledComponents";
import { LecturePromoType } from "pages/Cabinet/redux/reducer";
import styled from "styled-components";

const WrapperTimer = styled.div`
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
`;

const PreviewWebinarInfo = () => {
  const lecturePromo = useSelector(getLecturePromo);
  const lecturePromoLoad = useSelector(getLoadLecturePromoUsers);
  const isComing = useMemo(
    () =>
      lecturePromo?.webinar_finished_at && !lecturePromo.webinar_finished_at,
    [lecturePromo]
  );

  const dispatch = useDispatch();

  const handleStartWebinar = () => {
    if (!isComing) {
      dispatch(openModal({ name: "confirmStartWebinarModal" }));
      return;
    }
  };

  if (lecturePromoLoad) {
    return (
      <Container>
        <Title>Загрузка...</Title>
      </Container>
    );
  }

  if (!lecturePromo) {
    return (
      <Container>
        <Title>Нет активного вебинара</Title>
      </Container>
    );
  }

  return (
    <Container>
      <Title>Вебинар по теме</Title>
      <Subtitle>{lecturePromo.topic.name}</Subtitle>
      {lecturePromo ? <Timer lecturePromo={lecturePromo} /> : null}
      <StyledButton onClick={handleStartWebinar}>
        {!isComing ? "Начать вебинар" : "Вернуться к вебинару"}
      </StyledButton>
    </Container>
  );
};

export default PreviewWebinarInfo;

const Timer = ({ lecturePromo }: { lecturePromo: LecturePromoType }) => {
  const [timeLeft, setTimeLeft] = useState<Date>(new Date());
  const startFormatDate: Date = useMemo(
    () => new Date(lecturePromo.time_start * 1000),
    [lecturePromo.time_start]
  );

  const startDateString = new Date(
    lecturePromo.time_start * 1000
  ).toLocaleDateString();

  const updateTime = () => {
    setTimeLeft(new Date(startFormatDate.getTime() - new Date().getTime()));
  };

  useEffect(() => {
    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [startFormatDate]);

  const dayToStart =
    timeLeft.getUTCDate() - 1 <= 0 ? "" : `${timeLeft.getUTCDate() - 1} д`;

  return (
    <WrapperTimer>
      {timeLeft.getTime() > 0 ? (
        <>
          начнется <span>{startDateString}</span>
          <br />
          осталось
          <br />
          <span>{`${dayToStart} ${timeLeft.getUTCHours()} ч ${timeLeft.getUTCMinutes()} мин ${timeLeft.getUTCSeconds()} сек`}</span>
        </>
      ) : (
        "Должна идти в данный момент"
      )}
    </WrapperTimer>
  );
};
