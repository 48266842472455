import { Icon } from "components";
import { CustomScrollContainer } from "containers";
import { ChatMessage } from "shared";
import styled from "styled-components";

type Props = {
  isOpen?: boolean;
};

export const SupportWidgetContainer = styled.div<Props>`
  position: fixed;
  bottom: 0;
  right: 32px;
  z-index: 999;
  height: ${(props) => (props.isOpen ? "400px" : "44px")};
  min-height: ${(props) => (props.isOpen ? "400px" : "none")};
  max-height: calc(100vh - 30px);
  width: 274px;
  min-width: 274px;
  max-width: 353px;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  border-radius: 12px 12px 0 0;
  border: ${(props) =>
    props.isOpen ? `1px solid ${props.theme.colors.primaryGreen}` : "none"};
  border-bottom: none;
  box-sizing: border-box;

  @media screen and (max-width: 500px) {
    right: 8px;
    width: calc(100% - 16px);
  }
`;

export const Header = styled.div<Props>`
  height: 44px;
  border-radius: 12px 12px 0 0;
  background-color: ${(props) => props.theme.colors.primaryGreen};
  margin: -1px -1px 0;
  padding: 0 24px;
  font-size: ${(props) => props.theme.fontSizes.subtitle1FontSize};
  color: ${(props) => props.theme.colors.primaryWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  cursor: ${(props) => (props.isOpen ? "move" : "pointer")};
`;

export const BodyChatContainer = styled(CustomScrollContainer)`
  display: flex;
  flex-direction: column;
  height: calc(100% - 44px);
  overflow: auto;
  padding: 16px;
  padding-bottom: 0;
  box-sizing: border-box;
  position: relative;

  &::-webkit-scrollbar-track {
    margin-bottom: 24px;
  }

  & > :nth-child(1) {
    margin-top: auto !important;
  }
`;

export const Message = styled(ChatMessage)<{ isYour: boolean }>`
  margin-bottom: 16px;
  width: 237px;
  box-sizing: border-box;

  background-color: ${(props) =>
    props.isYour
      ? props.theme.colors.opacityGreen10
      : props.theme.colors.opacityGrey5};
  align-self: ${(props) => (props.isYour ? "flex-end" : "auto")};
`;

export const Arrow = styled(Icon)<{ isRotate?: boolean }>`
  cursor: pointer;
  transform: ${(props) => (props.isRotate ? "rotate(180deg)" : "rotate(0deg)")};
`;

export const InputContainer = styled.form`
  position: sticky;
  bottom: 0;
  padding-bottom: 24px;
  background-color: ${(props) => props.theme.colors.primaryWhite};
`;
