import { CustomScrollContainer, DefaultModalContainer } from "containers";
import styled from "styled-components";

export const Container = styled(DefaultModalContainer)`
  max-width: 840px;
  max-height: 720px;
  width: 100%;
  background: #fff;
`;

export const Body = styled(CustomScrollContainer)`
  border: 0.5px solid ${(props) => props.theme.colors.secondaryLightGray};
  padding: 24px;
  max-height: calc(720px - 100px);
  border-radius: 0 12px 12px 12px;
  background: #fff;
`;

export const CloseButton = styled.button`
  width: 137px;
  height: 40px;
  background: #4ac365;
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  color: #ffffff;
  margin: 20px auto 0 auto;
  display: block;
`;
