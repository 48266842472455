import { ChatMessage } from "shared";
import styled from "styled-components";

export const MessageContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const Avatar = styled.div`
  height: 48px;
  width: 48px;
  margin-right: 24px;
  border-radius: 50%;
  background: #ccc;
  min-width: 48px;
  min-height: 48px;
`;

export const ChatMessageWrapper = styled(ChatMessage)<{
  isYour: boolean;
}>`
  padding: 24px;
  width: 100%;
  max-width: 1000px;
  background-color: ${(props) =>
    props.isYour
      ? props.theme.colors.opacityGreen10
      : props.theme.colors.opacityGrey5};
`;
