import { getLoadLecturePromoUsers } from "pages";
import { useSelector } from "react-redux";
import { PreviewWebinarInfo, WebinarGroupInfo } from "..";

const PreviewWebinar = () => {
  const lecturePromoLoad = useSelector(getLoadLecturePromoUsers);

  return (
    <>
      <PreviewWebinarInfo />
      {lecturePromoLoad && <WebinarGroupInfo preview />}
    </>
  );
};

export default PreviewWebinar;
