import { StatusQuestion } from "pages";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 32px 32px 0 32px;
  box-sizing: border-box;

  @media screen and (max-width: 944px) {
    padding: 16px 16px 0 16px;
  }
`;
export const WrapperInfo = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 16px;

  @media screen and (max-width: 944px) {
    font-size: 0.75em;
  }
`;

export const WrapperList = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;

  &::-webkit-scrollbar {
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &::-webkit-scrollbar-thumb {
    max-height: 50px;
    border-radius: 8px;
    background: #6a7d9b;
  }
`;

export const Item = styled.div`
  min-width: 40px;
  max-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3d3d4b;
  border-radius: 4px;
  margin-right: 8px;
  font-weight: 600;
  cursor: pointer;
  background: ${({ status }: { status: StatusQuestion }) => {
    switch (status) {
      case "ok":
        return "rgba(74, 195, 101, 0.2)";
      case "wrong":
        return "rgba(252, 94, 94, 0.2)";
      case "no-answer":
        return "rgba(218, 221, 228, 0.2)";
      default:
        return "";
    }
  }};

  @media screen and (max-width: 944px) {
    width: 24px;
    height: 24px;
    min-width: 24px;
    max-width: 24px;
    font-size: 0.75em;
  }
`;

export const Text = styled.p`
  font-size: 1.5em;
  font-weight: 600;
  margin: 0;
`;

export const Time = styled.p`
  margin: 0;
`;
