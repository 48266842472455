import { PropsWithChildren } from "react";

type Props = {
  value: any;
  index: any;
};

const TabsPanel = ({ value, children, index }: PropsWithChildren<Props>) => {
  if (index !== value) {
    return null;
  }

  return <>{children}</>;
};

export default TabsPanel;
