const Clock = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_671_391)">
        <path
          d="M12.9484 7.2C12.9484 6.86863 12.6798 6.6 12.3484 6.6H12.3334C12.002 6.6 11.7334 6.86863 11.7334 7.2V12.6L15.337 14.7996C15.6112 14.9641 15.9668 14.8766 16.1333 14.6035C16.3021 14.3267 16.2122 13.9654 15.9334 13.8L12.9334 12L12.9484 7.2Z"
          fill="#62686B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.3334 3.9C13.3971 3.9 14.4504 4.10951 15.4331 4.51658C16.4159 4.92364 17.3088 5.52028 18.061 6.27244C18.8131 7.02459 19.4098 7.91753 19.8168 8.90027C20.2239 9.883 20.4334 10.9363 20.4334 12C20.4334 14.1483 19.58 16.2085 18.061 17.7276C16.5419 19.2466 14.4817 20.1 12.3334 20.1C7.8541 20.1 4.2334 16.455 4.2334 12C4.2334 9.85175 5.08679 7.79148 6.60583 6.27244C8.12488 4.75339 10.1851 3.9 12.3334 3.9ZM12.3334 5.1C13.2395 5.1 14.1368 5.27848 14.9739 5.62523C15.8111 5.97199 16.5717 6.48024 17.2124 7.12096C17.8532 7.76169 18.3614 8.52234 18.7082 9.35949C19.0549 10.1966 19.2334 11.0939 19.2334 12C19.2334 13.83 18.5064 15.585 17.2124 16.879C15.9184 18.173 14.1634 18.9 12.3334 18.9C8.51918 18.9 5.4334 15.7946 5.4334 12C5.4334 10.17 6.16036 8.41497 7.45436 7.12096C8.74836 5.82696 10.5034 5.1 12.3334 5.1Z"
          fill="#62686B"
        />
      </g>
      <defs>
        <clipPath id="clip0_671_391">
          <rect x="0.333374" width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Clock;
