import { useState, useRef, useCallback, useMemo } from "react";
import { playSvg, pauseSvg } from "../../../components/VideoPlayer/svgs";
import styled from "styled-components";
import { LectureVideo } from "../../LectureArchive/redux";
import ReactPlayer from "react-player";
import VideoPlayerControl from "../../../components/VideoPlayer/VideoPlayerControl";
import { sendWatchVideoBaseCourse } from "../../../api/CabinetPromoApi";

type VideoPlayerProps = {
  url: string;
  info?: any;
  videoData: LectureVideo;
};

export const rates: { [key: number]: number } = {
  1: 1,
  2: 1.25,
  3: 1.5,
  4: 1.75,
  5: 2,
};

export const Video = ({ url, info }: VideoPlayerProps) => {
  const videoElement = useRef<ReactPlayer | null>(null);
  const [isPlay, setPlay] = useState(false);
  const [activeTime, setActiveTime] = useState(0);
  const [rate, setRate] = useState(1);
  const [maxTime, setMaxTime] = useState(0);

  const handleTimeUpdate = useCallback((event: any) => {
    setActiveTime(event.playedSeconds);
  }, []);

  const onDuration = useCallback((val: number) => {
    setMaxTime(val);
  }, []);

  const setCurrentTime = (time: number) => {
    if (videoElement.current) {
      videoElement.current.seekTo(time);
    }
  };

  const play = useMemo(() => {
    return (
      <Play onClick={() => setPlay(!isPlay)}>
        {isPlay ? pauseSvg() : playSvg()}
      </Play>
    );
  }, [isPlay]);

  const playBigButton = useMemo(() => {
    return (
      <PlayBig onClick={() => setPlay(!isPlay)}>
        {!isPlay && playSvg(50, 50)}
      </PlayBig>
    );
  }, [isPlay]);

  const setPlayTestFalse = (val: boolean) => {
    setPlay(val);
  };

  return (
    <Wrapper>
      <Backlog onClick={() => setPlay(!isPlay)} isPlay={isPlay} />
      <Player onClick={() => setPlay(!isPlay)}>
        <ReactPlayer
          controls={false}
          playbackRate={rates[rate]}
          playing={isPlay}
          ref={videoElement}
          onProgress={handleTimeUpdate}
          onDuration={onDuration}
          width="100%"
          height="100%"
          style={{ zIndex: 1, position: "relative" }}
          url={url}
          onEnded={() => {
            sendWatchVideoBaseCourse(info.id);
          }}
        />
      </Player>
      <VideoButtonsWrapper>{play}</VideoButtonsWrapper>
      <VideoButtonsBigWrapper>{playBigButton}</VideoButtonsBigWrapper>
      <VideoPlayerControl
        rate={rate}
        setRate={setRate}
        tasks={[]}
        activeTime={activeTime}
        maxTime={maxTime}
        setCurrentTime={setCurrentTime}
        info={info}
        setPlay={setPlayTestFalse}
      />
    </Wrapper>
  );
};

const Player = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

interface BacklogProps {
  isPlay: boolean;
}

const Backlog = styled.div<BacklogProps>`
  display: ${(props) => {
    return props.isPlay ? "none" : "block";
  }};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 5;
`;

const VideoButtonsWrapper = styled.div`
  position: absolute;
  z-index: 7;
  margin: auto;
  bottom: 11px;
  left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 456px;
  height: 100px;
`;

const Button = styled.button`
  border: 0 !important;
  outline: 0 !important;
  width: 50px;
  height: 50px;
  padding: 0;
  background: transparent;
  opacity: 0.8;
  cursor: pointer;
  z-index: 10;

  &:hover {
    opacity: 1;
  }
`;

const VideoButtonsBigWrapper = styled.div`
  position: absolute;
  z-index: 10;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 456px;
  height: 100px;
`;

const Play = styled(Button)``;
const PlayBig = styled(Button)``;
