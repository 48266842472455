import {
  getCabinetLecturePromoUsers,
  getCabinetPromoLectureActionCreator,
} from "api/CabinetPromoApi";
import centrifuge from "api/Centrifuge";
import { getLecturePromo } from "pages";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useCentrifugeConnected = () => {
  const dispatch = useDispatch();
  const [connectionState, setConnectionState] = useState(0);
  const lecturePromo = useSelector(getLecturePromo);

  useEffect(() => {
    centrifuge.on("connect", () => {
      setConnectionState(1);
    });
  }, []);

  useEffect(() => {
    if (lecturePromo && lecturePromo.webinar_started_at) {
      dispatch(getCabinetLecturePromoUsers(lecturePromo.id));
    }
  }, [dispatch, lecturePromo]);

  useEffect(() => {
    if (connectionState) {
      dispatch(getCabinetPromoLectureActionCreator());
      // dispatch(getCabinetPromoLectureWithoutSubscribeActionCreator());
    }
  }, [dispatch, connectionState]);

  return connectionState;
};
