import { withStyles } from "@material-ui/core";
import MUISlider, { SliderProps } from "@material-ui/core/Slider";
import { opacityRed20, secondaryRed } from "constants/color";

const PrettoSlider = withStyles({
  root: {
    color: secondaryRed,
    height: 8,
  },
  thumb: {
    height: 36,
    width: 36,
    backgroundColor: opacityRed20,
    borderColor: opacityRed20,
    border: "3px solid",
    marginTop: -13,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 15.32px)",
    fontSize: 20,
    width: 30,
    height: 30,
    borderRadius: "50%",
    overflow: "hidden",
    backgroundColor: secondaryRed,
    top: 10,
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(MUISlider);

const Slider = (props: SliderProps) => {
  return (
    <PrettoSlider
      aria-label="pretto slider"
      defaultValue={20}
      valueLabelDisplay="on"
      {...props}
    />
  );
};

export default Slider;
