const formatDuration = (timeStamp: number): string => {
  const minutes = Math.floor(timeStamp / 60);
  const seconds = Math.floor(timeStamp % 60);

  let formattedMinutes = minutes.toString();

  if (formattedMinutes.length === 1) {
    formattedMinutes = `0${formattedMinutes}`;
  }

  let formattedSeconds = seconds.toString();

  if (formattedSeconds.length === 1) {
    formattedSeconds = `0${formattedSeconds}`;
  }

  return `${formattedMinutes}:${formattedSeconds}`;
};

export default formatDuration;

export const getRightEnding = (n: number): string => {
  let nMod100 = Math.abs(n) % 100;
  let nMod10 = nMod100 % 10;

  if (nMod100 > 10 && nMod100 < 20) {
    return `${n} раз`;
  } else if (nMod10 > 1 && nMod10 < 5) {
    return `${n} раза`;
  } else if (nMod10 === 1) {
    return `${n} раз`;
  } else {
    return `${n} раз`;
  }
};
