import { Button } from "components";
import { DefaultModalContainer } from "containers";
import styled from "styled-components";

export const Container = styled(DefaultModalContainer)`
  max-width: 728px;
  width: 100%;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading3FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.boldFontWeight};
  text-align: center;
`;

export const Subtitle = styled.div`
  margin-top: 64px;
  font-size: ${(props) => props.theme.fontSizes.heading6FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;

export const SkipButton = styled(Button)`
  margin-right: 16px;
`;

export const StyledRatingContainer = styled.div`
  display: flex;
  justify-content: center;
  transform: scale(2);
  margin-top: 32px;
`;
