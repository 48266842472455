import axios from "axios";
import { setUserRole, setUserUsername } from "reducers";
import { API_URL, getHeaderWithAuthorization } from "./API";
import centrifuge from "./Centrifuge";
import { TThunkDispatch } from "store";
import { removeToken } from "utils";
import { setLessons, setLoader } from "reducers";
import {
  setLectureArchive,
  setInteractiveTaskStats,
} from "pages/LectureArchive/redux";
import { setTickets } from "pages";
import { setWebinarList } from "pages/Cabinet/redux/reducer";
import { setUserId, setUserName } from "reducers/userInfo";
import {closeModal, setNotification} from "layout";
import { setCookie } from "../utils/localStorage.util";

export const userLoginActionCreator = ({
  login,
  password,
  handleError,
  pushToPath,
}: {
  login: string;
  password: string;
  handleError: () => void;
  pushToPath: (path: string) => void;
}) => {
  return (dispatch: TThunkDispatch) =>
    axios
      .post(API_URL + "v1/auth/login", {
        username: login,
        password: password,
      })
      .then(({ data }) => {
        dispatch(closeModal("LoginModal"));
        setCookie("authJwt", data.token, {
          secure: true,
          "max-age": 3600 * 48,
        });
        centrifuge.setToken(data.token);
        dispatch(getUserInfoActionCreator(pushToPath));
        pushToPath("/");
      })
      .catch(() => {
        handleError();
      });
};

export const getUserInfoActionCreator = (
  pushToPath: (path: string) => void
) => {
  return (dispatch: TThunkDispatch) => {
    centrifuge.connect();
    axios
      .get(API_URL + "v2/auth/state", {
        headers: getHeaderWithAuthorization(),
      })
      .then((res) => {
        dispatch(setUserName(`${res.data.firstname} ${res.data.lastname}`));
        dispatch(setUserUsername(res.data.username));
        dispatch(setUserRole(res.data.role));
        dispatch(setUserId(res.data.id));
        dispatch(getMainData());
      })
      .catch(() => {
        removeToken();
        pushToPath("/login");
      });
  };
};

export const userLogoutActionCreator = () => {
  return () => {
    centrifuge.disconnect();
    removeToken();
  };
};

export const getSchoolInfo = async (school: string) => {
  return await axios.get(`${API_URL}v2/school/${school}`);
};

export const deleteTerminateSessions = () => {
  return () => {
    axios
      .delete(API_URL + "v1/auth/terminate-sessions", {
        headers: getHeaderWithAuthorization(),
      })
      .then(() => {});
  };
};

export const getLectureArchiveList = () => {
  return (dispatch: TThunkDispatch) => {
    axios
      .get(API_URL + "v1/webinar/archive", {
        headers: getHeaderWithAuthorization(),
      })
      .then((archive) => {
        dispatch(setLectureArchive(archive.data));
      });
  };
};

export const getMainData = () => {
  return (dispatch: TThunkDispatch) => {
    Promise.all([
      axios.get(API_URL + "v1/pdd-ticket/numbers", {
        headers: getHeaderWithAuthorization(),
      }),
      axios.get(API_URL + "v1/pdd-ticket/topics", {
        headers: getHeaderWithAuthorization(),
      }),
      axios.post(
        API_URL + "v2/webinar/list?size=999",
        {},
        {
          headers: getHeaderWithAuthorization(),
        }
      ),
      axios.get(API_URL + "v1/stat/interactive-task", {
        headers: getHeaderWithAuthorization(),
      }),
    ])
      .then(([numbers, topics, webinars, interactiveStats]) => {
        dispatch(setTickets(numbers.data));
        dispatch(setLessons(topics.data));

        dispatch(setInteractiveTaskStats(interactiveStats.data));
        dispatch(setWebinarList(webinars.data.items));
        dispatch(setLoader({ value: false }));
      })
      .catch((err) => {
        dispatch(
          setNotification({
            title: "Ошибка",
            message: `error code: ${err.code}, message: ${err.message}`,
            status: "error",
          })
        );
      });
  };
};

// export const getMainData = () => {
//   return (dispatch: TThunkDispatch) => {
//     Promise.all([
//       axios.get(API_URL + "v1/pdd-ticket/numbers", {
//         headers: getHeaderWithAuthorization(),
//       }),
//       axios.get(API_URL + "v1/pdd-ticket/questions", {
//         headers: getHeaderWithAuthorization(),
//       }),
//       axios.get(API_URL + "v1/pdd-ticket/topics", {
//         headers: getHeaderWithAuthorization(),
//       }),
//       axios.get(API_URL + "v1/webinar/archive", {
//         headers: getHeaderWithAuthorization(),
//       }),
//       axios.get(API_URL + "v1/webinar/list", {
//         headers: getHeaderWithAuthorization(),
//       }),
//       axios.get(API_URL + "v1/stat/interactive-task", {
//         headers: getHeaderWithAuthorization(),
//       })
//     ])
//       .then(([numbers, questions, topics, archive, webinars, interactiveStats]) => {
//         dispatch(setTickets(numbers.data));
//         dispatch(setQuestions(questions.data));
//         dispatch(setLessons(topics.data));
//         dispatch(setLectureArchive(archive.data));
//         dispatch(setInteractiveTaskStats(interactiveStats.data));
//         dispatch(setWebinarList(webinars.data.list));
//         dispatch(setLoader({ value: false }));
//       })
//       .catch(console.log);
//   };
// };
