import VK from "./assets/images/VK.svg";
import WA from "./assets/images/WA.svg";
import TG_COLORED from "./assets/images/Frame.svg";
import TG from "./assets/images/TG.svg";
import Mockup from "./assets/images/Mockup_1.png";
import styled from "styled-components";
import FooterLogo1 from './assets/images/Vector1.svg';
import FooterLogo2 from './assets/images/Vector2.svg';
import FooterLogo3 from './assets/images/Vector3.png';
import footerLogo from './assets/images/footer-logo.svg';
export default function FooterComponent({data}) {
  const mobile = window.innerWidth<=768;
  function  TelegramComponent() {
    const Title = styled.div`
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 36px;
      line-height: 41px;
      color: white;
      @media (max-width: 768px){
      font-size: 20px!important;
      line-height: 22px!important;
      }
    `;
    const TgIcon = styled.div`
      width: 70px;
      height: 70px;
      background: url(${TG_COLORED});
      background-size: cover;
    `;

    const MockupImage = styled.div`
      width: 406px;
      height: 320px;
      position: absolute;
      right: 40px;
      bottom: 0;
      background: url(${Mockup});
      background-size: cover;
      @media (max-width: 768px){
         position: relative;
         right: auto;
         width: 320px;
         height: 252px;
         margin-inline: auto;
      }
         @media (max-width: 400px){
         width: 240px;
           height: 189px;
         }
    `;
    const Text = styled.p`
      line-height: 22px;
      font-size: 18px;
      color: white;
      @media (max-width: 768px){
      max-width: 147px;
      }
    `;
    const HorizontalWrapper = styled.a`
      display: flex;
      gap: 16px;
      align-items: center;
      @media (max-width: 768px){
      gap: 50px;
      }
    `;
    const Wrapper = styled.div`
      display: flex;
      flex-direction: column;
    `;
    const Container = styled.div`
      border-radius: 30px;
      position: relative;
      margin-bottom: 70px;

      background-color: #29a0d9;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      max-width: calc(100% - 50px);
        width: 100%;
      padding: 40px;
      margin-inline: auto;
      padding-right: 466px;
      margin-top: -40px;
        @media (max-width: 768px){
    padding: 30px 30px 0 30px;
       max-width: calc(100% - 20px);
    flex-direction: column;
    }
    `;
    return (
      <>
        <Container>
          <Wrapper>
            <Title>
              Хотите узнать о ПДД.ТВ больше и познакомиться с учениками?
            </Title>
            <HorizontalWrapper
              as="a"
              target="_blank"
              href={data.partner_telegram}
            >
              <Text>Присоединяйтесь к нам в Telegram</Text>
              <TgIcon />
            </HorizontalWrapper>
          </Wrapper>
          <MockupImage></MockupImage>
        </Container>
      </>
    );
  }
  const Container = styled.div`
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: #17171b;
  `;
  const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
    padding: 70px 0 10px 0;
    max-width: calc(100% - 80px);
    border-top: 1px solid #ffffff7f;
    border-bottom: 1px solid #ffffff7f;
    margin: 0 auto;
    @media (max-width: 768px){
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 20px;
    border-bottom: 0;
    >div {
    align-items: center;
    }
    }
  `;

  const HorizontalWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 40px;
        @media (max-width: 768px){
        margin-bottom: 20px;
        }
  `;
  const BottomHorizontalWrapper = styled(HorizontalWrapper)`
   @media (max-width: 768px){
        flex-direction: column;
        gap: 10px;
        align-items: center;
        >p {
        margin-top: 0;
        font-size: 14px;
        }
        }
  `
  const TextSlider = styled.div`
  display: flex;
  
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  margin-bottom: 40px;
  `;
  const TextSliderLine = styled.ul`
  display: flex;
  max-width: 99999px;
 min-width: fit-content;
  flex-wrap: nowrap;
  list-style-type: disc!important;
  animation: textSlide${props=>props.ln} 30s linear infinite;
gap: 20px;
  `
  const TextSliderLineReverse = styled(TextSliderLine)`
align-self: flex-end;
  `
  const TextSliderItem = styled.li`
  font-size: ${props=>props.fz}px;
  line-height: ${props=>props.fz}px;
  font-weight: 600;
white-space:nowrap;

  margin-left:  ${props=>props.fz}px;
  text-transform: uppercase;
  list-style-type: disc!important;
  color: white;
  `
  const FooterIcon = styled.div`
    background: url(${(props) => props.src});
    background-size: cover;
    width: 60px;
    height: 60px;
    @media (max-width: 768px){
    width: 50px;
    height: 50px;
    }
  `;
  const FooterLogoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 40px;
gap: 20px;
  `;
  const FooterLogoContainerItem = styled.div`
  background: url(${props=>props.src});
  width: ${props=>props.width}px;
    height: ${props=>props.height}px;
    background-size: cover;
    opacity: .5;

  `
  const FooterBoldText = styled.h6`
    line-height: 25px;
    font-size: 16px;
    opacity: 0.5;
    font-weight: 600;
    color: white;
  `;
  const FooterText = styled.p`
    margin-bottom: 12px;
    color: #ffffff;
    font-size: 16px;
    line-height: 25px;
  `;
  const FooterLink = styled.a`
    margin-bottom: 12px;
    color: #ffffff;
    line-height: 25px;
    text-decoration: underline !important;
    font-size: 16px;
  `;
  const FooterGrayLink = styled.a`
    margin-bottom: 12px;
    color: #ffffff;
    text-decoration: underline !important;
    opacity: 0.5;
    line-height: 25px;
    font-size: 16px;
  `;
  const FooterSmallGrayText = styled.p`
    color: #ffffff;
    margin-top: 16px;
    opacity: 0.5;
   
    line-height: 25px;
    font-size: 12px;
  `;
  const FooterBottomSmallGrayText = styled.p`
  color: #ffffff;

  opacity: 0.5;
  max-width: 289px;
  line-height: 14px;
  font-size: 10px;
`;
  const FooterLeft = styled.div`
    display: flex;
    flex-direction: column;
  `;
  const FooterRight = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  `;
  const MinContainer = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #ffffff7f;
flex-direction: column;
    max-width: calc(100% - 80px);
    margin: 0 auto;
    gap: 12px;
padding: 20px 0;
  `
  const MinContainerImage = styled.div`
  width: 207px;
  height: 248px;
  opacity: .5;
background: url(${footerLogo});
background-size: cover;
  `
  return (
    <>
      <Container>
        <TelegramComponent />
        <TextSlider>
            <TextSliderLine ln={1}>
                <TextSliderItem fz={60}>
                Москва
                </TextSliderItem>
                <TextSliderItem fz={60}>
                санкт-петербург
                </TextSliderItem>
                <TextSliderItem fz={60}>
                Новосибирск
                </TextSliderItem>
                <TextSliderItem fz={60}>
                екатеринбург
                </TextSliderItem>
                <TextSliderItem fz={60}>
                Казань
                </TextSliderItem>
                <TextSliderItem fz={60}>
                Красноярск
                </TextSliderItem>
                <TextSliderItem fz={60}>
                Москва
                </TextSliderItem>
                <TextSliderItem fz={60}>
                санкт-петербург
                </TextSliderItem>
                <TextSliderItem fz={60}>
                Новосибирск
                </TextSliderItem>
            </TextSliderLine>
            <TextSliderLineReverse ln={2}>
            <TextSliderItem fz={50}>
                самара
                </TextSliderItem>
                <TextSliderItem fz={50}>
                уфа
                </TextSliderItem>
                <TextSliderItem fz={50}>
                челябинск
                </TextSliderItem>
            <TextSliderItem fz={50}>
                омск
                </TextSliderItem>
            <TextSliderItem fz={50}>
                краснодар
                </TextSliderItem>
                <TextSliderItem fz={50}>
                Ростов-на-Дону
                </TextSliderItem>
                <TextSliderItem fz={50}>
                самара
                </TextSliderItem>
                <TextSliderItem fz={50}>
                уфа
                </TextSliderItem>
                <TextSliderItem fz={50}>
                челябинск
                </TextSliderItem>

            </TextSliderLineReverse>
            <TextSliderLine ln={3}>
                <TextSliderItem fz={40}>
                Волгоград
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Саратов
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Тюмень
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Тольятти
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Махачкала
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Барнаул
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Ижевск
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Хабаровск
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Иркутск
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Владивосток
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Волгоград
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Саратов
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Тюмень
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Тольятти
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Махачкала
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Барнаул
                </TextSliderItem>
                <TextSliderItem fz={40}>
                Ижевск
                </TextSliderItem>
            </TextSliderLine>
        </TextSlider>
        {mobile && <>
        <MinContainer>
        <MinContainerImage />
        <FooterBottomSmallGrayText style={{textAlign: 'center'}}>Образовательные услуги оказываются ПАО «Светофор Групп» на основании Лицензии № Л035−1 298−77/408 576 от 9 июня 2022 года и партнёрами.</FooterBottomSmallGrayText>

        </MinContainer>
        </>}
        <Footer>
          <FooterLeft>
            {/*<FooterText>По вопросам маркетинга:</FooterText>*/}
            {/*<HorizontalWrapper>*/}
            {/*  <FooterIcon src={VK}></FooterIcon>*/}
            {/*  <FooterIcon src={WA}></FooterIcon>*/}
            {/*  <FooterIcon src={TG}></FooterIcon>*/}
            {/*</HorizontalWrapper>*/}
          {!mobile &&  <FooterBoldText>© PDD TV, 2024</FooterBoldText>}
          </FooterLeft>
          <FooterRight>
            <FooterText>
              197348, г. Санкт-Петербург, ул. Генерала Хрулёва, д. 13 пом. 1-Н
            </FooterText>
            {/*<FooterLink as="a" href="mailto:info@simakindigital.ru">*/}
            {/*  info@simakindigital.ru*/}
            {/*</FooterLink>*/}
            <FooterGrayLink as="a" href="/privacy">
              Политика конфидециальности
            </FooterGrayLink>
            <FooterGrayLink
              as="a"
              href="https://svetoforgroup.ru/img/content/svetofor-lic.pdf"
            >
              Лицензия на ведение образовательной деятельности
            </FooterGrayLink>
            <BottomHorizontalWrapper>
              <FooterSmallGrayText>
                Публичное акционерное общество "Светофор Групп"
              </FooterSmallGrayText>
              <FooterSmallGrayText>
                ОГРН:1177847196141 / ИНН: 7814693830
              </FooterSmallGrayText>
            </BottomHorizontalWrapper>
          </FooterRight>
        </Footer>

        <FooterLogoContainer>
{!mobile ? <><FooterLogoContainerItem src={FooterLogo1} width={205} height={56} />
<FooterBottomSmallGrayText>Образовательные услуги оказываются ПАО «Светофор Групп» на основании Лицензии № Л035−1 298−77/408 576 от 9 июня 2022 года и партнёрами.</FooterBottomSmallGrayText>
</> :   <FooterBoldText>© PDD TV, 2024</FooterBoldText>}
<FooterLogoContainerItem src={FooterLogo2} width={mobile ? 80 :167} height={mobile ? 22 :46} />

<FooterLogoContainerItem src={FooterLogo3} width={mobile ? 80 :167} height={mobile ? 20 : 40} />

        </FooterLogoContainer>
      </Container>
    </>
  );
}
