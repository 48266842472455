import ServicesTable from "../ServicesTable";
import { Title, Container } from "./styledComponents";

const Services = () => {
  return (
    <Container>
      <Title>История платежей</Title>
      <ServicesTable />
    </Container>
  );
};

export default Services;
