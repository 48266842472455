import styled from "styled-components";

export const Image = styled.img``;

export const Container = styled.div`
  display: flex;
  padding: 12px 16px;

  &:nth-child(2n) {
    background-color: ${(props) => props.theme.colors.opacityLightGrey10};
  }
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.body1FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};

  span {
    font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  }
`;

export const ControlPanel = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const WaveContainer = styled.div`
  width: 270px;
  height: 40px;
  flex: 1;
`;

export const Info = styled.div`
  width: 100%;
`;
