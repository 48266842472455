import { ONLINE_CHECKER_API_URL } from "./API";
import { io } from "socket.io-client";

const socket = io(ONLINE_CHECKER_API_URL);

export const emitOnline = async (
  userId: string,
  webinarId: string
): Promise<Record<string, string>> => {
  socket.emit("online", userId, webinarId);
  return new Promise<Record<string, string>>((resolve) => {
    resolve({});
  });
};

export const emitOffline = async (
  userId: string,
  webinarId: string
): Promise<Record<string, string>> => {
  socket.emit("offline", userId, webinarId);
  return new Promise<Record<string, string>>((resolve) => {
    resolve({});
  });
};

export const pingSubscribe = (cb: () => void) => {
  socket.on("isOnline", cb);
};

export const pingUnsubscribe = (cb: () => void) => {
  socket.off("isOnline", cb);
};

export const onOnlineUsers = (
  setUsers: (
    value:
      | ((
          prevState: Record<string, string> | undefined
        ) => Record<string, string> | undefined)
      | Record<string, string>
      | undefined
  ) => void
) => {
  socket.on(
    "onlineUsers",
    (onlineUsers: Record<string, string> | undefined) => {
      setUsers(onlineUsers);
    }
  );
};

export const removeListenerOnlineUser = () => {
  socket.off("onlineUsers");
};
