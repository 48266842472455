const MicrophoneIcon = () => (
  <>
    <g clipPath="url(#clip0)">
      <path d="M12 14C13.66 14 14.99 12.66 14.99 11L15 5C15 3.34 13.66 2 12 2C10.34 2 9 3.34 9 5V11C9 12.66 10.34 14 12 14ZM17.3 11C17.3 14 14.76 16.1 12 16.1C9.24 16.1 6.7 14 6.7 11C6.68204 9.98864 5.22385 9.8599 5.02897 10.8525L5 11C5 14.41 7.72 17.23 11 17.72V20.5804C11.2802 21.5955 12.7198 21.5955 13 20.5804V17.72C16.28 17.24 19 14.42 19 11L18.9764 10.8853C18.7773 9.91786 17.3654 10.0145 17.3 11Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </>
);

export default MicrophoneIcon;
