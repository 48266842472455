import { primaryWhite, secondaryGrey } from "constants/color";
import { Icon } from "components";
import { ListItemContainer, Text } from "./styledComponents";
import { PropsWithChildren } from "react";

type Props = {
  active?: boolean;
  icon?: string;
};

const ListItem = ({ children, active, icon }: PropsWithChildren<Props>) => {
  return (
    <ListItemContainer active={active}>
      {icon && (
        <Icon icon={icon} color={active ? primaryWhite : secondaryGrey} />
      )}
      <Text>{children}</Text>
    </ListItemContainer>
  );
};

export default ListItem;
