import styled from "styled-components";
import { PropsWithChildren } from "react";

const LogoContainer = styled.div`
  width: 280px;
  height: 96px;
  text-align: center;
  line-height: 96px;
  font-family: ${(props) => props.theme.fonts.mainBoldFont};

  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.heading6FontSize};
`;

const Logo = ({ children }: PropsWithChildren<any>) => (
  <LogoContainer>{children}</LogoContainer>
);

export default Logo;
