import Centrifuge from "centrifuge";
import SockJS from "sockjs-client";
import { getToken } from "utils";

let centrifuge = new Centrifuge(
  `https://api.${process.env.REACT_APP_DOMAIN}:8000/connection/sockjs/`,
  {
    sockjs: SockJS,
  }
);

const token = getToken();

if (token) {
  centrifuge.setToken(getToken()!);
  centrifuge.connect();
}

export default centrifuge;
