import { useDispatch } from "react-redux";
import { closeModal } from "../../redux";
import {
  Container,
  Wrapper,
  Subtitle,
  StyledButton,
  Error,
  ErrorContainer,
  InputContainer,
} from "./styledComponents";
import { useCallback, useState } from "react";
import { Header } from "../../layout";
import { Input } from "../../../../components";
import { useFormik } from "formik";
import { object, string } from "yup";

const validationSchema = object({
  email: string().required("Необходимо заполнить «E-mail»"),
});

const PasswordForgotModal = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<null | string>("");

  const handleClose = useCallback(() => {
    dispatch(closeModal("PasswordForgotModal"));
  }, [dispatch]);

  const handleLoginError = () => {
    setError("Неверный e-mail");
  };

  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleLoginError();
    },
  });

  return (
    <Wrapper>
      <Container>
        <Header
          font={"heading6FontSize"}
          title={"Восстановление пароля"}
          onClose={handleClose}
        />
        <Subtitle>
          Введите вашу почту, на которую мы отправим инструкцию по
          восстановлению пароля.
        </Subtitle>
        <form onSubmit={form.handleSubmit}>
          {error && <Error>{error}</Error>}
          <InputContainer>
            <Input
              placeholder="Введите вашу почту"
              id="email"
              value={form.values.email}
              onChange={form.handleChange}
              error={form.touched.email && Boolean(form.errors.email)}
            />
            <ErrorContainer>
              {form.errors.email && <Error>{form.errors.email}</Error>}
            </ErrorContainer>
          </InputContainer>
          <StyledButton onClick={form.submitForm}>Отправить</StyledButton>
        </form>
      </Container>
    </Wrapper>
  );
};

export default PasswordForgotModal;
