import {
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Loader,
} from "components";
import { Name, Numbers, Container } from "./styledComponents";
import { getBillingHistory } from "../../../../api/CabinetPromoApi";
import { useEffect, useState } from "react";
import { AllPayments } from "../Payments/styledComponents";
import { NumbersBold } from "../PaymentsTable/styledComponents";

const statuses: any = {
  success: "Оплачено",
  wait: "В процессе",
  error: "Ошибка",
};

const ServicesTable = () => {
  const [data, setData] = useState<any[]>([]);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoad(true);
        const { data } = await getBillingHistory();
        setData(data.items);
        setLoad(false);
      } catch (e) {
        setLoad(false);
        console.log(e);
      }
    };

    getData();
  }, []);

  return (
    <Container>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Дата оформления</TableCell>
            <TableCell align="left" scope="row">
              Наименование
            </TableCell>
            <TableCell align="center">Номер платежа</TableCell>
            <TableCell align="center">Способ оплаты</TableCell>
            <TableCell align="center">Сумма</TableCell>
            <TableCell align="center">Статус</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item: any) => (
            <TableRow key={item.id}>
              <TableCell align="left">{item.date_order}</TableCell>
              <TableCell align="left">
                <Name>{item.name}</Name>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Numbers>{item.number}</Numbers>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Numbers>{item.method_pay}</Numbers>
              </TableCell>
              <TableCell align="center" valign="middle">
                <NumbersBold>{item.total.toLocaleString("ru")} ₽</NumbersBold>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Numbers>{statuses[item.status]}</Numbers>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {load && <Loader style={{ marginTop: 10, marginBottom: 10 }} />}
      {!load && data.length === 0 && <AllPayments>Нет данных</AllPayments>}
    </Container>
  );
};

export default ServicesTable;
