import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.secondaryGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 88px);
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.primaryWhite};
  font-size: ${(props) => props.theme.fontSizes.heading3FontSize};
  font-weight: ${(props) => props.theme.fontWeights.skinnyFontWeight};
  margin-bottom: 32px;
`;
