import React, { PropsWithChildren } from "react";
import { Button } from "components";
import styled from "styled-components";

type Props = {
  className?: string;
  onClick?: () => void;
  active?: boolean;
};

const StyledButton = styled(Button)<Props>`
  border-radius: 50%;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;

  &:hover {
    svg {
      fill: ${(props) => props.theme.colors.primaryWhite};
    }
  }

  ${({ active, theme }) =>
    active
      ? ""
      : `
      background-color:  ${theme.colors.opacityLightGrey20};
      svg { fill: #C4C4C4}
  `}
`;

const BorderContainer = styled.div<Props>`
  border: 4px solid
    ${({ active, theme }) =>
      active ? theme.colors.opacityGreen20 : "transparent"};
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;

const IconButton = ({
  children,
  className,
  onClick,
  active,
}: PropsWithChildren<Props>) => {
  return (
    <BorderContainer className={className} active={active}>
      <StyledButton onClick={onClick} active={active}>
        {children}
      </StyledButton>
    </BorderContainer>
  );
};

export default IconButton;
