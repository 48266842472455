import { createSlice } from "@reduxjs/toolkit";
import { IChatMessage } from "api/models/chat";

const onlineWebinarLectureWidgetReducer = createSlice({
  name: "appWidgetReducer",
  initialState: {
    isOpen: false,
    chatMessage: [] as IChatMessage[],
  },
  reducers: {
    toggleWebinarLectureWidgetOpen: (state) => {
      state.isOpen = !state.isOpen;
    },
    setWebinarLectureWidgetOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setLectureWidgetChatMessages: (state, action) => {
      state.chatMessage = action.payload.filter(
        (message: IChatMessage) => !!message.message
      );
    },
    addLectureWidgetChatMessage: (state, action: { payload: IChatMessage }) => {
      state.chatMessage = [...state.chatMessage, action.payload];
    },
    deleteChatUserMessages: (state, action) => {
      state.chatMessage = [
        ...state.chatMessage.filter(
          (message: IChatMessage) =>
            !(message.username === action.payload.username)
        ),
      ];
    },
    deleteChatUserMessage: (state, action) => {
      state.chatMessage = [
        ...state.chatMessage.filter(
          (message: IChatMessage) => !(message.id === action.payload.message_id)
        ),
      ];
    },
  },
});

export const {
  toggleWebinarLectureWidgetOpen,
  setWebinarLectureWidgetOpen,
  setLectureWidgetChatMessages,
  addLectureWidgetChatMessage,
  deleteChatUserMessages,
  deleteChatUserMessage,
} = onlineWebinarLectureWidgetReducer.actions;

export default onlineWebinarLectureWidgetReducer.reducer;
