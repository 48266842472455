import { KEY } from "./getToken";
import type { Workouts } from "pages/Tests/redux/reducer";

export const setCookie = (name: string, value: string, options: any) => {
  options = {
    path: "/",
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const removeToken = () => {
  document.cookie =
    KEY +
    `=; Max-Age=-99999999; domain=.${window.location.host.split(".")[1]}.ru;`;
  document.cookie = KEY + "=; Max-Age=-99999999;";
};

export const setActiveWorkoutFinished = (workoutId: string) => {
  const workouts = localStorage.getItem("workouts");
  if (!workouts) {
    return;
  }

  const workoutList: Workouts = JSON.parse(workouts);
  const updatedWorkoutList = workoutList.map((workoutItem) => {
    if (workoutItem.workoutId === workoutId) {
      return {
        ...workoutItem,
        finished: Date.now(),
      };
    }

    return workoutItem;
  });

  localStorage.setItem("workouts", JSON.stringify(updatedWorkoutList));
};
