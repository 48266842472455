import { AccountCard } from "layout/Header/components";
import styled from "styled-components";

export const AccountCardContainer = styled(AccountCard)`
  margin-left: 32px;
  min-width: 292px;
`;

export const OnlineLectureStartingHeaderContainer = styled.div`
  display: flex;
`;
