import { Button } from "components";
import React from "react";
import { closeModal } from "layout/ModalWindow/redux";
import { useDispatch } from "react-redux";
import { Container, Title, ButtonWrapper } from "./styledComponents";

type Props = {
  name: string;
};

export const ArchiveNotWatchedVideo = ({ name }: Props) => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal(name));
  };

  return (
    <Container>
      <Title>
        Пожалуйста, просмотрите лекцию от начала до конца, после этого вам будет
        доступна перемотка
      </Title>
      <ButtonWrapper>
        <Button onClick={onClose}>Закрыть</Button>
      </ButtonWrapper>
    </Container>
  );
};
