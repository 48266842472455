import {
  opacityGreen10,
  opacityLightGrey10,
  opacityRed10,
  opacityYellow10,
  primaryGreen,
  secondaryGrey,
  secondaryRed,
  secondaryYellow,
} from "constants/color";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;

  svg {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    transform: rotate(-90deg);
  }
`;

const Text = styled.p`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  position: absolute;
  z-index: 2;
  border-radius: 50%;
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
`;

type Props = {
  className?: string;
  progress?: number;
  isPercent?: boolean;
  fontSize?: number;
  tasksCount?: number | string;
  // tasksCount?: number;
};

const ProgressCircle = ({
  className,
  progress = 0,
  isPercent = false,
  fontSize = 1,
  tasksCount = 0,
}: Props) => {
  const radius = 89;
  const circumference = Math.PI * radius * 2;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  let fillColor = opacityRed10;
  let textColor = secondaryRed;
  let strokeColor = secondaryRed;

  if (progress === 0) {
    fillColor = opacityLightGrey10;
    textColor = secondaryGrey;
    strokeColor = secondaryGrey;
  }

  if (progress >= 30) {
    fillColor = opacityYellow10;
    textColor = secondaryYellow;
    strokeColor = secondaryYellow;
  }

  if (progress >= 90) {
    fillColor = opacityGreen10;
    textColor = primaryGreen;
    strokeColor = primaryGreen;
  }

  return (
    <Wrapper>
      <svg viewBox="0 0 200 200" className={className}>
        {progress > 0 && (
          <circle
            r="90"
            cx="100"
            cy="100"
            strokeWidth="16"
            fill="none"
            stroke={strokeColor}
            strokeDashoffset={0}
            strokeDasharray="251.2 502.4"
            strokeLinecap="round"
            //@ts-ignore
            style={{
              transition: `.5s all linear`,
              strokeDashoffset: strokeDashoffset,
              strokeDasharray: `${circumference} ${circumference}`,
            }}
          />
        )}
        <circle r="80" cx="100" cy="100" fill={fillColor} />
      </svg>
      <Text
        style={{
          color: textColor,
          fontSize: `${fontSize}em`,
        }}
      >
        {progress}
        {isPercent ? "%" : null}
      </Text>
    </Wrapper>
  );
};

export default ProgressCircle;
