import styled from "styled-components";
import TableContainer from "./TableContainer";

const DefaultTableContainer = styled(TableContainer)`
  border-radius: 10px;
  border: 0.5px solid ${(props) => props.theme.colors.secondaryLightGray};
  box-sizing: border-box;

  & .MuiTableCell-head {
    font-size: ${(props) => props.theme.fontSizes.body1FontSize};
    font-weight: ${(props) => props.theme.fontWeights.skinnyFontWeight};
    line-height: normal;
    padding: 0 16px;
    color: ${(props) => props.theme.colors.secondaryGrey};

    @media screen and (min-width: 1919px) {
      font-size: 1.2em;
    }
  }

  & .MuiTableRow-root {
    height: 56px;
  }

  & .MuiTableRow-root:nth-child(2n) {
    background: ${(props) => props.theme.colors.opacityLightGrey10};
  }

  & .MuiTableRow-head {
    height: 45px;
  }

  & .MuiTableCell-root {
    padding: 0 16px;
    color: ${(props) => props.theme.colors.primaryBlack};
    border-bottom: none;
  }

  & .MuiTableCell-head {
    border-bottom: 0.5px solid
      ${(props) => props.theme.colors.secondaryLightGray};
  }
`;

export default DefaultTableContainer;
