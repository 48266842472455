import styled from "styled-components";
import { Button } from "components";

export const Footer = styled.div`
  display: flex;
  margin-top: 134px;
  justify-content: center;

  button {
    margin: 0 20px;
  }
`;

export const Title = styled.div`
  font-family: ${(props) => props.theme.fonts.mainBoldFont};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.heading3FontSize};
  text-align: center;
  margin-top: 28px;
`;

export const Subtitle = styled.div`
  margin-top: 28px;
  font-size: ${(props) => props.theme.fontSizes.heading6FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  text-align: center;
`;

export const Image = styled.img`
  display: block;
  margin: 46px auto 0;
`;

export const CancelButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.secondaryGreyBorder};
  color: ${(props) => props.theme.colors.secondaryGrey};
  height: 40px;
  width: 156px;
  font-weight: ${(props) => props.theme.fontWeights.boldFontWeight};
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
`;

export const AcceptButton = styled(Button)`
  height: 40px;
  width: 156px;
`;
