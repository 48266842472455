import { closeModal, openModal } from "layout";
import {
  getWebinarId,
  removeTeacherQuestion,
  setInteractiveTasks,
  setTeacherQuestion,
} from "pages/Webinars/redux";
import { TGetState, TThunkDispatch } from "store";
import { ITask } from "./models/task";
import centrifuge from "./Centrifuge";
import { setNotification } from "layout";

type TRes = {
  data: { tasks: Array<ITask> };
};
export const getInteractiveTaskForTeacher = () => {
  return (dispatch: TThunkDispatch) =>
    centrifuge.rpc({ method: "task/get-all", data: {} }).then((res: TRes) => {
      dispatch(setInteractiveTasks(res.data.tasks));
    });
};

export const postInteractiveTasks = (taskId: number) => {
  return (dispatch: TThunkDispatch, getState: TGetState) => {
    const webinarId = getWebinarId(getState());
    const channel = "task" + webinarId;

    centrifuge
      .rpc({
        method: "task/set",
        data: { channel: channel, id: taskId },
      })
      .then(() => {});
  };
};

export const subscribeToTaskChannel = (taskChannel: string) => {
  return (dispatch: TThunkDispatch, getState: TGetState) => {
    centrifuge.subscribe(taskChannel, function (res) {
      const { userInfo } = getState();
      if (res.data.operation === "task/set") {
        const task: ITask = res.data.task;
        const { question, hint, answers, video, id, image } = task.entity;

        if (userInfo.role !== "teacher") {
          dispatch(closeModal("WebinarTaskModal"));
          dispatch(
            openModal({
              name: "WebinarTaskModal",
              videoUrl: video,
              imageUrl: image,
              question,
              hint,
              answers,
              questionId: id,
              id: res.data.task.id,
            })
          );
        } else {
          dispatch(setTeacherQuestion(res.data.task.id));
        }
      } else if (res.data.operation === "task/finish") {
        dispatch(closeModal("WebinarTaskModal"));
      }
    });
  };
};

export const setTaskSolve = (
  webinarId: number,
  id: number,
  questionId: number,
  answerId: number
) => {
  return () => {
    console.dir({ webinarId, id, questionId, answerId });
    centrifuge
      .rpc({
        method: "task/solve",
        data: {
          webinar_id: webinarId,
          id,
          question_id: questionId,
          answer_id: answerId,
        },
      })
      .catch((err) => console.error("Centrifuge error message:", err));
  };
};

export const setFinishTask = (id: number) => {
  return (dispatch: TThunkDispatch) => {
    centrifuge
      .rpc({ method: "task/finish", data: { id } })
      .then((res) => {
        dispatch(removeTeacherQuestion());
      })
      .catch((err) => {
        dispatch(
          setNotification({
            title: "Ошибка",
            message: `error code: ${err.code}, message: ${err.message}`,
            status: "error",
          })
        );
      });
  };
};
