import { Header } from "layout/ModalWindow/layout";

import image from "assets/images/Error.png";
import { useDispatch } from "react-redux";
import { closeModal } from "layout/ModalWindow/redux";
import {
  Image,
  Title,
  Container,
  Subtitle,
  StyledButton,
} from "./styledComponents";

type Props = {
  name: string;
};

const PaymentsErrorModal = ({ name }: Props) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal(name));
  };

  return (
    <Container>
      <Header onClose={handleClose} />
      <Image src={image} />
      <Title>Ошибка при оплате!</Title>
      <Subtitle>
        К сожалению, Ваш платеж отклонен. На карте недостаточно средств.
        Попробуйте оплатить другой картой
      </Subtitle>
      <StyledButton onClick={handleClose}>Повторить платеж</StyledButton>
    </Container>
  );
};

export default PaymentsErrorModal;
