import styled from "styled-components";
import { Button } from "components";

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
`;

export const CloseButton = styled(Button)`
  width: fit-content;
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
`;
