import styled from "styled-components";

export const HeaderItemContainer = styled.div<{
  active: boolean;
  revertColor?: boolean;
}>`
  cursor: pointer;
  width: 215px;
  height: 33px;
  display: flex;
  align-items: center;
  border-radius: 8px;

  background-color: ${(props) => {
    if (props.revertColor) {
      return props.active
        ? props.theme.colors.primaryGreen
        : props.theme.colors.primaryWhite;
    }
    return props.active
      ? props.theme.colors.primaryWhite
      : props.theme.colors.primaryGreen;
  }};
  color: ${(props) => {
    if (props.revertColor) {
      return props.active
        ? props.theme.colors.primaryWhite
        : props.theme.colors.primaryBlack;
    }
    return props.active
      ? props.theme.colors.primaryBlack
      : props.theme.colors.primaryWhite;
  }};
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  padding-left: 8px;
`;

export const Dot = styled.div<{
  active: boolean;
  revertColor?: boolean;
}>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => {
    if (props.revertColor) {
      return props.active
        ? props.theme.colors.primaryWhite
        : props.theme.colors.primaryGreen;
    }
    return props.active
      ? props.theme.colors.primaryGreen
      : props.theme.colors.primaryWhite;
  }};
  margin-right: 8px;
`;
