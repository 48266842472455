import { createSlice } from "@reduxjs/toolkit";

type TInitialState = {
  title: string;
  message: string;
  status: "success" | "warning" | "error" | "idle";
};

const initialState = {
  title: "",
  message: "",
  status: "idle",
} as TInitialState;

const notificationReducer = createSlice({
  name: "notificationReducer",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
    removeNotification: (state) => {
      state.title = "";
      state.message = "";
      state.status = "idle";
    },
  },
});

export const { setNotification, removeNotification } =
  notificationReducer.actions;

export default notificationReducer.reducer;
