import { primaryWhite } from "constants/color";
import checkDeviceSupport from "helpers/checkDeviceSupport";
import { openModal } from "layout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setUserDevices } from "reducers";
import image from "assets/images/soundVawe.png";
import {
  BodyVoiceQuestionContainer,
  Image,
  PrimaryButton,
  Text,
} from "./styledComponents";

const BodyVoiceQuestion = () => {
  const [voiceIsAvailable, setVoiceIsAvailable] = useState<boolean>(false);
  const [permissionInProgress, setPermissionInProgress] =
    useState<boolean>(false);
  const [isRaisedHand, setIsRaisedHand] = useState(false);
  const [conversationIsAllowed, setConversationIsAllowed] = useState(false);
  const [isMicrophoneRecording, setIsMicrophoneRecording] = useState(false);

  const [stream, setStream] = useState<null | any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    checkDeviceSupport((devices, permissions) => {
      if (permissions!.isMicrophoneAlreadyCaptured) {
        setVoiceIsAvailable(true);
      }
      dispatch(setUserDevices({ devices, permissions }));
    });
  }, [dispatch]);

  const handleClick = () => {
    setPermissionInProgress(true);
    navigator.getUserMedia(
      { audio: true, video: false },
      (stream: MediaStream) => {
        stream.getTracks().forEach((track) => track.stop());
        dispatch(
          openModal({
            name: "MicrophoneSettingsModal",
            onSubmit: () => {
              setVoiceIsAvailable(true);
              setPermissionInProgress(false);
            },
          })
        );
      },
      (error: any) => {
        if (error?.message?.includes("Requested device not found")) {
          dispatch(
            openModal({
              name: "ActivateMicrophoneErrorModal",
              onSubmit: () => handleClick(),
            })
          );
        }

        setVoiceIsAvailable(false);
        setPermissionInProgress(false);
      }
    );
  };

  const handleRaiseHand = () => {
    setIsRaisedHand(true);

    setTimeout(() => {
      setIsRaisedHand(false);
      setConversationIsAllowed(true);
    }, 1000);
  };

  const handleStartConversation = () => {
    setIsMicrophoneRecording(true);
    setConversationIsAllowed(false);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const audioContext = new AudioContext();
        const sourceNode = audioContext.createMediaStreamSource(stream);
        sourceNode!.connect(audioContext!.destination);

        setStream(stream);
      })
      .catch();
  };

  const handleEndConversation = () => {
    if (stream) {
      stream!.getTracks().forEach((track: any) => track.stop());
    }
    setIsMicrophoneRecording(false);
  };

  const renderContent = () => {
    if (isMicrophoneRecording) {
      return (
        <>
          <Image src={image} />
          <PrimaryButton
            onClick={handleEndConversation}
            leftIcon="NoMicrophoneIcon"
            leftIconColor={primaryWhite}
            leftIconSize="s"
          >
            Завершить разговор
          </PrimaryButton>
        </>
      );
    }
    if (conversationIsAllowed) {
      return (
        <>
          <Text>
            Преподаватель активировал Ваш запрос. Нажмите кнопку “Начать
            разговор”.
          </Text>
          <PrimaryButton
            onClick={handleStartConversation}
            leftIcon="MicrophoneIcon"
            leftIconColor={primaryWhite}
            leftIconSize="s"
          >
            Начать разговор
          </PrimaryButton>
        </>
      );
    }
    if (isRaisedHand) {
      return (
        <Text>
          Преподаватель увидел, что Вы подняли руку. Что бы задать Вопрос
          голосом пожалуйста, подождите. Как только преподаватель активирует Ваш
          запрос, Вы получите уведомление и сможете задать свой вопрос в прямом
          эфире.
        </Text>
      );
    }

    if (voiceIsAvailable) {
      return (
        <>
          <Text>
            Что бы задать Вопрос голосом, нажмите кнопку “Поднять руку”.
            Преподаватель увидит Ваш запрос и подключит Ваш микрофон к эфиру.
          </Text>
          <PrimaryButton
            onClick={handleRaiseHand}
            leftIcon="HandIcon"
            leftIconColor={primaryWhite}
            leftIconSize="s"
          >
            Поднять руку
          </PrimaryButton>
        </>
      );
    }

    if (permissionInProgress) {
      return (
        <Text>Нажмите кнопку “Разрешить” в появившемся всплывающем окне.</Text>
      );
    }

    if (!voiceIsAvailable) {
      return (
        <>
          <Text>
            Чтобы иметь возможность задавать вопросы голосом, необходимо
            подключить микрофон к вашему компьютеру и нажать кнопку
            “Активировать”
          </Text>
          <PrimaryButton onClick={handleClick}>Активировать</PrimaryButton>
        </>
      );
    }
  };

  return (
    <BodyVoiceQuestionContainer>{renderContent()}</BodyVoiceQuestionContainer>
  );
};

export default BodyVoiceQuestion;
