import { useState, useEffect } from "react";
import { useRouteMatch } from "react-router";

const useIsRoute = (path: string | string[]) => {
  const [isResult, setResult] = useState(false);
  const isPath = useRouteMatch(path);

  useEffect(() => {
    if (isPath && isPath.isExact !== isResult) {
      setResult(isPath.isExact);
    }
    if (!isPath) {
      setResult(false);
    }
  }, [isPath, isResult]);

  return isResult;
};

export default useIsRoute;
