import { SyntheticEvent, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Body, Header } from "./layout";
import { Resizable, ResizeCallbackData } from "react-resizable";
import {
  getIsOnlineLectureWidgetOpen,
  toggleWebinarLectureWidgetOpen,
} from "./redux";
import { getIsLectureComing } from "pages";
import { Container } from "./styledComponents";

const OnlineWebinarLectureWidget = () => {
  const isLectureComing = useSelector(getIsLectureComing);

  const [tab, setTab] = useState("chat");
  const [x, setX] = useState<string | number>("none");
  const [y, setY] = useState<string | number>("none");
  const [width, setWidth] = useState(353);
  const [height, setHeight] = useState(543);
  const containerRef = useRef<null | HTMLDivElement>(null);

  const isOpen = useSelector(getIsOnlineLectureWidgetOpen);
  const dispatch = useDispatch();

  const handleOnArrowClick = () => {
    dispatch(toggleWebinarLectureWidgetOpen());
  };

  const handleOnTabButtonClick = (tabName: string) => {
    setTab(tabName);
  };

  const handleDrugAndDropMouseDown = (e: any) => {
    let clickCoordinates = {};
    const rect = e.target.offsetParent?.getBoundingClientRect();

    if (rect) {
      clickCoordinates = {
        left: e.clientX - rect.left,
        top: e.clientY - rect.top,
        bottom: rect.bottom,
        right: rect.right,
      };
    } else {
      clickCoordinates = {
        left: containerRef.current!.clientWidth - 60,
        top: 24,
      };
    }

    window.onselectstart = () => false;
    //@ts-ignore
    document.body.style = "cursor: move";
    document.onmouseup = handleDrugAndDropMouseUp;
    document.onmousemove = (e: MouseEvent) =>
      drugAndDropMouseMove(e, clickCoordinates);
  };

  const handleDrugAndDropMouseUp = () => {
    //@ts-ignore
    document.body.style = "";
    window.onselectstart = null;
    document.onmousemove = null;
    document.onmouseup = null;
  };

  const drugAndDropMouseMove = (e: MouseEvent, coordinates: any) => {
    let leftCoordinate = e.pageX - coordinates.left;
    let topCoordinate = e.pageY - coordinates.top;

    const containerHeight = containerRef.current!.clientHeight;
    const containerWidth = containerRef.current!.clientWidth;
    const documentHeight = document.documentElement.clientHeight;
    const documentWidth = document.documentElement.clientWidth;

    const collapseBottom = containerHeight + topCoordinate >= documentHeight;
    const collapseTop = topCoordinate <= 0;
    const collapseLeft = leftCoordinate <= 0;
    const collapseRight = containerWidth + leftCoordinate >= documentWidth;

    if (collapseLeft) {
      leftCoordinate = 0;
    }
    if (collapseRight) {
      leftCoordinate = documentWidth - containerWidth;
    }
    if (collapseTop) {
      topCoordinate = 0;
    }
    if (collapseBottom) {
      topCoordinate = documentHeight - containerHeight;
    }

    setX(leftCoordinate);
    setY(topCoordinate);
  };

  const onResize = (
    event: SyntheticEvent<Element, Event>,
    { size }: ResizeCallbackData
  ) => {
    setWidth(size.width);
    setHeight(size.height);
  };

  if (!isLectureComing) {
    return null;
  }

  const styles = {
    left: isOpen ? x : "auto",
    top: isOpen ? y : "auto",
    width: isOpen ? width : 353,
    height: isOpen ? height : 49,
  };

  return (
    <Resizable
      height={isOpen ? height : 49}
      width={width}
      onResize={onResize}
      minConstraints={[353, 543]}
    >
      <Container isOpen={isOpen} ref={containerRef} style={styles}>
        <Header
          isOpen={isOpen}
          tab={tab}
          onTabClick={handleOnTabButtonClick}
          onArrowClick={handleOnArrowClick}
          onMouseDown={handleDrugAndDropMouseDown}
        />
        {isOpen && <Body tab={tab} />}
      </Container>
    </Resizable>
  );
};

export default OnlineWebinarLectureWidget;
