import { PaperContainer } from "containers";
import styled from "styled-components";

export const SidebarContainerWrapper = styled.div`
  margin-top: 24px;
  margin-left: 16px;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading6FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const CalendarDatePickerContainer = styled(PaperContainer)`
  margin-top: 16px;
  width: 280px;
  padding: 18px 18px 2px;
  box-sizing: border-box;
`;

export const CalendarLegendContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 24px;

  span {
    font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
    font-weight: ${(props) => props.theme.fontWeights.superSkinnyFontWeight};
    color: ${(props) => props.theme.colors.primaryBlack};
    margin-left: 16px;
  }
`;
