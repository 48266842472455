import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { removeNotification } from "layout";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type IStatus = "success" | "warning" | "error";

const Notifications = () => {
  const notice = useSelector((state: RootState) => state.notification);
  const [open, setOpen] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<IStatus>("success");
  const [message, setMessage] = React.useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (notice.status !== "idle") {
      setStatus(notice.status);
      setMessage(notice.message);
      setOpen(true);
      dispatch(removeNotification());
    }
  }, [dispatch, notice]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={status}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Notifications;
