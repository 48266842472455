import { ContentContainer } from "containers";
import { Payments, Services } from "./components";
import { Container, Title } from "./styledComponents";

const PaymentsPage = () => {
  return (
    <ContentContainer>
      <Container>
        <Title>Услуги и платежи</Title>
        <Payments />
        <Services />
      </Container>
    </ContentContainer>
  );
};

export default PaymentsPage;
