import { createSelector } from "reselect";
import { RootState } from "store";
import { WorkoutId } from "./reducer";

export const getTickets = (state: RootState) => state.tests.tickets;
export const getQuestions = (state: RootState) => state.tests.questions;
export const getExam = (state: RootState) => state.tests.exam;
export const getWorkoutList = (state: RootState) => state.tests.workouts;
const getWorkoutId = (state: RootState, id: WorkoutId | null) => id;

export const getWorkout = createSelector(
  [getWorkoutList, getWorkoutId],
  (items, id) =>
    id !== null ? items.find((item) => item.workoutId === id) : null
);
