import { CustomScrollContainer, PaperContainer } from "containers";
import { ChatMessage } from "shared";
import styled from "styled-components";

export const Container = styled.div`
  height: 452px;
`;

export const Message = styled(ChatMessage)<{ isYour: boolean }>`
  margin-bottom: 16px;
  width: 100%;
  box-sizing: border-box;

  background-color: ${(props) => props.theme.colors.opacityGrey5};
  align-self: auto;
`;

export const HeaderListContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primaryGreen};
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 16px 16px 0 0;
`;

export const BodyChatContainer = styled(CustomScrollContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 10px 10px 18px;
  box-sizing: border-box;
`;

export const StyledPaperContainer = styled(PaperContainer)`
  height: calc(100% - 65px);
  border-top: none;
  border-radius: 0 0 8px 8px;
`;
