import { ContentContainer } from "containers";
import styled from "styled-components";

export const InWorkContainer = styled(ContentContainer)`
  justify-content: center;
  align-items: center;
  height: calc(100vh - 110px);
`;

export const Text = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading1FontSize};
`;
