import { Input, Button } from "components";
import { primaryWhite } from "constants/color";
import styled from "styled-components";

type Props = {
  onChange?: (e: any) => void;
  onClick?: () => void;
  onKeyUp?: (e: any) => void;
  value?: string | null;
};

const InputWrapper = styled(Input)`
  position: sticky;
  font-size: 5em;
`;

const ButtonForInput = styled(Button)`
  height: 40px;
  min-width: 40px;
  width: 40px;
  margin: 2px;
  padding: 0;

  svg {
    margin: 0 auto;
  }
`;

const ChatInput = ({ onChange, onClick, value, onKeyUp }: Props) => {
  return (
    <InputWrapper
      value={value}
      onChange={onChange}
      onKeyUp={onKeyUp}
      placeholder="Введите ваше сообщение..."
      style={{ fontSize: "12px" }}
      //@ts-ignore
      leftZone={
        <ButtonForInput
          onClick={onClick}
          leftIcon="SendIcon"
          leftIconColor={primaryWhite}
        />
      }
    />
  );
};

export default ChatInput;
