export type ChatUserVoiceNotification = {
  lastname: string;
  firstname: string;
  middlename: string;
  id: number;
  url: string;
};

export enum OPERATION {
  VOICE = "chat/voice",
  NOTICE = "chat/notice",
}

export type ChatVoiceNotification = {
  operation: OPERATION;
  user: ChatUserVoiceNotification;
  id: number;
};
