export const heading1FontSize: "48px" | "3em" = "3em";
export const heading2FontSize: "30px" | "1.875em" = "1.875em";
export const heading3FontSize: "28px" | "1.75em" = "1.75em";
export const heading4FontSize: "24px" | "1.5em" = "1.5em";
export const heading5FontSize: "22px" | "1.375em" = "1.375em";
export const heading6FontSize: "20px" | "1.25em" = "1.25em";
export const heading7FontSize: "18px" | "1.125em" = "1.125em";
export const subtitle1FontSize: "16px" | "1em" = "1em";
export const subtitle2FontSize: "14px" | "0.875em" = "0.875em";
export const body1FontSize: "12px" | "0.75em" = "0.75em";
export const body2FontSize: "10px" | "0.625em" = "0.625em";
export const captionFontSize: "8px" | "0.5em" = "0.5em";

export const heading40FontSize: "40px" | "2.5em" = "2.5em";

export const boldFontWeight: 700 = 700;
export const normalFontWeight: 600 = 600;
export const skinnyFontWeight: 500 = 500;
export const superSkinnyFontWeight: 400 = 400;

export const mainFont: "Montserrat" = "Montserrat";
export const mainBoldFont: "Montserrat Bold !important" =
  "Montserrat Bold !important";

export const fontSizes = {
  heading1FontSize,
  heading2FontSize,
  heading3FontSize,
  heading4FontSize,
  heading5FontSize,
  heading6FontSize,
  heading7FontSize,
  subtitle1FontSize,
  subtitle2FontSize,
  heading40FontSize,
  body1FontSize,
  body2FontSize,
  captionFontSize,
};

export const fontWeights = {
  boldFontWeight,
  normalFontWeight,
  skinnyFontWeight: skinnyFontWeight,
  superSkinnyFontWeight: superSkinnyFontWeight,
};

export const fonts = {
  mainFont,
  mainBoldFont,
};
