import styled from "styled-components";
import feedback1 from './assets/images/feedback/1.png'
import feedback2 from './assets/images/feedback/2.png'
import feedback3 from './assets/images/feedback/3.png'
import feedback4 from './assets/images/feedback/4.png'
import feedback5 from './assets/images/feedback/5.png'
import feedback6 from './assets/images/feedback/6.png'
import feedback7 from './assets/images/feedback/7.png'
import feedback8 from './assets/images/feedback/8.png'
import vkIcon  from './assets/images/vk_small.svg'

import Slider from "react-slick";
const Container = styled.div`
    max-width: calc(100vw - 20px);
    margin-bottom: 140px;
    width: 100%;
    margin-inline: auto;
    @media (max-width: 768px) {
      margin-bottom: 30px !important;
    }
  `;
export default function FeedbackComponent ({Text,ExtraTitle,primary}) {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: window.innerWidth <=768 ? 1 : 2,
        slidesToScroll:  window.innerWidth <=768 ? 1 : 2,
    }
const Wrapper = styled.div`
position: relative;

`
  const VkIcon = styled.div`
  width: 30px;
  height: 30px;
  background: url(${vkIcon});
  background-size: cover;
  `
  const FeedbackImage = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 12px;
  background: url(${props=>props.src});
background-size: 100%;
     @media (max-width: 768px){
     width: 75px;
     height: 75px;
     }
  `
  const FeedbackHeader = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
       @media (max-width: 768px){
       gap: 10px;
       }
  `
  const FeedbackUser = styled.div`
  display: flex;

  flex-direction: column;
  `
  const FeedbackUsername = styled.h6`
  color: #23232a;
    font-size: 26px;
        font-weight: 600;
        line-height: 29px;
        margin-bottom: 10px;
        @media (max-width: 768px){
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 4px;
        }

  `
  const FeedbackTime = styled.span`
  color ${primary};
  opacity: .5;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
       @media (max-width: 768px){
       font-size: 12px;
       line-height: 14px;
       }

  `
  const FeedbackItem = styled.div`
  min-width: 590px;
  height: 416px;
  max-width: 590px;
  width: 590px;
  padding: 40px;
  background: #f5f5f5;
  border-radius: 30px;
  @media (max-width: 768px){
  width: 100%!important;
   max-width: 100%!important;
    min-width: 100%!important;
    padding: 30px!important;
    height: fit-content;
  }
  `
  const Container_ = styled(Container)`

margin-bottom: 70px;
`;
const Title = styled(ExtraTitle)`
margin-bottom: 40px;
color: ${primary};
`;
const Text_ = styled(Text)`
color: ${primary};

margin-bottom: 0;
`;

return (
<>
<Container_>
    <Title>
    Прямо сейчас в ПДД.ТВ учатся 8 100 человек
    </Title>
    <Wrapper>
<Slider {...settings}
>
    <FeedbackItem>
<FeedbackHeader>
    <FeedbackImage src={feedback1} />
    <FeedbackUser>
        <FeedbackUsername>Владимир <br/> Табаков</FeedbackUsername>
        <FeedbackTime>8 ноя 2023 в 17:05</FeedbackTime>
    </FeedbackUser>
    <VkIcon></VkIcon>
</FeedbackHeader>
<Text_>Приложение класс, по обучение всё было весело, ясно, понятно. Очень понравилось что в любой момент можно просмотреть материал еще раз, это очень помогло в подготовке. Подход к обучению очень хороший. Вождение проходил в офигительной атмосфере. Большое спасибо всему коллективу автошколы, и разработчикам платформы ПДД.ТВ. Ребята максимально качественно подошли к процессу обучения)</Text_>
    </FeedbackItem>
    <FeedbackItem>
<FeedbackHeader>
    <FeedbackImage src={feedback2} />
    <FeedbackUser>
        <FeedbackUsername>Юлия<br/> Волкова </FeedbackUsername>
        <FeedbackTime>5 ноя 2023 в 12:57</FeedbackTime>
    </FeedbackUser>
    <VkIcon></VkIcon>
</FeedbackHeader>
<Text_>Очень понравилось обучение в «ПДД ТВ» с точки зрения подхода к обучению! Продуманная программа, вся информация переработана и оформлена доступным языком. Отдельное спасибо за видео обзоры от Даниила Александровича и дневник, разработанный самой школой. Также хочется отметить, что на занятиях по теории с Анастасией Николаевной вы не просто зубрите билеты, а разбираете их на понимание.</Text_>
    </FeedbackItem>
    <FeedbackItem>
<FeedbackHeader>
    <FeedbackImage src={feedback3} />
    <FeedbackUser>
        <FeedbackUsername>Наталья<br/>Архипова</FeedbackUsername>
        <FeedbackTime>4 ноя 2023 в 0:14</FeedbackTime>
    </FeedbackUser>
    <VkIcon></VkIcon>
</FeedbackHeader>
<Text_>Одно из самых прекрасных и одновременно грустных моментов это писать отзыв. Это значит водительское удостоверение в руках, НО обучение закончилось. Это было одно из самых ярких событий в моей жизни</Text_>
    </FeedbackItem>
    <FeedbackItem>

<FeedbackHeader>
    <FeedbackImage src={feedback4} />
    <FeedbackUser>
        <FeedbackUsername>Анастасия<br/> Диченко</FeedbackUsername>
        <FeedbackTime>2 ноя 2023 в 3:30</FeedbackTime>
    </FeedbackUser>
    <VkIcon></VkIcon>
</FeedbackHeader>
<Text_>Выражаю свою благодарность автошколе ПДД.ТВ! Отдел заботы всегда был со мной на связи и помогал в любой ситуации.</Text_>
    </FeedbackItem>
    <FeedbackItem>

        <FeedbackHeader>
            <FeedbackImage src={feedback5} />
            <FeedbackUser>
                <FeedbackUsername>Александра<br/> Голованова</FeedbackUsername>
                <FeedbackTime>12 окт 2023 в 18:47</FeedbackTime>
            </FeedbackUser>
            <VkIcon></VkIcon>
        </FeedbackHeader>
        <Text_>Ура! Свершилось! Наконец то я пополнила ряды автоледи! Права в кармане-мечта сбылась! Спасибо ПДД.ТВ - лучшие из лучших! Коллектив поддержки - каждый профессионал своего дела. Занятия по ПДД и маневрированию проходили с интересом, на позитиве, доступно для понимания (спасибо Даниилу Александрович, Анастасии Николаевне)!</Text_>
            </FeedbackItem>

            <FeedbackItem>

        <FeedbackHeader>
            <FeedbackImage src={feedback6} />
            <FeedbackUser>
                <FeedbackUsername>Виктория<br/> Распутина</FeedbackUsername>
                <FeedbackTime>12 окт 2023 в 16:06</FeedbackTime>
            </FeedbackUser>
            <VkIcon></VkIcon>
        </FeedbackHeader>
        <Text_>Сдала! Сдала, с первого раза! Ура!!!Большое спасибо автошколе ПДД.ТВ, за крутую подготовку, подробный разбор материала и отработку навыков!Самый замечательный преподавательский состав! Все разложили в голове, отрисовали и отпечатали в памяти ПДД и БДД!!!Отдельная огромная благодарность моему инструктору Антону Олеговичу Заборскому! Самый лучший инструктор!</Text_>
            </FeedbackItem>
            <FeedbackItem>

        <FeedbackHeader>
            <FeedbackImage src={feedback7} />
            <FeedbackUser>
                <FeedbackUsername>Александра<br/> Рычкова</FeedbackUsername>
                <FeedbackTime>7 окт 2023 в 17:05</FeedbackTime>
            </FeedbackUser>
            <VkIcon></VkIcon>
        </FeedbackHeader>
        <Text_>ПДД.ТВ это самая крутая платформа по обучению теории, поддержка очень хорошая, всегда ответят на вопросы, подскажут и поддержат!!! Огромное спасибо моим инструкторам, Склярову Алексею Вадимовичу и Шарашову Алексею Геннадьевичу!!! Всегда все объясняли на занятиях, если были допущены ошибки, они вселили в меня надежду сдать экзамен, и у меня это получилось!!!! Ура!</Text_>
            </FeedbackItem>
            <FeedbackItem>

        <FeedbackHeader>
            <FeedbackImage src={feedback8} />
            <FeedbackUser>
                <FeedbackUsername>Katharina<br/> Nikolajewna</FeedbackUsername>
                <FeedbackTime>5 окт 2023 в 22:40</FeedbackTime>
            </FeedbackUser>
            <VkIcon></VkIcon>
        </FeedbackHeader>
        <Text_>Вот и всё, отличная получилась история, интересная, весёлая порой немного и грустная, разные были дни.</Text_>
            </FeedbackItem>
</Slider>
</Wrapper>
</Container_>
</>
)
}
