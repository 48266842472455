import { getLecturePromoLoad, getWebinarList } from "pages";
import { LecturePromoType } from "pages/Cabinet/redux/reducer";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Begin, Left, Name, Title, Container } from "./styledComponents";
import moment from "moment";

const OnlineLectureWaiting = () => {
  const isLoad = useSelector(getLecturePromoLoad);
  const webinarList = useSelector(getWebinarList);
  const timeNow = moment().unix();
  const nextLesson = webinarList.filter(
    (item) => item.time_start >= timeNow
  )[0];

  if (isLoad) {
    return (
      <Container>
        <Title>Загрузка...</Title>
      </Container>
    );
  }

  if (nextLesson) {
    return <OnlineLectureWaitingMain lecturePromo={nextLesson} />;
  }

  return (
    <Container>
      <Title>Онлайн лекции отсутствуют</Title>
    </Container>
  );
};

export default OnlineLectureWaiting;

const OnlineLectureWaitingMain = ({
  lecturePromo,
}: {
  lecturePromo: LecturePromoType;
}) => {
  const [timeLeft, setTimeLeft] = useState<Date | null>(null);

  const startFormatDate: Date = useMemo(
    () => new Date(lecturePromo.time_start * 1000),
    [lecturePromo.time_start]
  );

  const startDateString = new Date(
    lecturePromo.time_start * 1000
  ).toLocaleDateString();

  const updateTime = () => {
    setTimeLeft(new Date(startFormatDate.getTime() - new Date().getTime()));
  };

  useEffect(() => {
    const intervalId = setInterval(updateTime, 1000);
    updateTime();

    return () => {
      clearInterval(intervalId);
    };
  }, [startFormatDate]);

  if (timeLeft === null) {
    return <Container>Загрузка...</Container>;
  }

  const dayToStart =
    timeLeft.getUTCDate() - 1 <= 0 ? "" : `${timeLeft.getUTCDate() - 1} д`;

  return (
    <Container>
      <Title>Лекция по теме</Title>
      <Name>{lecturePromo.topic.name}</Name>
      {timeLeft.getTime() > 0 ? (
        <>
          <Left>
            <Begin>
              начнется
              <span>{startDateString}</span>
            </Begin>
          </Left>
          осталось
          <span>{`${dayToStart} ${timeLeft.getUTCHours()} ч ${timeLeft.getUTCMinutes()} мин ${timeLeft.getUTCSeconds()} сек`}</span>
        </>
      ) : (
        <Left>Пожалуйста, подождите, лекция сейчас начнется</Left>
      )}
    </Container>
  );
};
