import { openModal } from "layout";
import { useDispatch } from "react-redux";
import PaymentsTable from "../PaymentsTable";
import {
  ControlPanel,
  Head,
  StyledButton,
  Title,
  Container,
} from "./styledComponents";
import { useEffect, useState } from "react";
import { getBillingOrdered } from "../../../../api/CabinetPromoApi";

const Payments = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const [load, setLoad] = useState(true);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoad(true);
        const { data } = await getBillingOrdered();
        setData(data.items);
        setLoad(false);
      } catch (e) {
        setLoad(false);
        console.log(e);
      }
    };

    getData();
  }, [refetch]);

  const handleOpenPaymentsModal = () => {
    dispatch(
      openModal({
        name: "PaymentListModal",
        refetch: () => setRefetch(!refetch),
      })
    );
  };

  const handleOnPay = (item: any) => {
    dispatch(
      openModal({
        name: "PaymentsModal",
        item,
      })
    );
  };

  return (
    <Container>
      <Head>
        <Title>Услуги</Title>
        <ControlPanel>
          <StyledButton onClick={handleOpenPaymentsModal}>
            Добавить услугу
          </StyledButton>
        </ControlPanel>
      </Head>
      <PaymentsTable onPay={handleOnPay} data={data} load={load} />
    </Container>
  );
};

export default Payments;
