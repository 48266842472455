import { RootState } from "store";
import { createSelector } from "reselect";
import { LectureVideo } from "./reducer";

export const getLectureArchive = (state: RootState) =>
  state.lectureArchive.list as LectureVideo[];

export const getLectureArchiveLoadStatus = (state: RootState) =>
  state.lectureArchive.statusLoading;

const getLectureVideoId = (state: RootState, id: string) => id;

export const getLectureVideo = createSelector(
  [getLectureArchive, getLectureVideoId],
  (items, id) => items.find((item) => item.id === Number(id))
);

export const getLectureInfo = (state: RootState) => state.lectureArchive.info;
const getLectureInfoId = (state: RootState, id: string) => id;

export const getInteractiveTaskStats = (state: RootState) =>
  state.lectureArchive.interactiveTaskStats;

export const getLectureVideoInfo = createSelector(
  [getLectureInfo, getLectureInfoId],
  (items, id) => items.find((item) => item.id === Number(id))
);
