import styled from "styled-components";

const LabelContainer = styled.div`
  background: ${(props) => props.theme.colors.opacityGreen10};
  border: 1px solid ${(props) => props.theme.colors.primaryGreen};
  box-sizing: border-box;
  border-radius: 18px;
`;

export default LabelContainer;
