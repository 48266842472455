import styled from "styled-components";
import { Button } from "components";

export const Container = styled.div`
  margin-right: 32px;
  border: 0.5px solid ${(props) => props.theme.colors.secondaryLightGray};
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  height: calc(100vh - 110px - 48px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.heading3FontSize};
  font-weight: ${(props) => props.theme.fontWeights.skinnyFontWeight};
  color: ${(props) => props.theme.colors.primaryBlack};
`;

export const Subtitle = styled.div`
  padding: 0 120px;
  text-align: center;
  margin-top: 26px;
  font-size: ${(props) => props.theme.fontSizes.heading4FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  color: ${(props) => props.theme.colors.primaryBlack};
`;

export const StyledButton = styled(Button)`
  margin-top: 56px;
`;
