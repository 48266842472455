import styled from "styled-components";

export const HeaderContainer = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primaryGreen};
  line-height: 44px;
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  color: ${(props) => props.theme.colors.primaryWhite};
  transition: all 0.5s;

  cursor: ${(props) => (props.isOpen ? `default` : "pointer")};
  border-radius: ${(props) => (props.isOpen ? `10px 10px 0px 0px` : "10px")};
  height: ${(props) => (props.isOpen ? `114px` : " 49px")};
  justify-content: flex-end;
  flex-direction: column;
`;
