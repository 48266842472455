import React, { useEffect, useState } from "react";
import InfoPreviewImages from "layout/InfoPreviewImages/InfoPreviewImages";
import { ProgressBlock, ProgressContainer, Title } from "./styledComponents";
import DetailProgress from "./DetailProgress";
import { shallowEqual, useSelector } from "react-redux";
import { getStatistics } from "../../../../api/LecturesArchiveAPI";
import { getUserId } from "../../../../selectors/userInfo";

import pddIcon from "icons/pddIcon.svg";
import lectureIcon from "icons/lectureIcon.svg";
import tasksIcon from "icons/tasksIcon.svg";
import { useHistory } from "react-router";

const video = "https://promo-videos-pddtv.hb.bizmrg.com/promo-pddtv.mp4";

const Progress = React.memo(() => {
  const history = useHistory();
  const userId = useSelector(getUserId, shallowEqual);
  const [stat, setStat] = useState<any>(null);

  useEffect(() => {
    const getStat = async () => {
      if (userId) {
        const request = await getStatistics(userId);
        // @ts-ignore
        setStat(request.data);
      }
    };

    getStat();
  }, [userId]);

  if (stat === null) {
    return null;
  }

  return (
    <>
      <ProgressContainer>
        <ProgressBlock>
          <DetailProgress
            title="Лекции"
            description={`${stat.full_stat.lessons_presence} посещено, ${stat.full_stat.lessons_left} пропущено`}
            success={stat.full_stat.lessons_presence}
            error={stat.full_stat.lessons_left}
            total={stat.full_stat.lessons_total}
            icon={lectureIcon}
            buttonText="просмотр"
            buttonClick={() => history.push("/lecture-archive")}
          />
          <DetailProgress
            title="Задания"
            description={`${stat.full_stat.interactive_tasks_success} успешно, ${stat.full_stat.interactive_tasks_fail} с ошибками`}
            success={stat.full_stat.interactive_tasks_success}
            error={stat.full_stat.interactive_tasks_fail}
            total={stat.full_stat.interactive_tasks_total}
            icon={tasksIcon}
          />
        </ProgressBlock>
        <ProgressBlock>
          <DetailProgress
            title="Билеты ПДД"
            description={`${stat.pdd.total.success} успешно, ${stat.pdd.total.fail}  с ошибками`}
            success={stat.pdd.total.success}
            error={stat.pdd.total.fail}
            total={stat.pdd.total.total}
            icon={pddIcon}
            buttonText="обучение"
            buttonClick={() => history.push("/tickets_themes")}
          />
        </ProgressBlock>
      </ProgressContainer>

      <ProgressBlock>
        <Title>Видео обучение</Title>
        <InfoPreviewImages video={video} />
      </ProgressBlock>
    </>
  );
});

export default Progress;
