import {
  Wrapper,
  Container,
  Video,
  CloseButton,
  Overlay,
} from "./styledComponents";
import { useCallback } from "react";
import { closeModal } from "../../redux";
import { useDispatch } from "react-redux";

type Props = {
  video: string;
};

const VideoBoxModal = ({ video }: Props) => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeModal("VideoBoxModal"));
  }, [dispatch]);

  return (
    <>
      <Overlay onClick={handleClose} />
      <Wrapper>
        <Container>
          <CloseButton
            icon="CloseIcon"
            size="l"
            color="white"
            onClick={handleClose}
          />
          <Video controls src={video} datatype="video/mp4" autoPlay />
        </Container>
      </Wrapper>
    </>
  );
};

export default VideoBoxModal;
