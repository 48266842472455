import { Icon } from "components";
import { PaperContainer } from "containers";
import styled from "styled-components";

export const Container = styled(PaperContainer)`
  margin-top: 16px;
  display: flex;
  height: 64px;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px;
`;

export const Name = styled.div`
  margin-right: 8px;
  font-size: ${(props) => props.theme.fontSizes.subtitle1FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const Description = styled.div`
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
`;

export const NotificationsIcon = styled(Icon)`
  margin-right: 16px;
`;

export const CloseIcon = styled(Icon)`
  margin-left: auto;
  cursor: pointer;
`;
