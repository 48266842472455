import styled from "styled-components";

type Props = {
  withoutShadow?: boolean;
};

const PaperContainer = styled.div<Props>`
  background-color: ${(props) => props.theme.colors.primaryWhite};
  border: 6px solid ${(props) => props.theme.colors.secondaryGreyBorder};
  border-radius: 16px;
  box-shadow: ${(props) =>
    props.withoutShadow
      ? "none"
      : `0px 10px 24px ${props.theme.colors.shadowPaperContainer}`};
`;

export default PaperContainer;
