import styled from "styled-components";
import { DefaultModalContainer } from "containers";

export const Container = styled(DefaultModalContainer)``;

export const WrapperPicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.p`
  font-size: 1.75em;
  font-weight: 700;
  margin-top: 34px;
  margin-bottom: 24px;
  text-align: center;
`;

export const Text = styled.p`
  font-size: 1.25em;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 111px;
  text-align: center;
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin: 0 10px;
  }
`;
