import { Icon } from "components";
import { CustomScrollContainer } from "containers";
import { PropsWithChildren, useState } from "react";
import styled from "styled-components";

type Props = {
  title: string;
  className?: string;
};

const Container = styled.div<{ isOpen: boolean }>`
  min-height: 48px;
  max-height: ${(props) => (props.isOpen ? "312px" : "48px")};
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.secondaryLightGray};
  overflow: hidden;
  transition: all 0.2s ease-out;
`;

const HeaderWrapper = styled.div`
  padding: 0 24px;
  cursor: pointer;
`;

const Header = styled.div`
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 0.5px solid ${(props) => props.theme.colors.secondaryLightGray};
`;

const Body = styled(CustomScrollContainer)`
  max-height: 264px;
  padding: 0 16px 0 24px;
  margin-right: 8px;
`;

const ArrowIcon = styled(Icon)<{ isOpen: boolean }>`
  transform: ${(props) => (props.isOpen ? "none" : "rotate(180deg)")};
`;

const Accordion = ({
  title,
  children,
  className,
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(false);

  const handleToggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Container isOpen={open} className={className}>
      <HeaderWrapper onClick={handleToggleOpen}>
        <Header>
          {title}
          <ArrowIcon isOpen={open} icon="ArrowUpIcon" size="s" />
        </Header>
      </HeaderWrapper>
      <Body>{children}</Body>
    </Container>
  );
};

export default Accordion;
