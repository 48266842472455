import { Icon } from "components";
import CanvasDraw from "react-canvas-draw";
import styled from "styled-components";
import LectureControlPanel from "../components/LectureControlPanel";

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;
`;

export const ControlPanel = styled(LectureControlPanel)`
  position: absolute;
  bottom: -40px;
  left: calc(50% - 280px);
  z-index: 9999;
`;

export const Canvas = styled(CanvasDraw)`
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  transform-origin: 0 0;
`;

export const Iframe = styled.iframe<{ scale?: number; isDraw?: boolean }>`
  height: 1080px;
  width: 1920px;
  position: absolute;
  transform-origin: 0 0;
  transform: ${(props) => `scale(${props.scale! - 0.1}%)`};
  z-index: ${(props) => (props.isDraw ? 998 : 9999)};
`;

export const DrawField = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const StyledIcon = styled(Icon)`
  transform: rotate(180deg);
`;

export const SlidesControl = styled.div`
  display: flex;
  justify-content: space-between;
  width: 334px;
  position: absolute;
  right: 50px;
  bottom: 20px;
  z-index: 9999;
`;
