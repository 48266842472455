import { TaskAnswer } from "shared";
import styled from "styled-components";

export const Container = styled.div`
  margin: 16px 0;
`;

export const StyledAnswer = styled(TaskAnswer)`
  margin-bottom: 16px;
`;

export const HintBlock = styled.div`
  padding: 14px 24px;
  margin: 16px 0;
  border-radius: 12px;
  border: 1px solid #dadde4;
  background: rgba(74, 195, 101, 0.2);
`;
