import CalendarDatePicker from "components/CalendarDatePicker";
import { PaperContainer } from "containers";
import styled from "styled-components";

export const Container = styled(PaperContainer)`
  padding: 24px 32px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 500px) {
    padding: 16px 16px;
  }
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading6FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  margin-bottom: 16px;
`;

export const CalendarLegendContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  span {
    font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
    font-weight: ${(props) => props.theme.fontWeights.superSkinnyFontWeight};
    color: ${(props) => props.theme.colors.primaryBlack};
    margin-left: 16px;
  }
`;

export const Calendar = styled(CalendarDatePicker)`
  flex-grow: 2;
  width: 100%;
`;
