import styled from "styled-components";

export const ListItemContainer = styled.div<{ active?: boolean }>`
  width: 256px;
  height: 48px;
  border-radius: 0 10px 10px 0;
  padding: 16px 0 16px 36px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  display: flex;
  align-items: center;

  color: ${(props) =>
    props.active
      ? props.theme.colors.primaryWhite
      : props.theme.colors.secondaryGrey};
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.primaryGreen
      : props.theme.colors.primaryWhite};
  font-weight: ${(props) =>
    props.active
      ? props.theme.fontWeights.normalFontWeight
      : props.theme.fontWeights.skinnyFontWeight};

  ${(props) =>
    !props.active &&
    `&:hover {
      border-radius: 8px;
      background-color: ${props.theme.colors.opacityLightGrey20};
      color: ${props.theme.colors.secondaryGrey};
  }`}
`;

export const Text = styled.span`
  margin-left: 16px;
  text-decoration: none;
`;
