import { Icon } from "components";
import { primaryWhite } from "constants/color";
import preventDefault from "helpers/preventDefault";
import styled from "styled-components";

type Props = {
  onArrowClick?: () => void;
  onMouseDown?: (e: any) => void;
};

const HeaderControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  cursor: move;
  padding: 16px 24px 0;
  box-sizing: border-box;
`;

const Arrow = styled(Icon)`
  cursor: pointer;
  transform: rotate(180deg);
`;

const DragAndDrop = styled(Icon)`
  margin-right: 12px;
  cursor: move;
`;

const HeaderControls = ({ onArrowClick, onMouseDown }: Props) => {
  return (
    <HeaderControlsContainer onMouseDown={onMouseDown}>
      <DragAndDrop icon="DragAndDropIcon" size="s" color={primaryWhite} />
      <Arrow
        icon="ArrowUpIcon"
        size="s"
        color={primaryWhite}
        onClick={onArrowClick}
        onMouseDown={preventDefault}
      />
    </HeaderControlsContainer>
  );
};

export default HeaderControls;
