import React, { PropsWithChildren, useState } from "react";
import { PopUp } from "components";
import DayPopUp from "./DayPopUp";
import styled from "styled-components";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

type Props = {
  planned?: boolean;
  passed?: boolean;
  disabled?: boolean;
  selected?: boolean;
  today?: boolean;
  preview?: boolean;
  d?: MaterialUiPickersDate;
  webinar?: any;
};

const DayContainer = styled.div<Props>`
  height: 28px;
  width: 28px;
  min-width: 28px;
  text-align: center;
  line-height: 28px;
  margin: 0;
  border-radius: 6px;
  font-weight: ${(props) =>
    props.today
      ? props.theme.fontWeights.normalFontWeight
      : props.theme.fontWeights.superSkinnyFontWeight};
  font-size: ${(props) =>
    props.today
      ? props.theme.fontSizes.heading6FontSize
      : props.theme.fontSizes.subtitle1FontSize};
  cursor: ${(props) => (props.preview ? "auto" : "pointer")};
  background-color: ${(props) =>
    props.planned || props.selected
      ? "#4AC365"
      : props.theme.colors.primaryWhite};
  border: ${(props) => (props.passed ? "1px dashed #4AC365" : "none")};
  box-sizing: border-box;

  color: ${(props) => {
    if (props.planned || props.selected) {
      return props.theme.colors.primaryWhite;
    }

    if (props.disabled) {
      return props.theme.colors.secondaryGrey;
    }

    return props.theme.colors.primaryBlack;
  }};
`;

const Day = ({
  children,
  passed,
  planned,
  disabled,
  selected,
  today,
  preview,
  d,
  webinar,
}: PropsWithChildren<Props>) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    if ((passed || planned) && !preview) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <DayContainer
        today={today}
        passed={passed}
        planned={planned}
        disabled={disabled}
        onClick={handleClick}
        selected={selected}
        preview={preview}
      >
        {children}
      </DayContainer>
      <PopUp
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DayPopUp d={d} webinar={webinar} />
      </PopUp>
    </>
  );
};

export default Day;
