import { TTaskAnswers } from "api/models/task";
import { Header } from "layout/ModalWindow/layout";
import { closeModal } from "layout/ModalWindow/redux";
import React from "react";
import { useDispatch } from "react-redux";
import { TaskAnswers, TaskQuestion } from "shared";
import { Body, CloseButton, Container } from "./styledComponents";

type Props = {
  webinarId: number;
  name: string;
  imageUrl?: string;
  videoUrl?: string;
  question?: string;
  hint?: string;
  answers: TTaskAnswers;
  questionId: number;
  id: number;
  callback?: () => void;
};

const WebinarTaskModal = ({
  webinarId,
  name,
  imageUrl,
  videoUrl,
  question,
  hint,
  answers,
  questionId,
  id,
  callback,
}: Props) => {
  const dispatch = useDispatch();
  console.log({
    webinarId,
    name,
    imageUrl,
    videoUrl,
    question,
    hint,
    answers,
    questionId,
    id,
    callback,
  });
  const handleClose = () => {
    dispatch(closeModal(name));
    if (callback) callback();
  };

  return (
    <Container>
      <Header title="Вопрос" onClose={handleClose} />
      <Body>
        <TaskQuestion
          question={question}
          videoUrl={videoUrl}
          imageUrl={imageUrl}
        />
        <TaskAnswers
          webinarId={webinarId}
          id={id}
          questionId={questionId}
          answers={answers}
          hint={hint}
        />
        <CloseButton onClick={handleClose}>Закрыть</CloseButton>
      </Body>
    </Container>
  );
};

export default WebinarTaskModal;
