import styled from "styled-components";
import image from "assets/images/promoBannerBackground.svg";
import { PaperContainer } from "containers";
import { Button } from "components";

export const WrapLoader = styled.div`
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const PromoBannerContainer = styled(PaperContainer)<{
  appBarIsOpen: boolean;
  hideBackgroundImage?: boolean;
}>`
  padding: 24px 32px;
  margin-top: 16px;
  max-width: 100%;
  min-height: 272px;
  box-sizing: border-box;
  background-image: ${(props) =>
    !props.hideBackgroundImage ? `url(${image})` : `none`};
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position-x: right;
  background-position-y: bottom;
  position: relative;
  text-align: left;

  @media screen and (max-width: 700px) {
    background-image: none;
    text-align: center;
  }

  @media screen and (max-width: 500px) {
    padding: 24px 8px;
  }

  ${(props) =>
    props.appBarIsOpen &&
    `
    @media screen and (max-width: 500px) {
      background-image: none;
    }
    `}
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.heading4FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};

  @media screen and (max-width: 500px) {
    font-size: ${(props) => props.theme.fontSizes.heading5FontSize};
  }
`;

export const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.heading5FontSize};
  font-family: ${(props) => props.theme.fonts.mainBoldFont};
  margin-top: 12px;

  @media screen and (max-width: 500px) {
    font-size: ${(props) => props.theme.fontSizes.heading4FontSize};
  }
`;

export const Caption = styled.div`
  color: ${(props) => props.theme.colors.secondaryGrey};
  font-size: ${(props) => props.theme.fontSizes.body1FontSize};
  max-width: 340px;
  width: 100%;
  margin-top: 12px;
`;

export const StyledButton = styled(Button)`
  width: fit-content;
  margin-top: 24px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
