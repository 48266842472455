import { getLecturePromo, getLecturePromoUsers } from "pages";
import { useSelector } from "react-redux";
import { Container, Header, User, ScrollContainer } from "./styledComponents";
import { useContext } from "react";
import { PingOnlineStatusContext } from "../../../../providers/PingOnlineStatusProvider";

type Props = {
  className?: string;
  preview?: boolean;
};

const WebinarGroupInfo = ({ className, preview }: Props) => {
  const lecturePromo = useSelector(getLecturePromo);
  const { onlineUsers } = useContext(PingOnlineStatusContext);

  const lecturePromoUsers = useSelector(getLecturePromoUsers);

  const name = lecturePromo?.combo_group.name;

  return lecturePromoUsers && lecturePromoUsers.length ? (
    <Container className={className}>
      <Header>
        <span>
          {name && name.length >= 29 ? `${name.substring(0, 25)}...` : name}
        </span>
      </Header>
      <ScrollContainer>
        {lecturePromoUsers.map((item) => {
          if (!onlineUsers) return null;
          if (item === null) return null;

          const onlineStatus = Object.keys(onlineUsers).includes(`${item.id}`)
            ? "online"
            : "offline";
          return (
            <User active={onlineStatus} preview={preview} key={item.id}>
              {item.firstname} {item.lastname}
            </User>
          );
        })}
      </ScrollContainer>
    </Container>
  ) : null;
};

export default WebinarGroupInfo;
