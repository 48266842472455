import React, { useEffect } from "react";
import { getLectureArchive } from "pages/LectureArchive/redux/selectors";
import {
  DefaultTableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "components";
import {
  Container,
  DateBlock,
  MainText,
  StyledButton,
  Status,
  Label,
} from "./styledComponents";
import { getInteractiveTaskStats } from "pages/LectureArchive/redux";
import { useHistory } from "react-router";
import { toLectureVideoPath } from "constants/paths";
import moment from "moment";
import { getLectureArchiveList } from "api/UserAPI";
import {
  PromoBannerContainer,
  Title,
} from "pages/Cabinet/components/PromoBanner/styledComponents";
import { useDispatch, useSelector } from "react-redux";

const LectureArchiveTable = () => {
  const history = useHistory();
  const interactiveTaskStats = useSelector(getInteractiveTaskStats);
  const lectureArchiveList = useSelector(getLectureArchive);
  const stats = interactiveTaskStats?.lessons;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLectureArchiveList());
  }, []);

  if (!lectureArchiveList.length) {
    return (
      <PromoBannerContainer appBarIsOpen={false} hideBackgroundImage={true}>
        <Title>Архив лекций пока пуст</Title>
      </PromoBannerContainer>
    );
  }

  return (
    <Container>
      <DefaultTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Дата лекции</TableCell>
              <TableCell align="left">№</TableCell>
              <TableCell scope="row">Тема лекции</TableCell>
              <TableCell align="center">Интерактивные задания</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {lectureArchiveList.map((item, index) => {
              return (
                <TableRow key={item.id}>
                  <TableCell align="left">
                    <DateBlock>
                      {moment(item.webinar_finished_at * 1000).format(
                        "DD.MM.YY"
                      )}
                    </DateBlock>
                  </TableCell>
                  <TableCell align="left">
                    <MainText>№{++index}</MainText>
                  </TableCell>
                  <TableCell scope="row">
                    <MainText>{item.topic.name}</MainText>
                  </TableCell>
                  <TableCell scope="row" align="center" valign="middle">
                    <MainText>
                      {stats ? (
                        <>
                          {stats[item.id]?.interactive_tasks_success ?? 0} /{" "}
                          {stats[item.id]?.interactive_tasks_total ?? 0}
                        </>
                      ) : (
                        0
                      )}
                    </MainText>
                  </TableCell>
                  <TableCell align="center" valign="middle">
                    <StyledButton
                      size="small"
                      onClick={() => history.push(toLectureVideoPath(item.id))}
                    >
                      Смотреть
                    </StyledButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DefaultTableContainer>
    </Container>
  );
};

export default LectureArchiveTable;
