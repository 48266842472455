import { Header } from "layout/ModalWindow/layout";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "layout";
import {
  Button,
  Table,
  TableBody,
  TableHead,
  TabsItem,
  TabsPanel,
} from "components";
import React, { useState } from "react";
import {
  AnswerTab,
  AnswerTitle,
  StyledTabs,
  TabsPanelContainer,
  Container,
  CounterContainer,
  HeaderTableCell,
  StyledTableRow,
  CommonTableCell,
  ResultTableCell,
  StyledScrollContainer,
  ListContainer,
  SelectedTaskContainer,
  StyledCounter,
} from "./styledComponents";
import { TTaskAnswers, TTaskEntity } from "api/models/task";
import { WebinarTaskList } from "..";
import { getWebinarTeacherQuestion } from "pages/Webinars/redux";
import { postInteractiveTasks, setFinishTask } from "api/InteractiveTaskAPI";
import { getLecturePromoUsers } from "pages";
import { TaskAnswers, TaskQuestion } from "../../../../shared";
import { Box } from "@material-ui/core";
import {
  primaryGreen,
  secondaryGrey,
  secondaryRed,
} from "../../../../constants/color";

type Props = {
  webinarId: number;
  name: string;
  question: string;
  video: string;
  image: string;
  answers: TTaskAnswers;
};

const WebinarTask = ({ webinarId }: Props) => {
  const dispatch = useDispatch();
  const teacherQuestion = useSelector(getWebinarTeacherQuestion);
  const lecturePromoUsers = useSelector(getLecturePromoUsers);
  const [tab, setTab] = useState("task");
  const [selectedTask, setSelectedTask] = useState<TTaskEntity | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );

  const handleOnClose = () => {
    if (teacherQuestion.id !== null) {
      dispatch(setFinishTask(teacherQuestion.id));
    }
    dispatch(closeModal("WebinarTask"));
  };

  const handleChangeTab = (tabName: string) => {
    setTab(tabName);
  };

  const handleSelect = (task: TTaskEntity, index: number) => {
    if (teacherQuestion.id === null) {
      setSelectedTask(task);
      setSelectedIndex(index);
    }
  };

  const handleOpenTask = (task: TTaskEntity) => {
    dispatch(postInteractiveTasks(task.id));
  };

  const correctAnswersCount = teacherQuestion.answers.filter(
    (i) => i.answer.correct
  ).length;

  const wrongAnswersCount = teacherQuestion.answers.filter(
    (i) => !i.answer.correct
  ).length;

  const notAnsweredCount =
    lecturePromoUsers.length - correctAnswersCount - wrongAnswersCount;

  const correctAnswersPercent = (
    (correctAnswersCount /
      (!correctAnswersCount ? 1 : correctAnswersCount + wrongAnswersCount)) *
    100
  ).toFixed(1);

  return (
    <Container>
      <Header title="" onClose={handleOnClose} />
      <StyledTabs value={tab} onChange={handleChangeTab}>
        <TabsItem index="task">Задание</TabsItem>
        <TabsItem index="answer">Ответы слушателей</TabsItem>
      </StyledTabs>
      <TabsPanelContainer>
        <TabsPanel index="answer" value={tab}>
          <AnswerTab>
            <AnswerTitle>
              Правильных ответов {correctAnswersPercent}%
            </AnswerTitle>
            <Table>
              <TableHead>
                <StyledTableRow>
                  <HeaderTableCell align="left">ФИО</HeaderTableCell>
                  <HeaderTableCell align="left">Ответ</HeaderTableCell>
                  <HeaderTableCell scope="row" align="center">
                    <CounterContainer>
                      <p>Результат</p>
                      <StyledCounter color={primaryGreen}>
                        {correctAnswersCount}
                      </StyledCounter>
                      <StyledCounter color={secondaryRed}>
                        {wrongAnswersCount}
                      </StyledCounter>
                      <StyledCounter color={secondaryGrey}>
                        {notAnsweredCount}
                      </StyledCounter>
                    </CounterContainer>
                  </HeaderTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {lecturePromoUsers.map((item) => {
                  const a = teacherQuestion.answers.find(
                    (u) => item.id === u.userId
                  );
                  return (
                    <StyledTableRow key={item.id}>
                      <CommonTableCell align="left">
                        {item.firstname} {item.lastname}
                      </CommonTableCell>
                      <CommonTableCell align="left">
                        {a ? a.answer.answer : ""}
                      </CommonTableCell>
                      {a ? (
                        <ResultTableCell
                          scope="row"
                          align="center"
                          correct={a.correct}
                        >
                          {a.correct ? "Правильный" : "Неправильный"}
                        </ResultTableCell>
                      ) : null}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </AnswerTab>
        </TabsPanel>
        <TabsPanel index="task" value={tab}>
          <Box display={"flex"}>
            <ListContainer>
              <WebinarTaskList
                handleSelect={handleSelect}
                selectedTask={selectedIndex}
              />
            </ListContainer>
            <SelectedTaskContainer>
              {selectedTask && (
                <StyledScrollContainer>
                  <TaskQuestion
                    question={selectedTask.question}
                    videoUrl={selectedTask.video}
                    imageUrl={selectedTask.image}
                  />
                  <TaskAnswers
                    webinarId={webinarId}
                    id={selectedTask.id}
                    questionId={selectedTask.id}
                    answers={selectedTask.answers}
                    hint={selectedTask.hint}
                    disabled
                  />
                  {teacherQuestion.id !== null ? (
                    <Button
                      buttonTheme="error"
                      size="medium"
                      onClick={() => handleOnClose()}
                    >
                      Завершить задание
                    </Button>
                  ) : (
                    <Button
                      buttonTheme="warning"
                      size="medium"
                      onClick={() => handleOpenTask(selectedTask)}
                    >
                      Старт задания
                    </Button>
                  )}
                </StyledScrollContainer>
              )}
            </SelectedTaskContainer>
          </Box>
        </TabsPanel>
      </TabsPanelContainer>
    </Container>
  );
};

export default WebinarTask;
