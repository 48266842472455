import { useCallback, useEffect, useRef, useState } from "react";
import type { FormEventHandler } from "react";
import { primaryWhite } from "constants/color";
import { ChatInput } from "shared";
import { CSSProperties } from "@material-ui/styles";
import preventDefault from "helpers/preventDefault";
import {
  Arrow,
  BodyChatContainer,
  Header,
  InputContainer,
  Message,
  SupportWidgetContainer,
} from "./styledComponents";
import { IChatMessage } from "api/models/chat";
import { howMuchTimeSpent } from "../../helpers/howMuchTimeSpent";

type ChatProps = {
  isOpen?: boolean;
  type: "history" | "realTime";
  title: string;
  messageList: IChatMessage[];
  handleClickOpen?: () => void;
  handleSend?: (message: any) => void;
};

export const Chat = ({
  isOpen,
  handleClickOpen,
  messageList,
  type,
  title,
  handleSend,
}: ChatProps) => {
  const [isOpenState, setOpenState] = useState(isOpen);
  const containerRef = useRef<null | HTMLDivElement>(null);
  const chatContainer = useRef<null | HTMLDivElement>(null);

  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<IChatMessage[]>(messageList);
  const [left, setLeft] = useState<number | string>("none");
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    scrollToContainerBottom();
  }, [messages]);

  useEffect(() => {
    setMessages(messageList);
  }, [messageList]);

  const style: CSSProperties = {
    left: isOpenState ? left : "auto",
    height: isOpenState ? height : 44,
    bottom: 0,
  };

  const handleOpen = useCallback(() => {
    if (handleClickOpen) {
      handleClickOpen();
    } else {
      setOpenState(!isOpenState);
    }
  }, [isOpenState, handleClickOpen]);

  useEffect(() => {
    setOpenState(isOpen);
  }, [isOpen]);

  const handleChange = useCallback(
    (e: any) => {
      setMessage(e.target.value);
    },
    [setMessage]
  );

  const scrollToContainerBottom = () => {
    chatContainer.current?.scrollTo({
      top: chatContainer.current?.scrollHeight,
    });
  };

  const handleSendMessage = useCallback(
    (e: FormEventHandler<HTMLFormElement>) => {
      //@ts-ignore
      e.preventDefault();
      if (handleSend) {
        handleSend(message);
      }
      setMessage("");
      return false;
    },
    // eslint-disable-next-line
    [message, messages]
  );

  const handleDrugAndDropMouseDown = (e: any) => {
    const rect = e.target.offsetParent?.getBoundingClientRect();

    const clickOptions = {
      left: e.clientX - rect.left,
      right: rect.right,
      offsetTop: rect.height + e.clientY,
    };

    window.onselectstart = () => false;
    //@ts-ignore
    document.body.style = "cursor: move";
    document.onmouseup = handleDrugAndDropMouseUp;
    document.onmousemove = (e: MouseEvent) =>
      drugAndDropMouseMove(e, clickOptions);
  };

  const handleDrugAndDropMouseUp = () => {
    //@ts-ignore
    document.body.style = "";
    window.onselectstart = null;
    document.onmousemove = null;
    document.onmouseup = null;
  };

  const drugAndDropMouseMove = (e: MouseEvent, options: any) => {
    let leftCoordinate = e.pageX - options.left;

    const containerWidth = containerRef.current!.clientWidth;
    const documentWidth = document.documentElement.clientWidth;

    const collapseLeft = leftCoordinate <= 0;
    const collapseRight = containerWidth + leftCoordinate >= documentWidth;

    if (collapseLeft) {
      leftCoordinate = 0;
    }
    if (collapseRight) {
      leftCoordinate = documentWidth - containerWidth;
    }

    setLeft(leftCoordinate);
    setHeight(options.offsetTop - e.clientY);
  };

  return (
    <SupportWidgetContainer
      isOpen={isOpenState}
      style={style}
      ref={containerRef}
    >
      <Header
        onClick={isOpenState ? () => {} : handleOpen}
        isOpen={isOpenState}
        onMouseDown={handleDrugAndDropMouseDown}
      >
        {title}
        <Arrow
          isRotate={isOpenState}
          icon="ArrowUpIcon"
          size="s"
          color={primaryWhite}
          onClick={isOpenState ? handleOpen : () => {}}
          onMouseDown={preventDefault}
        />
      </Header>
      <BodyChatContainer ref={chatContainer}>
        {messages.map((message) => (
          <Message
            key={message.id}
            author={`${message.firstname ? message.firstname + " " : ""}${
              message.lastname
            }`}
            message={message.message}
            time={howMuchTimeSpent(message.created_at)}
            isYour={false}
          />
        ))}
        {type === "realTime" && (
          <InputContainer
            //@ts-ignore
            onSubmit={handleSendMessage}
            method="post"
            action={""}
          >
            <ChatInput
              value={message}
              onChange={handleChange}
              //@ts-ignore
              onClick={handleSendMessage}
            />
          </InputContainer>
        )}
      </BodyChatContainer>
    </SupportWidgetContainer>
  );
};
