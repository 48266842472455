import { PaperContainer } from "containers";
import styled from "styled-components";

export const BodyContainer = styled(PaperContainer)`
  border-top: none;
  height: calc(100% - 114px);
  border-radius: 0 0 10px 10px;
  box-shadow: none;
  display: flex;
  flex-direction: column;
`;
