import styled from "styled-components";
import course1 from "./assets/images/course1.png";
import course2_1 from "./assets/images/course2_1.png";
import course2_2 from "./assets/images/course2_2.png";
import course2_3 from "./assets/images/course2_3.png";

import course3 from "./assets/images/course3.png";
const ContainerTest = styled.div`
    max-width: calc(100vw - 20px);
    margin-bottom: 140px;
    width: 100%;
    margin-inline: auto;
    @media (max-width: 768px) {
      margin-bottom: 30px !important;
    }
  `;
export default function CourseComponent({Title, primary }) {
  const TitleN = styled(Title)`

    margin-bottom: 20px;
    @media (max-width: 768px){
    margin-bottom: 12px;}
  `;
  const Subtitle = styled.p`
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 40px;
        @media (max-width: 768px){
    margin-bottom: 20px;}
  `;
  const HorizontalWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 16px;
    @media (max-width: 768px){
    flex-direction: column;
    gap: 10px;
    }
  `;
  const StepBlock = styled.div`
    background: ${primary};
    border-radius: 24px;
    overflow: hidden;
    width: 100%;
    
    padding: 40px 24px 374px 24px;
    display: flex;
    flex-direction: column;
position: relative;
  `;
  const StepBlockTitle = styled.h3`
    color: white;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px;
    font-weight: 600;
  `;
  const CourseBlock = styled(ContainerTest)`
  display: flex;
  flex-direction: column;

  margin-bottom: 140px;`
  const StepBlockText = styled.h3`
    color: white;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 40px;
  `;
  const CourseImageCompilation = styled.div`
  display: flex;
position: absolute;
bottom: 0;
height: 374px;
align-self: center;
  width: 314px;
  >img:first-child {
  position: absolute;
  top: 0;
  width: 314px;
  height: 116px;
  object-fit: cover;
  }
   >img:nth-child(2) {
  position: absolute;
  top: 80px;
  height: 176px;
  width: 314px;
  object-fit: cover;
  }
   >img:last-child {
  position: absolute;
  bottom: 0;
  width: 314px;
  height: 164px;
  object-fit: cover;
  }
  `
  const StepBlockImage = styled.div`
  position: absolute;
  align-self: center;
  bottom: 0;
    background: url(${course1});
    background-size: cover;
    height: 374px;
    width: 314px;
  `;
  return (
    <>
<CourseBlock>
      <TitleN>Полный курс теории ПДД в режиме онлайн
      </TitleN>
      <Subtitle>Никаких скучных офлайн-лекций и неудобных бумажных материалов — объясняем человеческим языком</Subtitle>
      <iframe
        style={{ height: window.innerWidth <= 768 ? 250 :652, width: "100%", marginBottom: 6 }}
        src="https://kinescope.io/embed/vAbBzjFkF6N3z2PCwCcpZF"
        allow="autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;"
        frameborder="0"
        allowfullscreen=""
      ></iframe>
      <HorizontalWrapper>
        <StepBlock>
          <StepBlockTitle>Конспект для каждого урока</StepBlockTitle>
          <StepBlockText>
            Мы знаем, что у каждого свой способ лучше запомнить материал —
            конспект останется у тебя навсегда
          </StepBlockText>
          <StepBlockImage src={course1}></StepBlockImage>
        </StepBlock>
        <StepBlock>
          <StepBlockTitle>Простой для вас интерфейс</StepBlockTitle>
          <StepBlockText>
          Простой для вас интерфейс
          </StepBlockText>
       <CourseImageCompilation>
        <img src={course2_1} alt="" />
        <img src={course2_2} alt="" />
        <img src={course2_3} alt="" />
       </CourseImageCompilation>
        </StepBlock>
        <StepBlock>
          <StepBlockTitle>Эксперт-курс для учеников          </StepBlockTitle>
          <StepBlockText>
          Простой для вас интерфейс
          </StepBlockText>
          <StepBlockImage src={course3}></StepBlockImage>
        </StepBlock>
      </HorizontalWrapper>
      </CourseBlock>
    </>
  );
}
