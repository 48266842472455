import MenuItem from "@material-ui/core/MenuItem";
import { PropsWithChildren } from "react";

type Props = {
  value: any;
};

const Option = ({ value, ...props }: PropsWithChildren<Props>) => {
  return <MenuItem value={value} {...props} />;
};

export default Option;
