export const primaryWhite = "#FFFFFF";
export const primaryBlack = "#1F2021";
export const primaryGreen = "#4AC365";
export const primaryGreenHover = "#55D071";
export const primaryGreenPressed = "#3CB758";

export const secondaryGrey = "#62686B";
export const secondaryGreyHover = "rgba(98, 104, 107, 0.03)";
export const secondaryGreyActive = "rgba(98, 104, 107, 0.08)";
export const secondaryLightGray = "#DADDE4";
export const secondaryYellow = "#F1C33D";
export const secondaryYellowHover = "#F6CF5D";
export const secondaryYellowActive = "#ECB925";
export const secondaryRed = "#FC5E5E";

export const secondaryGreyBorder = "#FBFBFB";

export const opacityLightGrey20 = "rgba(218, 221, 228, 0.2)";
export const opacityLightGrey10 = "rgba(218, 221, 228, 0.1)";
export const opacityYellow20 = "rgba(241, 195, 61, 0.2)";
export const opacityYellow10 = "rgba(241, 195, 61, 0.1)";
export const opacityGreen10 = "rgba(74, 195, 101, 0.1)";
export const opacityGreen20 = "rgba(74, 195, 101, 0.2)";
export const opacityRed10 = "rgba(252, 94, 94, 0.1)";
export const opacityRed20 = "rgba(252, 94, 94, 0.2)";
export const opacityGrey5 = "rgba(98, 104, 107, 0.05)";

export const shadowSideMenu = "rgba(225, 225, 232, 0.4)";
export const shadowPaperContainer = "rgba(225, 225, 232, 0.55)";

const Colors = {
  primaryWhite,
  primaryBlack,
  primaryGreen,
  primaryGreenHover,
  primaryGreenPressed,
  secondaryGrey,
  secondaryGreyHover,
  secondaryGreyActive,
  secondaryLightGray,
  secondaryYellow,
  secondaryYellowHover,
  secondaryYellowActive,
  secondaryRed,
  secondaryGreyBorder,
  opacityLightGrey20: opacityLightGrey20,
  opacityLightGrey10: opacityLightGrey10,
  opacityYellow20: opacityYellow20,
  opacityYellow10: opacityYellow10,
  opacityGreen10: opacityGreen10,
  opacityGreen20: opacityGreen20,
  opacityRed10: opacityRed10,
  opacityRed20: opacityRed20,
  opacityGrey5: opacityGrey5,
  shadowSideMenu,
  shadowPaperContainer,
};

export default Colors;
