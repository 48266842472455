/* eslint-disable no-restricted-globals */

type Device = {
  deviceId: string;
  groupId: string;
  id: string;
  kind: string;
  label: string;
  [key: string]: any;
};

type Permission = {
  hasMicrophone: boolean;
  hasSpeakers: boolean;
  hasWebcam: boolean;
  isMicrophoneAlreadyCaptured: boolean;
};

const navigator: any = window.navigator;

if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
  // Firefox 38+ seems having support of enumerateDevicesx
  navigator.enumerateDevices = function (callback: any) {
    navigator.mediaDevices.enumerateDevices().then(callback);
  };
}

let MediaDevices: Array<Device> = [];

const isHTTPs = location.protocol === "https:";
let canEnumerate = false;

if (
  typeof MediaStreamTrack !== "undefined" &&
  "getSources" in MediaStreamTrack
) {
  canEnumerate = true;
} else if (
  navigator.mediaDevices &&
  !!navigator.mediaDevices.enumerateDevices
) {
  canEnumerate = true;
}

let hasMicrophone = false;
let hasSpeakers = false;
let hasWebcam = false;

let isMicrophoneAlreadyCaptured = false;
let isWebcamAlreadyCaptured = false;

export default function checkDeviceSupport(
  callback: (devices?: Array<Device>, permission?: Permission) => void
) {
  if (!canEnumerate) {
    return;
  }

  if (
    !navigator.enumerateDevices &&
    window.MediaStreamTrack &&
    //@ts-ignore
    window.MediaStreamTrack.getSources
  ) {
    //@ts-ignore
    navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(
      window.MediaStreamTrack
    );
  }

  if (!navigator.enumerateDevices && navigator.enumerateDevices) {
    navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
  }

  if (!navigator.enumerateDevices) {
    if (callback) {
      callback();
    }
    return;
  }

  MediaDevices = [];
  navigator.enumerateDevices(function (devices: Array<Device>) {
    devices.forEach(function (_device: Device) {
      const device: Device = {} as Device;
      for (const d in _device) {
        device[d] = _device[d];
      }
      if (device.kind === "audio") {
        device.kind = "audioinput";
      }
      if (device.kind === "video") {
        device.kind = "videoinput";
      }

      let skip;
      MediaDevices.forEach(function (d: Device) {
        if (d.id === device.id && d.kind === device.kind) {
          skip = true;
        }
      });

      if (skip) {
        return;
      }

      if (!device.deviceId) {
        device.deviceId = device.id;
      }
      if (!device.id) {
        device.id = device.deviceId;
      }
      if (!device.label) {
        device.label = "Please invoke getUserMedia once.";
        if (!isHTTPs) {
          device.label =
            "HTTPs is required to get label of this " +
            device.kind +
            " device.";
        }
      } else {
        if (device.kind === "videoinput" && !isWebcamAlreadyCaptured) {
          isWebcamAlreadyCaptured = true;
        }
        if (device.kind === "audioinput" && !isMicrophoneAlreadyCaptured) {
          isMicrophoneAlreadyCaptured = true;
        }
      }
      if (device.kind === "audioinput") {
        hasMicrophone = true;
      }
      if (device.kind === "audiooutput") {
        hasSpeakers = true;
      }
      if (device.kind === "videoinput") {
        hasWebcam = true;
      }

      MediaDevices.push(device);
    });

    if (callback) {
      callback(MediaDevices, {
        hasMicrophone,
        hasSpeakers,
        hasWebcam,
        isMicrophoneAlreadyCaptured,
      });
    }
  });
}
