import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export function useChat<ChatMessagesType>({
  fetchChatMessage,
  chatMessageSelector,
}: {
  fetchChatMessage: any;
  chatMessageSelector: any;
}) {
  const [time, setTime] = useState(new Date());
  const chatMessages: ChatMessagesType = useSelector(chatMessageSelector);
  const chatContainerRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fetchChatMessage) {
      dispatch(fetchChatMessage());
    }
  }, [dispatch, fetchChatMessage]);

  useEffect(() => {
    const id = setInterval(() => {
      setTime(new Date());
    }, 2000);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current?.scrollTo({
        top: chatContainerRef.current?.scrollHeight,
      });
    }
  }, [chatMessages]);

  return { chatContainerRef, chatMessages, time };
}
