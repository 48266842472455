import { DefaultModalContainer } from "containers";
import styled from "styled-components";

import { Button } from "components";

export const Container = styled(DefaultModalContainer)`
  display: flex;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
`;

export const Subtitle = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.heading6FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const ButtonWrapper = styled.div`
  justify-content: center;
  align-items: center;
  padding: 20px 10px 0;
`;

export const CancelButton = styled(Button)`
  width: 100%;
  margin: 10px;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  color: ${(props) => props.theme.colors.secondaryRed};
  border: solid;
  border-color: ${(props) => props.theme.colors.secondaryRed};
  border-width: 1px;
  :hover {
    color: ${(props) => props.theme.colors.primaryWhite};
    background-color: ${(props) => props.theme.colors.secondaryRed};
  }
`;
export const ConfirmButton = styled(Button)`
  width: 100%;
  margin: 10px;
  background-color: ${(props) => props.theme.colors.secondaryRed};
  :hover {
    background-color: ${(props) => props.theme.colors.secondaryRed};
  }
`;
