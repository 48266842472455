import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getUserRole } from "selectors/userInfo";
import { PropsWithChildren } from "react";

type Props = {
  rolesForRedirect: string | Array<string>;
  to?: string;
};
const RedirectForRole = ({
  children,
  rolesForRedirect,
  to = "/",
}: PropsWithChildren<Props>) => {
  const userRole = useSelector(getUserRole);

  //@ts-ignore
  const redirectRoles: Array<string> = [].concat(rolesForRedirect);

  return !redirectRoles.includes(userRole) ? (
    <>{children}</>
  ) : (
    <Redirect to={to} />
  );
};

export default RedirectForRole;
