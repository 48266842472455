import { useDispatch, useSelector } from "react-redux";
import { getIsOpen, toggleAppBarOpen, setAppBarOpen } from "./redux";
import { LECTURE_VIDEO_PATH, EXAM, BASE_COURSES_LESSON } from "constants/paths";
import { Sidebar } from "components";
import { Footer, List, Logo } from "./components";
import { RootState } from "store";
import { useCallback, useEffect, useMemo } from "react";
import { useIsRoute } from "utils";
import { useRouteMatch } from "react-router";

const AppBar = () => {
  const dispatch = useDispatch();
  const isWatchVideoLectureUrl = useIsRoute(LECTURE_VIDEO_PATH);
  const isWatchBaseCourseVideo = useRouteMatch(BASE_COURSES_LESSON);
  const isExam = useIsRoute(EXAM);
  const isCabinet = useIsRoute(["/cabinet", "/"]);
  const isOpen = useSelector(getIsOpen);
  const width = useSelector((state: RootState) => state.clientOptions.width);
  const isMobile = useMemo(() => width < 944, [width]);

  const handleOnClose = useCallback(() => {
    dispatch(toggleAppBarOpen());
  }, []);

  useEffect(() => {
    if (
      isMobile ||
      isWatchVideoLectureUrl ||
      isExam ||
      isWatchBaseCourseVideo
    ) {
      dispatch(setAppBarOpen(false));
    } else {
      dispatch(setAppBarOpen(true));
    }
  }, [
    isMobile,
    isWatchVideoLectureUrl,
    isExam,
    isCabinet,
    isWatchBaseCourseVideo,
  ]);

  return (
    <Sidebar open={isOpen} onClose={handleOnClose} isMobile={isMobile}>
      <Logo>Автошкола</Logo>
      <List />
      <Footer />
    </Sidebar>
  );
};

export default AppBar;
