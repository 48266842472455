import { PropsWithChildren, SyntheticEvent } from "react";
import styled from "styled-components";
import Button from "./CommonButton";
import { ButtonSize, ButtonTheme } from "./types";

const StyledButton = styled(Button)`
  background: ${(props) => props.theme.colors.secondaryYellow};
  border-radius: 9px;
  color: ${(props) => props.theme.colors.primaryWhite};
  font-size: ${(props) => props.theme.fontSizes.body1FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  width: 100%;
  padding: 12px 24px;

  &:hover {
    background: ${(props) => props.theme.colors.secondaryYellowHover};
  }

  &:active {
    background: ${(props) => props.theme.colors.secondaryYellowActive};
  }
`;

const Container = styled.div`
  border: 4px solid ${(props) => props.theme.colors.opacityYellow20};
  border-radius: 12px;
  box-sizing: border-box;
`;

type Props = {
  withBorder?: boolean;
  onClick?: (event: SyntheticEvent) => void;
  className?: string;
  leftIcon?: string;
  leftIconColor?: string;
  leftIconSize?: "s" | "m" | "l" | "xl";
  size?: ButtonSize;
  buttonTheme?: ButtonTheme;
};

const WarningButton = ({
  children,
  className,
  onClick,
  withBorder,
}: PropsWithChildren<Props>) => {
  if (withBorder) {
    return (
      <Container className={className}>
        <StyledButton onClick={onClick}>{children}</StyledButton>
      </Container>
    );
  }

  return (
    <StyledButton className={className} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default WarningButton;
