import { VideoStream, Chat } from "components";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { getLecturePromo } from "pages";
import { OnlineLectureWaiting } from "./layout";
import {
  postMessageToLectureChat,
  unsubscribeChat,
} from "api/UserLectureChatAPI";
import { getCabinetPromoLectureActionCreator } from "../../api/CabinetPromoApi";
import { getOnlineLectureWidgetChatMessages } from "layout/OnlineWebinarLectureWidget/redux";
import { useContext, useEffect, useMemo } from "react";
import { PingOnlineStatusContext } from "../../providers/PingOnlineStatusProvider";
import { getUserId } from "../../selectors/userInfo";
import { getWebinarId } from "../Webinars/redux";

import ImagePause from "./assets/WebinarPause.jpg";

const OnlineLecture = () => {
  const dispatch = useDispatch();
  const lecturePromo = useSelector(getLecturePromo);
  const messages = useSelector(getOnlineLectureWidgetChatMessages);
  const isWebinarShouldStart =
    lecturePromo &&
    lecturePromo.webinar_started_at &&
    !lecturePromo.webinar_finished_at;
  const userId = useSelector(getUserId);
  const webinarId = useSelector(getWebinarId);

  useEffect(() => {
    unsubscribeChat();
    dispatch(getCabinetPromoLectureActionCreator());
    return () => {
      checkOnlineStatus();
      removeWindowFocusHandler();
      unsubscribeChat();
    };
  }, []);

  const { checkOnlineStatus, addWindowFocusHandler, removeWindowFocusHandler } =
    useContext(PingOnlineStatusContext);

  useEffect(() => {
    if (isWebinarShouldStart && userId && webinarId) {
      checkOnlineStatus();
      addWindowFocusHandler();
    }
  }, [isWebinarShouldStart, userId, webinarId]);

  const chatMessages = useMemo(() => {
    return messages.filter((item) => !item.message.includes("Голос"));
  }, [messages]);

  const handleSendMessage = (message: string) => {
    dispatch(postMessageToLectureChat(message));
  };

  if (isWebinarShouldStart) {
    return (
      <>
        {lecturePromo && lecturePromo?.pause === null ? (
          <VideoStream
            lecturePromo={lecturePromo}
            url={lecturePromo.video_studio.video_stream_url}
            streamKey={lecturePromo.video_studio.video_stream_id as string}
          />
        ) : (
          <ContainerPause>
            <Reason>{lecturePromo?.pause?.name}</Reason>
            <Text>Пожалуйста, подождите...</Text>
          </ContainerPause>
        )}
        <Chat
          type="realTime"
          messageList={chatMessages}
          title="Чат трансляции"
          handleSend={handleSendMessage}
        />
      </>
    );
  }

  return <OnlineLectureWaiting />;
};

const Reason = styled.div`
  line-height: 45px;
  font-family: Montserrat Bold !important;
  color: #fff;
  font-size: 2.25em;
  max-width: 405px;
  text-align: center;
  margin-top: 3em;
`;

const Text = styled.div`
  line-height: 16px;
  font-family: Montserrat Bold !important;
  color: #fff;
  font-size: 1.25em;
  margin-top: 2em;
`;

const ContainerPause = styled.div`
  position: relative;
  margin-top: 50px;
  width: 100%;
  height: 660px;
  background: url(${ImagePause}) no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
`;

export default OnlineLecture;
