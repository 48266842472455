const Block = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.666687" width="32" height="32" rx="16" fill="#62686B" />
      <path
        d="M12.0667 23.8C10.9621 23.8 10.0667 22.9046 10.0667 21.8V14.6C10.0667 13.4954 10.9621 12.6 12.0667 12.6H12.5417V11C12.5417 9.89333 12.944 8.94987 13.7487 8.1696C14.5528 7.38987 15.5255 7 16.6667 7C17.808 7 18.7809 7.38987 19.5856 8.1696C20.3897 8.94987 20.7917 9.89333 20.7917 11V12.6H21.2667C22.3713 12.6 23.2667 13.4954 23.2667 14.6V21.8C23.2667 22.9046 22.3713 23.8 21.2667 23.8H12.0667ZM14.1917 12.6H19.1417V11C19.1417 10.3333 18.9011 9.76667 18.4198 9.3C17.9386 8.83333 17.3542 8.6 16.6667 8.6C15.9792 8.6 15.3948 8.83333 14.9136 9.3C14.4323 9.76667 14.1917 10.3333 14.1917 11V12.6ZM16.6667 19.8C17.1205 19.8 17.509 19.6435 17.8324 19.3304C18.1553 19.0168 18.3167 18.64 18.3167 18.2C18.3167 17.76 18.1553 17.3832 17.8324 17.0696C17.509 16.7565 17.1205 16.6 16.6667 16.6C16.213 16.6 15.8247 16.7565 15.5018 17.0696C15.1784 17.3832 15.0167 17.76 15.0167 18.2C15.0167 18.64 15.1784 19.0168 15.5018 19.3304C15.8247 19.6435 16.213 19.8 16.6667 19.8Z"
        fill="white"
      />
    </svg>
  );
};

export default Block;
