import { useEffect, useRef, useState } from "react";
import { getSlideUrl } from "utils";
import { Canvas, Container, DrawField, Iframe } from "./styledComponents";

let timeoutId: number | null;

const ListenerOnlineWebinar = () => {
  const [draw, setDraw] = useState<string | undefined>(
    localStorage.getItem("savedDrawing") || undefined
  );
  const [slide, setSlide] = useState<number>(
    +localStorage.getItem("activeSlide")! || 1
  );

  const [canvasScale, setCanvasScale] = useState<number>(1);

  const drawFieldRef = useRef<HTMLDivElement>(null);

  const changeCanvasScale = () => {
    setCanvasScale(
      (drawFieldRef.current?.offsetWidth! * 100) /
        +localStorage.getItem("offsetWidth")!
    );
  };

  const getConvertedData = (data: string | undefined) => {
    const { width, ...obj } = JSON.parse(draw!);
    obj.width = "400vw";

    return JSON.stringify(obj);
  };

  useEffect(() => {
    setInterval(() => {
      setDraw(() => localStorage.getItem("savedDrawing") || undefined);
      setSlide(() => +localStorage.getItem("activeSlide")! || 1);
    }, 500);

    window.addEventListener(`resize`, changeCanvasScale, false);

    return () => {
      clearInterval(timeoutId!);
    };
  }, []);

  useEffect(() => {
    changeCanvasScale();
  });

  return (
    <Container>
      <DrawField ref={drawFieldRef}>
        <Canvas
          scale={canvasScale}
          disabled
          hideGrid
          canvasWidth="400vw"
          canvasHeight="100%"
          backgroundColor="inherit"
          lazyRadius={0}
          saveData={getConvertedData(draw)}
          brushColor="transparent"
          catenaryColor="transparent"
          immediateLoading
        />
        <Iframe
          scale={(drawFieldRef.current?.offsetWidth! * 100) / 1920 || 1}
          scrolling="no"
          src={getSlideUrl(`${slide}`.length === 1 ? `0${slide}` : `${slide}`)}
        />
      </DrawField>
    </Container>
  );
};

export default ListenerOnlineWebinar;
