//@ts-ignore
// import worker from "workerize-loader!./worker"; // eslint-disable-line import/no-webpack-loader-syntax
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from "@reduxjs/toolkit";
import {
  appBarReducer,
  modalWindowReducer,
  appWidgetReducer,
  onlineWebinarLectureWidgetReducer,
  notificationReducer,
} from "layout";
import {
  cabinetReducer,
  onlineLectureReducer,
  webinarsReducer,
  testsReducer,
} from "pages";
import {
  userDevicesReducer,
  clientOptionsReducer,
  userInfoReducer,
  lessonsReducer,
  loaderReducer,
  modalRestrictionsReducer,
} from "reducers";
import lectureArchiveReducer from "pages/LectureArchive/redux/reducer";
import thunk, { ThunkDispatch } from "redux-thunk";

export const history = createBrowserHistory();

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history as any),
    appBar: appBarReducer,
    onlineLecture: onlineLectureReducer,
    modals: modalWindowReducer,
    modalRestrictions: modalRestrictionsReducer,
    notification: notificationReducer,
    appWidget: appWidgetReducer,
    userDevices: userDevicesReducer,
    onlineLectureWidget: onlineWebinarLectureWidgetReducer,
    webinars: webinarsReducer,
    clientOptions: clientOptionsReducer,
    userInfo: userInfoReducer,
    cabinet: cabinetReducer,
    tests: testsReducer,
    lessons: lessonsReducer,
    loader: loaderReducer,
    lectureArchive: lectureArchiveReducer,
  });

const madeWorkers = ({ getState }: { getState: any; dispatch: any }) => {
  // const initialState: RootState = getState();
  // const workerInstance = worker();
  // //@ts-ignore
  // workerInstance.addEventListener(
  //   "message",
  //   (message: {
  //     data: {
  //       type: "finish-workout";
  //       workout: { workoutId: WorkoutId; finished: number };
  //     };
  //   }) => {
  //     if (message.data.type === "finish-workout") {
  //       dispatch(
  //         finishWorkout({
  //           id: message.data.workout.workoutId,isVideoWatch
  //           finished: message.data.workout.finished,
  //         })
  //       );
  //     }
  //   }
  // );
  // workerInstance.watchOnWorkouts(initialState.tests.workouts);

  //@ts-ignore
  return (next) => (action) => {
    return next(action);
  };
};

//@ts-ignore
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createRootReducer(history as any),
  compose(
    composeEnhancer(
      applyMiddleware(routerMiddleware(history), thunk, madeWorkers)
    )
  )
);

const updateStorage = (key: string, dataStorage: any) => {
  const dataLocalStorage = localStorage.getItem(key);
  if (dataStorage) {
    if (!dataLocalStorage) {
      localStorage.setItem(key, JSON.stringify(dataStorage));
    } else if (JSON.stringify(dataStorage) !== dataLocalStorage) {
      localStorage.setItem(key, JSON.stringify(dataStorage));
    }
  }
};

store.subscribe(() => {
  const state = store.getState();
  updateStorage("exam", state.tests.exam);
  updateStorage("workouts", state.tests.workouts);
});

export type RootState = ReturnType<typeof store.getState>;
export type TThunkDispatch = ThunkDispatch<RootState, void, any>;
export type TGetState = () => RootState;

export default store;
