import { heading4FontSize } from "constants/style";
import { ChatMessageWrapper, MessageContainer } from "./styledComponents";

const Message = (props: any) => {
  return (
    <MessageContainer>
      <ChatMessageWrapper
        {...props}
        authorFontSize={heading4FontSize}
        messageFontSIze={heading4FontSize}
        timeFontSize={heading4FontSize}
      />
    </MessageContainer>
  );
};

export default Message;
