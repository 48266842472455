import { Option, Select } from "components";
import styled from "styled-components";
import ArchivesTableComponent from "../../ArchivesTable";

export const MainContainerWrapper = styled.div`
  margin-top: 16px;
  margin-right: 16px;
  width: 100%;

  @media screen and (max-width: 1245px) {
    margin-right: 0;
  }
`;

export const ArchivesTableTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 38px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const ArchivesTableTitle = styled.div`
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.heading5FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  margin-right: 8px;

  @media screen and (max-width: 500px) {
    font-size: ${(props) => props.theme.fontSizes.heading6FontSize};
    margin-bottom: 16px;
  }
`;

export const ArchivesTable = styled(ArchivesTableComponent)`
  margin-top: 16px;
`;

export const StyledSelect = styled(Select)`
  .MuiSelect-select {
    font-size: 1em;
  }
`;

export const StyledOption = styled(Option)`
  font-size: 1em !important;
`;
