const CalendarRightArrow = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.62911 3.60428C5.28304 3.94574 5.28304 4.50426 5.62911 4.84572L8.68278 7.85868C8.90096 8.07395 8.90096 8.42605 8.68278 8.64132L5.62911 11.6543C5.28304 11.9957 5.28304 12.5543 5.62911 12.8957C5.96867 13.2308 6.51443 13.2308 6.854 12.8957L10.6557 9.14475C11.1545 8.65255 11.1545 7.84745 10.6557 7.35525L6.854 3.60428C6.51443 3.26924 5.96867 3.26924 5.62911 3.60428Z"
      fill="#4AC365"
    />
  </svg>
);

export default CalendarRightArrow;
