import { Button } from "components";
import React from "react";
// import { getInteractiveTaskForTeacher } from "api/InteractiveTaskAPI";
// import { ITask, TTaskEntity } from "api/models/task";
// import { getWebinarInteractiveTaskList } from "pages/Webinars/redux";
import { closeModal } from "layout/ModalWindow/redux";
import { useDispatch } from "react-redux";
import {
  Container,
  Title,
  ButtonContainer,
  ButtonWrapper,
} from "./styledComponents";

type Props = {
  name: string;
  clickYes: () => void;
};

const ArchivePlaybackModal = ({ name, clickYes }: Props) => {
  const dispatch = useDispatch();

  const handleClickNo = () => {
    dispatch(closeModal(name));
  };

  return (
    <Container>
      <Title>Лекция просмотрена. Повторить сначала?</Title>
      <ButtonContainer>
        <ButtonWrapper>
          <Button onClick={clickYes}>Да</Button>
        </ButtonWrapper>
        <ButtonWrapper>
          <Button onClick={handleClickNo}>Нет</Button>
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  );
};

export default ArchivePlaybackModal;
