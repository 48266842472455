import {
  Container,
  Error,
  InputContainer,
  StyledButton,
  ForgotButton,
  RememberContainer,
  ErrorContainer,
} from "./styledComponents";
import { Input } from "../../../components";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useFormik } from "formik";
import { userLoginActionCreator } from "../../../api/UserAPI";
import { push } from "connected-react-router";
import { getToken } from "../../../utils";

const validationSchema = object({
  login: string().required("Необходимо заполнить «E-mail»"),
  password: string().required("Необходимо заполнить «Пароль»"),
});

type Props = {
  handleForgot: () => void;
};

export const LoginForm = ({ handleForgot }: Props) => {
  const [error, setError] = useState<null | string>("");
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLoginError = () => {
    setError("Неверный логин или пароль");
  };

  const form = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setError(null);
      dispatch(
        userLoginActionCreator({
          ...values,
          handleError: handleLoginError,
          pushToPath: history.push,
        })
      );
    },
  });

  useEffect(() => {
    if (getToken()) {
      dispatch(push("/"));
    }
  }, [dispatch]);

  return (
    <Container>
      <form onSubmit={form.handleSubmit}>
        <ErrorContainer>{error && <Error>{error}</Error>}</ErrorContainer>
        {/*<FormTitle> {`Вход в систему\nдистанционного обучения`}</FormTitle>*/}
        <InputContainer>
          <Input
            placeholder="Введите вашу почту"
            id="login"
            value={form.values.login}
            onChange={form.handleChange}
            error={form.touched.login && Boolean(form.errors.login)}
          />
          <ErrorContainer>
            {form.errors.login && <Error>{form.errors.login}</Error>}
          </ErrorContainer>
        </InputContainer>
        <InputContainer>
          <Input
            placeholder="Введите пароль"
            id="password"
            type="password"
            value={form.values.password}
            onChange={form.handleChange}
            error={form.touched.password && Boolean(form.errors.password)}
          />
          <ErrorContainer>
            {form.errors.password && <Error>{form.errors.password}</Error>}
          </ErrorContainer>
        </InputContainer>
        <RememberContainer>
          <ForgotButton onClick={() => handleForgot()}>
            Забыли пароль?
          </ForgotButton>
        </RememberContainer>
        <StyledButton type="submit">Войти</StyledButton>
      </form>
    </Container>
  );
};
