import { ContentContainer, MediaQueryContainer } from "containers";
import { MainContainer, SidebarContainer } from "./components";

const Cabinet = () => {
  return (
    <ContentContainer>
      <MainContainer />
      <MediaQueryContainer minWidth={1245}>
        <SidebarContainer />
      </MediaQueryContainer>
    </ContentContainer>
  );
};

export default Cabinet;
