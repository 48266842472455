import { createSlice } from "@reduxjs/toolkit";
import { produce, Immutable } from "immer";

type LectureArchive = {
  list: Immutable<LectureVideo[]>;
  statusLoading: "OK" | "LOADING" | "WRONG";
  info: LectureArchiveInfo[];
  currentLectureId: number | null;
  interactiveTaskStats: InteractiveTasksStats | null;
};

export type InteractiveTasksStats = {
  lessons: InteractiveTaskStats;
  interactive_tasks_total: number;
  interactive_tasks_success: number;
  interactive_tasks_fail: number;
};

type InteractiveTaskStats = Record<
  string,
  {
    interactive_tasks_total: number;
    interactive_tasks_success: number;
    interactive_tasks_fail: number;
  }
>;

export type LectureArchiveInfo = {
  id: number;
  tasks: any[];
  timings: any[];
  chat: any[];
};

export type LectureVideo = {
  id: number;
  interactiveTasksCount: number;
  video_url: string;
  video_thumbnail: string;
  video_info: string;
  webinar_started_at: number;
  webinar_finished_at: number;
  topic: {
    id: number;
    name: string;
    category_id: number;
  };
  actions: {
    archive_watch_time: number;
    is_archived_watched: number;
    lesson_id: number;
    user_id: number;
  };
};

const lectureArchiveReducer = createSlice({
  name: "lectureArchive",
  initialState: {
    list: [],
    info: [],
    currentLectureId: null,
    statusLoading: "LOADING",
    interactiveTaskStats: null,
  } as LectureArchive,
  reducers: {
    setLectureArchive: (state, action: { payload: LectureVideo[] }) => {
      state = produce(state, () => {
        state.list = action.payload;
        state.statusLoading = "OK";
      });
    },
    setInteractiveTaskStats: (state, action) => {
      state.interactiveTaskStats = action.payload;
    },
    setBadRequestLectureArchive: (state) => {
      state.statusLoading = produce(state.statusLoading, () => {
        return "WRONG";
      });
    },
    setLectureArchiveInfo: (state, action: { payload: LectureArchiveInfo }) => {
      state.currentLectureId = action.payload.id;

      const info = state.info.some((item) => item.id === action.payload.id);
      const video = state.list.find((item) => item.id === action.payload.id);
      if (!info && video) {
        state.info.push(action.payload);
      }
    },
  },
});

export const {
  setLectureArchive,
  setBadRequestLectureArchive,
  setLectureArchiveInfo,
  setInteractiveTaskStats,
} = lectureArchiveReducer.actions;
export default lectureArchiveReducer.reducer;
