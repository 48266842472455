import preventDefault from "helpers/preventDefault";
import { Dot, HeaderItemContainer } from "./styledComponents";
import { PropsWithChildren } from "react";

type Props = {
  active: boolean;
  preview?: boolean;
  onClick?: () => void;
  revertColor?: boolean;
};

const ChatHeaderListItem = ({
  children,
  active,
  preview,
  onClick,
  revertColor,
}: PropsWithChildren<Props>) => {
  return (
    <HeaderItemContainer
      revertColor={revertColor}
      active={active}
      onClick={onClick}
      onMouseDown={preventDefault}
    >
      {!preview && <Dot active={active} revertColor={revertColor} />}
      {children}
    </HeaderItemContainer>
  );
};

export default ChatHeaderListItem;
