import { MainHeader, WebinarHeader, EasyHeader } from "./layout";
import { useDispatch, useSelector } from "react-redux";
import { getLecturePromo } from "pages";
import { useRouteMatch, useLocation, useHistory } from "react-router";
import { RootState } from "store";
import {
  WEBINARS_PATH,
  LECTURE_VIDEO_PATH,
  EXAM,
  WORKOUT,
  TESTS,
  BASE_COURSES_LESSON,
} from "constants/paths";
import { HeaderContainer } from "./styledComponents";
import queryString from "query-string";
import { useCallback, useMemo } from "react";
import { closeModal, openModal } from "layout";
import { finishWorkout, getWorkoutList, removeExam } from "pages/Tests/redux";
import { getLectureVideo } from "pages/LectureArchive/redux";
import { Loader } from "../../components";

const imageSvg = () => {
  return (
    <svg width="102" height="102" viewBox="0 0 102 102" fill="none">
      <rect
        y="50.9121"
        width="72"
        height="72"
        rx="4"
        transform="rotate(-45 0 50.9121)"
        fill="#F1C33D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.0711 48.0837C5.50901 49.6458 5.50901 52.1784 7.0711 53.7405L48.0833 94.7527C49.6454 96.3148 52.1781 96.3148 53.7401 94.7527L94.7523 53.7405C96.3144 52.1784 96.3144 49.6458 94.7523 48.0837L53.7401 7.07149C52.1781 5.50939 49.6454 5.50939 48.0833 7.07149L7.0711 48.0837ZM9.89953 49.4979C9.11848 50.2789 9.11848 51.5453 9.89953 52.3263L49.4975 91.9243C50.2786 92.7053 51.5449 92.7053 52.3259 91.9243L91.9239 52.3263C92.705 51.5453 92.705 50.2789 91.9239 49.4979L52.3259 9.89992C51.5449 9.11887 50.2786 9.11887 49.4975 9.89992L9.89953 49.4979Z"
        fill="#1F2021"
      />
      <path
        d="M31.9116 58.6848L39.8113 67.9121L47.666 58.6848H43.029V48.2415C43.029 46.0834 43.8937 44.1294 45.2901 42.715C46.6866 41.3005 48.6221 40.4255 50.7528 40.4255C55.0144 40.4255 58.4756 43.9248 58.4756 48.2415V67.8957H64.9116V48.2415C64.9116 40.3278 58.5689 33.9121 50.756 33.9121C46.8496 33.9121 43.3048 35.516 40.7445 38.1089C38.1847 40.7024 36.593 44.2847 36.593 48.2416V58.6848H31.9116V58.6848Z"
        fill="#1F2021"
      />
    </svg>
  );
};

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const lecturePromo = useSelector(getLecturePromo);
  const workouts = useSelector(getWorkoutList);
  const isLectureComing = useMemo(
    () => lecturePromo?.webinar_started_at && !lecturePromo.webinar_finished_at,
    [lecturePromo]
  );

  const lectureId = useSelector(
    (state: RootState) => state.lectureArchive.currentLectureId
  );

  const video = useSelector((state: RootState) => {
    if (lectureId) return getLectureVideo(state, lectureId?.toString());
  });

  const isWatchVideoLectureUrl = useRouteMatch(LECTURE_VIDEO_PATH);
  const isWatchBaseCourseVideo = useRouteMatch(BASE_COURSES_LESSON);

  const isWebinarUrl = useRouteMatch(WEBINARS_PATH);
  const isExam = useRouteMatch(EXAM);

  const search = queryString.parse(location.search);

  const isWorkout = useMemo(() => {
    return search.type === "workout";
  }, [search]);

  const handleOpenModal = useCallback(() => {
    const ticketId =
      search.ticket && !isNaN(Number(search.ticket))
        ? Number(search.ticket)
        : null;
    const lessonId =
      search.lesson && !isNaN(Number(search.lesson))
        ? Number(search.lesson)
        : null;

    if (ticketId !== null || lessonId != null) {
      const w = workouts.find(
        (item) =>
          item.workoutId === `ticket_${ticketId}_id` ||
          item.workoutId === `lesson_${lessonId}_id`
      );
      if (w && w.finished) {
        history.push(WORKOUT);
        return;
      }
    }

    dispatch(
      openModal({
        name: "AskModal",
        title: "Вы уверены, что хотите завершить тест?",
        text: "Ваш результат не будет сохранен, если Вы вернетесь назад к списку билетов",
        imageSvg,
        buttons: [
          {
            text: "Завершить",
            onClick: () => {
              if (isWorkout) {
                if (ticketId !== null) {
                  dispatch(
                    finishWorkout({
                      id: `ticket_${ticketId}_id`,
                      finished: Date.now(),
                    })
                  );
                }
                if (lessonId !== null) {
                  dispatch(
                    finishWorkout({
                      id: `lesson_${lessonId}_id`,
                      finished: Date.now(),
                    })
                  );
                }
              } else {
                dispatch(removeExam());
                history.replace(TESTS);
              }
              dispatch(closeModal("AskModal"));
            },
            type: "secondary",
          },
          {
            text: "Продолжить тест",
            onClick: () => dispatch(closeModal("AskModal")),
            type: "primary",
          },
        ],
      })
    );
  }, [dispatch, workouts]);

  if (isWebinarUrl && isLectureComing) {
    return <WebinarHeader />;
  }

  if (isWatchVideoLectureUrl || isWatchBaseCourseVideo) {
    return (
      <HeaderContainer type="easyHeader">
        {isWatchVideoLectureUrl && video === undefined ? (
          <Loader size={25} style={{ marginTop: -85 }} />
        ) : (
          <EasyHeader
            title={`${video?.topic?.name || "Базовый курс"}`}
            afterBack={() => history.goBack()}
          />
        )}
      </HeaderContainer>
    );
  }

  if (isExam) {
    const search = queryString.parse(location.search);
    let n = null;
    if (search.ticket && !isNaN(Number(search.ticket))) {
      n = Number(search.ticket);
    }

    return (
      <HeaderContainer type="easyHeader">
        <EasyHeader
          title={n !== null ? `Билет №${n}` : ""}
          afterBack={handleOpenModal}
        />
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer>
      <MainHeader />
    </HeaderContainer>
  );
};

export default Header;
