import { useEffect, useState } from "react";
import { Button } from "components";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "layout";
import {
  finishWebinarActionCreator,
  getListReasonsByStopWebinar,
  setReasonWebinar,
  unsetReasonWebinar,
} from "api/WebinarAPI";
import { getLecturePromo, setWebinarIsStarted } from "pages";
import {
  ButtonWrapper,
  CompleteWebinarButton,
  TaskListButton,
  WebinarHeaderContainer,
} from "./styledComponents";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const WebinarHeader = () => {
  const lecturePromo = useSelector(getLecturePromo);
  const dispatch = useDispatch();

  const [reason, setSelectorReason] = useState("");
  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getListReasonsByStopWebinar();
      setReasons(data);
    };

    getData();
  }, []);

  const handleCancelWebinar = () => {
    if (lecturePromo) {
      dispatch(finishWebinarActionCreator(lecturePromo.id));
    }
  };

  const handleOpenTaskList = () => {
    dispatch(openModal({ name: "WebinarTask" }));
  };

  const handleOnClickBack = () => {
    dispatch(setWebinarIsStarted(false));
  };

  const handleChange = (event: any) => {
    setSelectorReason(event.target.value);
  };

  const handleStart = async () => {
    if (lecturePromo !== null) {
      unsetReasonWebinar(lecturePromo.id);
    }
  };

  const handleStop = async () => {
    if (lecturePromo !== null) {
      setReasonWebinar(lecturePromo.id, Number(reason));
    }
  };

  return (
    <WebinarHeaderContainer>
      <Button size="large" onClick={handleOnClickBack} buttonTheme="secondary">
        Назад
      </Button>

      {lecturePromo && (
        <ButtonWrapper>
          <FormControl style={{ minWidth: 400 }}>
            <InputLabel id="demo-simple-select-label">Причина паузы</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reason}
              label="Причина паузы"
              onChange={handleChange}
            >
              {reasons.map((item: any) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {lecturePromo.pause === null ? (
            <CompleteWebinarButton
              onClick={handleStop}
              buttonTheme={reason === "" ? "errorSecondary" : "primary"}
              disabled={reason === ""}
            >
              Начать паузу
            </CompleteWebinarButton>
          ) : (
            <CompleteWebinarButton onClick={handleStart} buttonTheme="error">
              Продолжить лекцию
            </CompleteWebinarButton>
          )}
        </ButtonWrapper>
      )}

      <ButtonWrapper>
        <TaskListButton onClick={handleOpenTaskList} buttonTheme="warning">
          Список заданий
        </TaskListButton>
        <CompleteWebinarButton
          onClick={handleCancelWebinar}
          buttonTheme="error"
        >
          Завершить лекцию
        </CompleteWebinarButton>
      </ButtonWrapper>
    </WebinarHeaderContainer>
  );
};

export default WebinarHeader;
