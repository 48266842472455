const Waiting = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.333496" width="32" height="32" rx="16" fill="#3CB758" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4331 8.51657C18.4504 8.10951 17.3971 7.89999 16.3334 7.89999C14.1851 7.89999 12.1249 8.75338 10.6058 10.2724C9.08679 11.7915 8.2334 13.8517 8.2334 16C8.2334 20.455 11.8541 24.1 16.3334 24.1C18.4817 24.1 20.5419 23.2466 22.061 21.7276C23.58 20.2085 24.4334 18.1482 24.4334 16C24.4334 14.9363 24.2239 13.883 23.8168 12.9003C23.4098 11.9175 22.8131 11.0246 22.061 10.2724C21.3088 9.52027 20.4159 8.92363 19.4331 8.51657ZM16.9334 11.2C16.9334 10.8686 16.6647 10.6 16.3334 10.6C16.002 10.6 15.7334 10.8686 15.7334 11.2V16.6L19.3502 18.7568C19.615 18.9148 19.9577 18.8299 20.1182 18.5667C20.2808 18.3 20.1949 17.9519 19.9269 17.7915L16.9334 16V11.2Z"
        fill="white"
      />
    </svg>
  );
};

export default Waiting;
