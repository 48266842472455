import { memo, useState, useRef, useEffect } from "react";
import getFinishTime from "utils/getFinishTime";
import { Time } from "../styledComponents";
import { getDistance } from "../utils/util";

const Timer = ({
  started,
  duration,
}: {
  started: number;
  duration: number;
}) => {
  const [finishIn] = useState(getFinishTime(started, duration));
  const [activeTime, setActiveTime] = useState(getDistance(finishIn));
  const interval = useRef(
    setInterval(() => {
      setActiveTime(getDistance(finishIn));
    }, 1000)
  );

  useEffect(() => {
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  return (
    <Time>
      {!activeTime.includes("-") ? activeTime : "00:00"} до окончания теста
    </Time>
  );
};

export default memo(Timer);
