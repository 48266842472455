import { Icon, IconButton } from "components";
import { primaryWhite } from "constants/color";
import { Container, StyledSlider } from "./styledComponents";

type Props = {
  className?: string;
  onClear: () => void;
  activeTool: "pencil" | "eraser" | null;
  onCLickEraser: () => void;
  onClickPencil: () => void;
  onChangeBrush: any;
  brushValue: number;
};

const LectureControlPanel = ({
  className,
  onClear,
  activeTool,
  onCLickEraser,
  onClickPencil,
  onChangeBrush,
  brushValue,
}: Props) => {
  return (
    <Container className={className}>
      <IconButton active={activeTool === "pencil"} onClick={onClickPencil}>
        <Icon icon="PenIcon" size="m" color={primaryWhite} />
      </IconButton>
      <IconButton active={activeTool === "eraser"} onClick={onCLickEraser}>
        <Icon icon="UndoArrowIcon" size="m" color={primaryWhite} />
      </IconButton>
      <IconButton onClick={onClear}>
        <Icon icon="TrashIcon" size="m" color={primaryWhite} />
      </IconButton>
      <StyledSlider
        onChange={onChangeBrush}
        value={brushValue}
        step={1}
        max={20}
        min={1}
      />
    </Container>
  );
};

export default LectureControlPanel;
