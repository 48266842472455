import { PropsWithChildren, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { PageTitle } from "shared";
import { useRouteMatch } from "react-router";
import { ContentContainer } from "containers";
import { TabsItem, Tabs } from "components";
import { WORKOUT, START_EXAM, TICKETS_THEMES } from "constants/paths";

const ContentContainerWrapper = styled(ContentContainer)`
  display: block;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;
`;

const WrapperTabPanel = styled.div`
  border: 1px solid #dadde4;
  border-top: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 24px;

  @media screen and (max-width: 910px) {
    margin-bottom: 30px;
  }
`;

const Tests = ({ children }: PropsWithChildren<{}>) => {
  const history = useHistory();
  const isWorkout = useRouteMatch(WORKOUT);
  const isStartExam = useRouteMatch(START_EXAM);
  const isTicketsThemes = useRouteMatch(TICKETS_THEMES);

  const handleChangeTab = useCallback((value: string) => {
    history.push(value);
  }, []);

  const activeTab = useMemo(() => {
    if (isWorkout) {
      return WORKOUT;
    }
    if (isStartExam) {
      return START_EXAM;
    }
    if (isTicketsThemes) {
      return TICKETS_THEMES;
    }
  }, [isWorkout, isStartExam, isTicketsThemes]);

  return (
    <ContentContainerWrapper>
      <PageTitle>Тесты по билетам</PageTitle>
      <Wrapper>
        <Tabs value={activeTab} onChange={handleChangeTab}>
          <TabsItem index={WORKOUT}>Режим тренировки</TabsItem>
          <TabsItem index={START_EXAM}>Режим экзамена</TabsItem>
          <TabsItem index={TICKETS_THEMES}>Билеты по темам</TabsItem>
        </Tabs>
        <WrapperTabPanel>{children}</WrapperTabPanel>
      </Wrapper>
    </ContentContainerWrapper>
  );
};

export default Tests;
