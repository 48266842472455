import { ContentContainer } from "containers";
import { PageTitle } from "shared";
import LectureArchiveTable from "layout/ArchiveTable/LectureArchiveTable";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  min-height: 200px;
`;

const LectureArchiveWrapper = () => {
  return (
    <ContentContainer direction="column">
      <PageTitle>Видеоархив лекций</PageTitle>
      <Wrapper>
        <LectureArchiveTable />
      </Wrapper>
    </ContentContainer>
  );
};

export default LectureArchiveWrapper;
