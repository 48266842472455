import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const QuestionText = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.subtitle1FontSize};
  line-height: 20px;
`;

export const Image = styled.img`
  width: 100%;
  margin-bottom: 16px;
`;

export const Video = styled.video`
  width: 100%;
  margin-bottom: 16px;
  border-radius: 12px;
`;
