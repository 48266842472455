import { RootState } from "store";

export const getIsLectureComing = (state: RootState) =>
  state.onlineLecture.isLectureComing;

const getLectureName = (state: RootState) => state.onlineLecture.lectureName;

const getLectureStartDate = (state: RootState) => state.onlineLecture.startDate;

const getLectureStartTime = (state: RootState) => state.onlineLecture.startTime;

export const getLectureInfo = (state: RootState) => ({
  name: getLectureName(state),
  startDate: getLectureStartDate(state),
  startTime: getLectureStartTime(state),
});

export const getLectureLoaded = (state: RootState) =>
  state.onlineLecture.loaded;

export const getLectureLoading = (state: RootState) =>
  state.onlineLecture.loading;

export const getLectureTeacherName = (state: RootState) =>
  state.onlineLecture.teacher.name;

export const getLectureId = (state: RootState) => state.onlineLecture.lectureId;
