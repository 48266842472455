import {
  CloseIcon,
  CornerZone,
  HeaderContainer,
  Title,
} from "./styledComponents";
import { fontSizes } from "../../../constants/style";

type Props = {
  title?: string;
  onClose?: () => void;
  font?: keyof typeof fontSizes;
};

const Header = ({ title, onClose, font }: Props) => {
  const handleCloseModal = () => {
    onClose!();
  };

  return (
    <HeaderContainer>
      <Title fontSize={font}>{title}</Title>
      <CornerZone>
        {onClose && (
          <CloseIcon icon="CloseIcon" size="l" onClick={handleCloseModal} />
        )}
      </CornerZone>
    </HeaderContainer>
  );
};

export default Header;
