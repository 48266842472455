import { Option } from "components";
import { callEveryAt } from "helpers/CallEveryAt";
import checkDeviceSupport from "helpers/checkDeviceSupport";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserDevices } from "reducers";
import { getUserAudioInputDevices } from "selectors";
import { Header } from "../../layout";
import { closeModal } from "../../redux";
import {
  SettingTitle,
  Container,
  SettingRow,
  SelectWrapper,
  IndicatorContainer,
  IndicatorWrapper,
  IndicatorDescription,
  AcceptButton,
} from "./styledComponents";

type Props = {
  name: string;
  onSubmit: () => void;
};

const MicrophoneSettingsModal = ({ name, onSubmit }: Props) => {
  const mediaDevices = useSelector(getUserAudioInputDevices);
  const [microphone, setMicrophone] = useState("none");
  const [audioContext, setAudioContext] = useState<any | null>(null);
  const [stream, setStream] = useState<null | MediaStream>(null);
  const [volume, setVolume] = useState<number>(0);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    offAudioStream();
    onSubmit();
    dispatch(closeModal(name));
  };

  useEffect(() => {
    checkDeviceSupport((devices, permissions) => {
      dispatch(setUserDevices({ devices, permissions }));
    });
  }, [dispatch]);

  const offAudioStream = () => {
    if (audioContext && stream) {
      audioContext.disconnect();
      stream!.getTracks().forEach((track) => track.stop());
    }
  };

  const handleSetVolume = callEveryAt(2, (average: any) => setVolume(average));

  const handleChangeMicrophone = (e: any) => {
    const microphoneId = e.target.value;
    offAudioStream();
    setMicrophone(microphoneId);
    const constraints = { audio: { deviceId: { exact: microphoneId } } };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        setStream(stream);
        setAudioContext(javascriptNode);
        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);
        javascriptNode.onaudioprocess = function () {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          var values = 0;

          var length = array.length;
          for (var i = 0; i < length; i++) {
            values += array[i];
          }

          var average = values / length;
          handleSetVolume(average);
        };
      })
      .catch((err) => console.log(err.name + ":" + err.message));
  };

  return (
    <Container>
      <Header title="Настройка микрофона" onClose={handleCloseModal} />
      <SettingRow>
        <SettingTitle>Выберите устройство</SettingTitle>
        <SelectWrapper
          value={microphone || mediaDevices[0]}
          onChange={handleChangeMicrophone}
        >
          {mediaDevices.map((device) => (
            //@ts-ignore
            <Option value={device.deviceId}>{device.label}</Option>
          ))}
          <Option value={"none"}>Нет</Option>
        </SelectWrapper>
      </SettingRow>
      <SettingRow>
        <SettingTitle>Проверка громкости</SettingTitle>
        <IndicatorContainer>
          <IndicatorWrapper percent={volume} indicatorCount={6} />
          <IndicatorDescription>
            Скажите что-нибудь в микрофон.
          </IndicatorDescription>
        </IndicatorContainer>
      </SettingRow>
      <AcceptButton
        onClick={handleCloseModal}
        buttonTheme="primary"
        size="medium"
      >
        Готово
      </AcceptButton>
    </Container>
  );
};

export default MicrophoneSettingsModal;
