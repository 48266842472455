import { getLecturePromo } from "pages";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { PreviewWebinar, Webinar } from "./layout";
import { ContentWrapper } from "./styledComponents";

const Webinars = () => {
  const lecturePromo = useSelector(getLecturePromo);
  const isComing = useMemo(
    () => lecturePromo?.webinar_started_at,
    [lecturePromo]
  );

  if (isComing) {
    return (
      <ContentWrapper>
        <Webinar />
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper>
      <PreviewWebinar />
    </ContentWrapper>
  );
};

export default Webinars;
