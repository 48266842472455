import { useState } from "react";
import { Calendar } from "@material-ui/pickers/index";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import styled from "styled-components";
import { Icon } from "components";
import Day from "./Day";
import { useSelector } from "react-redux";
import { getWebinarList } from "pages";

type Props = {
  className?: string;
};

const CalendarWrapper = styled.div`
  & .MuiPickersCalendar-transitionContainer {
    overflow: hidden;
    min-height: max-content;
  }

  & .MuiPickersCalendarHeader-transitionContainer p {
    font-size: ${(props) => props.theme.fontSizes.subtitle1FontSize};
    font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
    color: ${(props) => props.theme.colors.primaryBlack};
  }

  & .MuiPickersCalendarHeader-daysHeader {
    justify-content: space-between;
    span {
      font-size: ${(props) => props.theme.fontSizes.body2FontSize};
      color: ${(props) => props.theme.colors.secondaryGrey};
    }
  }

  & .MuiPickersCalendarHeader-dayLabel {
    width: 28px;
  }

  & .MuiPickersCalendar-week {
    justify-content: space-between;
    margin-bottom: 16px;
  }

  & .MuiPickersCalendar-week:nth-last-child {
    margin-bottom: 0;
  }

  & .MuiPickersCalendarHeader-iconButton {
    padding: 0;
  }

  & .MuiPickersCalendarHeader-switchHeader {
    margin-bottom: 24px;
    padding-left: 10px;
    padding-right: 10px;
  }

  & .MuiPickersSlideTransition-transitionContainer > * {
    position: static;
  }

  & .MuiPickersSlideTransition-slideExit {
    position: absolute;
  }
`;

const CalendarDatePicker = ({ className }: Props) => {
  const webinarList = useSelector(getWebinarList);
  const [date, setDate] = useState<string | null>(
    moment().format("YYYY-MM-DD")
  );

  const handleChangeDate = (newDate: MaterialUiPickersDate) => {
    const formattedNewDate = newDate?.format("YYYY-MM-DD");

    if (newDate?.day() === 6 || newDate?.day() === 0) {
      return;
    }

    if (formattedNewDate !== date) {
      setDate(formattedNewDate!);
    }
  };

  const getWebinarByDate = (day: moment.Moment) => {
    const dayToUTC = day.utc(true);

    for (let i = 0; i <= webinarList.length - 1; i++) {
      const webinar = webinarList[i];
      const webinaDateToUTC = moment(webinar.time_start * 1000).utc(true);

      if (webinaDateToUTC.isSame(dayToUTC, "day")) {
        return webinar;
      }
    }
    return null;
  };

  const renderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean
  ) => {
    const webinar = getWebinarByDate(day as moment.Moment);
    const today = moment().utcOffset(0).isSame(day, "day");

    if (webinar && moment().isAfter(day)) {
      return (
        <Day webinar={webinar} d={day} today={today} passed>
          {day?.date()}
        </Day>
      );
    }

    if (webinar && moment().isBefore(day)) {
      return (
        <Day webinar={webinar} d={day} today={today} planned>
          {day?.date()}
        </Day>
      );
    }

    return (
      <Day webinar={webinar} d={day}>
        {day?.date()}
      </Day>
    );
  };

  return (
    <CalendarWrapper className={className}>
      <Calendar
        onChange={handleChangeDate}
        date={moment(date)}
        leftArrowIcon={<Icon icon="CalendarLeftArrow" size="s" />}
        rightArrowIcon={<Icon icon="CalendarRightArrow" size="s" />}
        renderDay={renderDay}
      />
    </CalendarWrapper>
  );
};

export default CalendarDatePicker;
