import CalendarDatePicker from "components/CalendarDatePicker";
import Day from "components/CalendarDatePicker/Day";
import {
  CalendarDatePickerContainer,
  CalendarLegendContainer,
  Legend,
  SidebarContainerWrapper,
  Title,
} from "./styledComponents";

const SidebarContainer = () => {
  return (
    <SidebarContainerWrapper>
      <Title>Календарь</Title>
      <CalendarDatePickerContainer>
        <CalendarDatePicker />
      </CalendarDatePickerContainer>
      <CalendarLegendContainer>
        <Legend>
          <Day planned preview /> <span>Запланированные лекции</span>
        </Legend>
        <Legend>
          <Day passed preview /> <span>Пройденные лекции</span>
        </Legend>
      </CalendarLegendContainer>
    </SidebarContainerWrapper>
  );
};

export default SidebarContainer;
