import { useEffect } from "react";
import SLDP from "libs/SLDP";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const SLDPPlayer = () => {
  useEffect(() => {
    let sldpPlayer: any = null;
    function initPlayer() {
      sldpPlayer = SLDP.init({
        container: "SLDPPlayer",
        stream_url: `wss://stream.${process.env.REACT_APP_DOMAIN}:30443/svetofor/test`,
        initial_resolution: "240p",
        buffering: 500,
        autoplay: true,
        height: "parent",
        width: "parent",
      });
    }

    initPlayer();

    return () => {
      sldpPlayer.destroy(function () {
        console.log("SLDP Player is destroyed.");
      });
    };
  }, []);
  return <Container id="SLDPPlayer" />;
};

export default SLDPPlayer;
