import { RootState } from "store";

export const getLecturePromo = (state: RootState) => state.cabinet.lecturePromo;
export const getLecturePromoLoad = (state: RootState) =>
  state.cabinet.loadLecturePromo;
export const getWebinarList = (state: RootState) => state.cabinet.webinarsList;
export const getLecturePromoUsers = (state: RootState) =>
  state.cabinet.lecturePromoUsers;
export const getLoadLecturePromoUsers = (state: RootState) =>
  state.cabinet.loadLecturePromoUsers;
