export const Wallet = () => (
  <>
    <path d="M19 12C19 12.5523 18.5523 13 18 13C17.4477 13 17 12.5523 17 12C17 11.4477 17.4477 11 18 11C18.5523 11 19 11.4477 19 12Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 16V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V7C2 5.34315 3.34315 4 5 4H19C20.6569 4 22 5.34315 22 7V8H23C23.5523 8 24 8.44772 24 9V15C24 15.5523 23.5523 16 23 16H22ZM5 5.5H19C19.8284 5.5 20.5 6.17157 20.5 7V8H18C15.7909 8 14 9.79086 14 12C14 14.2091 15.7909 16 18 16H20.5V17C20.5 17.8284 19.8284 18.5 19 18.5H5C4.17157 18.5 3.5 17.8284 3.5 17V7C3.5 6.17157 4.17157 5.5 5 5.5ZM22 9.5H22.5V14.5H22H20.5H18C16.6193 14.5 15.5 13.3807 15.5 12C15.5 10.6193 16.6193 9.5 18 9.5H20.5H22Z"
    />
  </>
);
