import { ProgressCircle } from "components";
import { Button, Select } from "components";
import styled from "styled-components";

export const Progress = styled(ProgressCircle)`
  width: 40px;
  height: 40px;
  display: block;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSelect = styled(Select)`
  margin-bottom: 16px;
  align-self: flex-end;
  width: 190px;
`;

export const DateBlock = styled.div`
  font-size: 14px;
`;

export const MainText = styled.div`
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const StyledButton = styled(Button)`
  height: 32px;
  margin: 0 auto;
`;
