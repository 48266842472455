import { useDispatch } from "react-redux";
import { closeModal } from "../../redux";
import {
  Container,
  Wrapper,
  Subtitle,
  StyledButton,
  Error,
  ErrorContainer,
  InputContainer,
} from "./styledComponents";
import { useCallback, useState } from "react";
import { Header } from "../../layout";
import { Input } from "../../../../components";
import { useFormik } from "formik";
import { object, string } from "yup";
import {LoginForm} from "../../../../pages/Login/LoginForm";

const validationSchema = object({
  email: string().required("Необходимо заполнить «E-mail»"),
});

export const LoginModal = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<null | string>("");

  const handleClose = useCallback(() => {
    dispatch(closeModal("LoginModal"));
  }, [dispatch]);

  const handleLoginError = () => {
    setError("Неверный e-mail");
  };

  const form = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleLoginError();
    },
  });

  return (
    <Wrapper>
      <Container>
        <Header
          font={"heading6FontSize"}
          title={'Вход в систему\nдистанционного обучения'}
          onClose={handleClose}
        />
        {/*<Subtitle>*/}
        {/*  Введите вашу почту, на которую мы отправим инструкцию по*/}
        {/*  восстановлению пароля.*/}
        {/*</Subtitle>*/}
        <LoginForm  handleForgot={() => ''}/>
      </Container>
    </Wrapper>
  );
};
