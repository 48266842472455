import { Icon } from "components";
import styled from "styled-components";
import { fontSizes } from "../../../../constants/style";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
`;

export const Title = styled.div<{ fontSize?: keyof typeof fontSizes }>`
  font-size: ${(props) =>
    props.fontSize
      ? props.theme.fontSizes[props.fontSize]
      : props.theme.fontSizes.heading3FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-family: ${(props) => props.theme.fonts.mainBoldFont};
`;

export const CornerZone = styled.div``;

export const CloseIcon = styled(Icon)`
  cursor: pointer;
`;
