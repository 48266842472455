import { Immutable } from "immer";
import type { QuestionsResponse } from "api/models/ticket";
import type { UserAnswer } from "pages";
import { Container, WrapperInfo, Text, WrapperList } from "./styledComponents";
import Timer from "./components/Timer";
import QuestionItem from "./components/QuestionItem";

type QuestionsProps = {
  started: number;
  list: QuestionsResponse;
  userAnswers: Immutable<UserAnswer[]>;
  index: number;
  duration?: number;
};

const Questions = ({
  started,
  list,
  userAnswers,
  duration,
}: QuestionsProps) => {
  return (
    <Container>
      <WrapperInfo>
        <Text>Вопросы</Text>
        {started && duration ? (
          <Timer duration={duration} started={started} />
        ) : null}
      </WrapperInfo>
      <WrapperList>
        {list.map((item, index) => (
          <QuestionItem
            key={item.id}
            item={item}
            userAnswers={userAnswers}
            index={index}
          />
        ))}
      </WrapperList>
    </Container>
  );
};

export default Questions;
