import { EXAM } from "constants/paths";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getTickets, startWorkout, WorkoutId } from "./redux";
import {
  WorkoutListWrapper,
  WorkoutItemBlock,
  WorkoutName,
  WorkoutCount,
} from "./styledComponents";
import { useCallback } from "react";
import { TicketsResponseItem } from "../../api/models/ticket";
import { getRightEnding } from "utils/formatDuration";

type WorkoutItemType = {
  name: string;
  count: number;
  item: TicketsResponseItem;
};

const Workout = () => {
  const tickets = useSelector(getTickets);
  return (
    <WorkoutListWrapper>
      {tickets.map((item, index) => (
        <WorkoutItem
          name={`Билет №${item.number}`}
          item={item}
          count={0}
          key={index}
        />
      ))}
    </WorkoutListWrapper>
  );
};

const WorkoutItem = ({ name, item: { count, number } }: WorkoutItemType) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleStartWorkout = useCallback((id: number) => {
    const workoutId: WorkoutId = `ticket_${id}_id`;
    dispatch(startWorkout({ id: workoutId }));
    history.push(`${EXAM}?type=workout&ticket=${id}`);
  }, []);

  return (
    <WorkoutItemBlock onClick={() => handleStartWorkout(number)}>
      <WorkoutName>{name}</WorkoutName>
      {count ? (
        <WorkoutCount>Пройдено: {getRightEnding(count)}</WorkoutCount>
      ) : null}
    </WorkoutItemBlock>
  );
};

export default Workout;
