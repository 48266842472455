import { PaperContainer } from "containers";
import styled from "styled-components";

export const FooterContainer = styled(PaperContainer)`
  margin: 64px 24px 40px 24px;
  padding: 2px 0 16px 0;
  display: flex;
  flex-flow: column nowrap;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  color: ${(props) => props.theme.colors.primaryBlack};
  padding-top: 32px;
  padding-bottom: 13px;
`;

export const Subtitle = styled.div`
  font-size: ${(props) => props.theme.fontSizes.body2FontSize};
  font-weight: ${(props) => props.theme.fontWeights.superSkinnyFontWeight};
  color: ${(props) => props.theme.colors.secondaryGrey};
  margin-top: 8px;
`;

export const Header = styled.div`
  padding: 0 20px 0 24px;
  position: relative;
`;

export const ImageMokap = styled.img`
  width: 87.61px;
  height: 166px;
`;

export const Image = styled.img`
  position: absolute;
  right: 7px;
  top: 0;
  width: 52px;
`;

export const ContentBlock = styled.div`
  padding: 0 20px 0 24px;
  display: flex;
  flex-flow: row nowrap;
`;

export const LeftImageBlock = styled.div`
  flex: 1;
  /* width: 61px; */
`;

export const RightTextBlock = styled.div`
  flex: 1;
`;

export const TitleRightBlock = styled.div`
  color: #4ac365;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17.18px;
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 18.32px;
`;

export const PRightBlock = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #62686b;
  line-height: 15.32px;
`;
export const ButtonContainer = styled.div`
  padding: 0 20px 0 24px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  position: relative;
`;

interface PQRCodeContainer {
  readonly isActive: boolean;
  readonly currentButton: string;
}

export const QRCodeContainer = styled.div<PQRCodeContainer>`
  display: ${(props) => (props.isActive ? "block" : "none")};
  position: absolute;
  bottom: 0;
  left: ${(props) => {
    if (props.currentButton === "playMarket") return "105px";
    else return "-25px";
  }};
  width: 143px;
  height: 143px;
  background: red;
`;

export const QRCode = styled.img`
  max-width: 100%;
`;
