import { LabelContainer, PaperContainer } from "containers";
import styled from "styled-components";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";

const DayPopUpContainer = styled(PaperContainer)`
  padding: 24px 24px 32px;
  width: 324px;
  border-color: ${(props) => props.theme.colors.opacityGreen10};
  margin: 10px 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Body = styled.div`
  margin-top: 24px;
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  font-weight: ${(props) => props.theme.fontWeights.skinnyFontWeight};
`;

const Info = styled.div`
  padding-top: 8px;
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};

  & span {
    color: ${(props) => props.theme.colors.primaryGreen};
    font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  }
`;

const Label = styled(LabelContainer)`
  height: 22px;
  line-height: 20px;
  padding: 0 10px;
  font-size: ${(props) => props.theme.fontSizes.captionFontSize};
  color: ${(props) => props.theme.colors.primaryGreen};
  background-color: ${(props) => props.theme.colors.opacityGreen10};
  border: 1px solid ${(props) => props.theme.colors.primaryGreen};
  box-sizing: border-box;
  border-radius: 18px;
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

const Time = styled.div`
  margin-top: 8px;
`;

const DayPopUp = ({
  webinar,
}: {
  d?: MaterialUiPickersDate;
  webinar?: any;
}) => {
  return webinar ? (
    <DayPopUpContainer>
      <Header>
        <Info>
          <div>
            Дата:{" "}
            <span>{moment(webinar.time_start * 1000).format("DD.MM.YY")}</span>
          </div>
          <Time>
            Время:{" "}
            <span>
              {moment(webinar.time_start * 1000).format("HH:mm")} (по МСК)
            </span>
          </Time>
        </Info>
        <Label>{webinar.webinar_status.name}</Label>
      </Header>
      <Body>{webinar.topic.name}</Body>
    </DayPopUpContainer>
  ) : null;
};

export default DayPopUp;
