import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { getUserId } from "../selectors/userInfo";
import {
  onOnlineUsers,
  emitOnline,
  emitOffline,
  removeListenerOnlineUser,
  pingUnsubscribe,
  pingSubscribe,
} from "../api/OnlineUsersApi";
import { useLocation } from "react-router-dom";
import { getWebinarId } from "../pages/Webinars/redux";

type Context = {
  checkOnlineStatus: () => void;
  removeWindowFocusHandler: () => void;
  addWindowFocusHandler: () => void;
  onlineUsers?: Record<string, string>;
};
export const PingOnlineStatusContext = createContext<Context>({} as Context);

export const PingOnlineStatusProvider = ({
  children,
}: PropsWithChildren<any>) => {
  const [onlineUsers, setOnlineUsers] = useState<Record<string, string>>();
  const [isOnline, setIsOnline] = useState<boolean>();
  const [isFocused, setIsFocused] = useState<boolean>(true);
  const location = useLocation();
  const userId = useSelector(getUserId);
  const webinarId = useSelector(getWebinarId);

  useEffect(() => {
    onOnlineUsers(setOnlineUsers);
    addWindowFocusHandler();
    return () => {
      removeListenerOnlineUser();
      removeWindowFocusHandler();
    };
  }, []);

  const checkOnlineStatus = useCallback(() => {
    if (userId && webinarId && isOnline) {
      emitOnline(`${userId}`, `${webinarId}`).then((newOnlineUsers) => {
        setOnlineUsers(newOnlineUsers);
      });
    }
    if (userId && webinarId && !isOnline) {
      emitOffline(`${userId}`, `${webinarId}`).then((newOnlineUsers) => {
        setOnlineUsers(newOnlineUsers);
      });
    }
  }, [userId, webinarId, isOnline]);

  useEffect(() => {
    checkOnlineStatus();
    pingSubscribe(checkOnlineStatus);
    return () => pingUnsubscribe(checkOnlineStatus);
  }, [checkOnlineStatus]);

  useEffect(() => {
    if (location.pathname === "/online-lecture" && isFocused) {
      setIsOnline(true);
    } else if (location.pathname === "/webinars") {
      setIsOnline(true);
    } else {
      setIsOnline(false);
    }
  }, [location.pathname, isFocused]);

  const onFocusWindow = () => {
    setIsFocused(true);
    pingSubscribe(checkOnlineStatus);
  };

  const onBlurWindow = () => {
    setIsFocused(false);
    pingUnsubscribe(checkOnlineStatus);
  };

  const addWindowFocusHandler = () => {
    window.addEventListener("focus", onFocusWindow);
    window.addEventListener("blur", onBlurWindow);
  };

  const removeWindowFocusHandler = () => {
    window.removeEventListener("focus", onFocusWindow);
    window.removeEventListener("blur", onBlurWindow);
  };

  return (
    <PingOnlineStatusContext.Provider
      value={{
        checkOnlineStatus,
        addWindowFocusHandler,
        removeWindowFocusHandler,
        onlineUsers,
      }}
    >
      {children}
    </PingOnlineStatusContext.Provider>
  );
};
