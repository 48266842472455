import styled from "styled-components";

const ContainerTest = styled.div`
    max-width: calc(100vw - 20px);
    margin-bottom: 140px;
    width: 100%;
    margin-inline: auto;
    @media (max-width: 768px) {
      margin-bottom: 30px !important;
    }
  `;
export default function VideoFeedbackComponent ({ExtraTitle,primary, Text}) {
    const HorizontalWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 16px;
    >iframe {
    width: 100%;

    height: 529px;
    }
    @media (max-width: 768px){
flex-direction: column;
}
  `;
  const Container_ = styled(ContainerTest)`

margin-bottom: 70px;

`;
const Title = styled(ExtraTitle)`
margin-bottom: 20px;
color: ${primary};
@media (max-width: 768px){
margin-top: 20px;
}
`;
const Text_ = styled.p`
color: ${primary};
max-width: 839px;
margin-top: 0;
margin-bottom: 40px;
font-size: 18px;
line-height: 28px;
@media (max-width: 768px){

}
`;
    return (
        <>
        <Container_>
            <Title>Лучшая гарантия - честные отзывы</Title>
            <Text_>Наша задача подготовить учеников автошколы к бескомпромиссной сдаче экзамена с первого раза, успешному получению прав и первоклассному управлению автомобилем.</Text_>
            <HorizontalWrapper>
            <iframe src="https://kinescope.io/embed/vKyAkhv8xwPhn5ucDbapr2" allow="autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;" frameborder="0" allowfullscreen="" />
            <iframe src="https://kinescope.io/embed/iZozgzgRpTEzKrw85tJRBa" allow="autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;" frameborder="0" allowfullscreen="" />
            <iframe src="https://kinescope.io/embed/8oQnrgFzbry8PopT96S1YF" allow="autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;" frameborder="0" allowfullscreen="" />
            </HorizontalWrapper>
            </Container_>
        </>
    )
}
