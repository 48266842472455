import { Actions, Container, Name, StyledIcon } from "./styledComponents";

type Props = {
  name: string;
  className?: string;
};

const DocumentListItem = ({ name, className }: Props) => {
  return (
    <Container className={className}>
      <Name>{name}</Name>
      <Actions>
        Скачать <StyledIcon icon="DownloadIcon" />
      </Actions>
    </Container>
  );
};

export default DocumentListItem;
