import { Accordion } from "components";
import { CustomScrollContainer, DefaultModalContainer } from "containers";
import styled from "styled-components";

export const Container = styled(DefaultModalContainer)`
  padding: 16px;
  height: 566px;
  overflow: scroll;
`;

export const Body = styled(CustomScrollContainer)`
  max-height: 470px;
  margin-top: 24px;
`;

export const StyledAccordion = styled(Accordion)`
  margin-top: 14px;
`;

export const Task = styled.div<{ selected: boolean }>`
  margin: 4px 0;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.opacityGreen20 : null} !important;

  &:hover {
    text-decoration: underline;
  }
`;
