import { DefaultTheme } from "styled-components";
import Colors from "./color";
import { fontSizes, fontWeights, fonts } from "./style";

export interface ITheme {
  colors: typeof Colors;
  fontSizes: typeof fontSizes;
  fontWeights: typeof fontWeights;
  fonts: typeof fonts;
}

const Theme: DefaultTheme = {
  colors: Colors,
  fontSizes,
  fontWeights,
  fonts,
};

export default Theme;
