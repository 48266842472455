import { getIsOpen } from "layout/AppBar/redux";
import moment from "moment";
import { getLecturePromo, getLecturePromoLoad, getWebinarList } from "pages";
import { LecturePromoType } from "pages/Cabinet/redux/reducer";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Caption,
  PromoBannerContainer,
  StyledButton,
  Subtitle,
  Title,
  WrapLoader,
} from "./styledComponents";
import { Loader } from "components";
import { defaultNotification, formatTimer } from "./utils";

const PromoBanner = () => {
  const appBarIsOpen = useSelector(getIsOpen);
  const webinarList = useSelector(getWebinarList);
  const isLoad = useSelector(getLecturePromoLoad);

  const lecturePromo = useSelector(getLecturePromo);
  const isWebinarShouldStart =
    lecturePromo &&
    lecturePromo.webinar_started_at &&
    !lecturePromo.webinar_finished_at;

  const timeNow = moment().unix();
  const nextLesson = webinarList.filter(
    (item) => item.time_start >= timeNow
  )[0];

  return (
    <PromoBannerContainer appBarIsOpen={appBarIsOpen}>
      {isLoad ? (
        <WrapLoader>
          <Loader />
        </WrapLoader>
      ) : !nextLesson ? (
        <Title>В ближайшее время лекций нет</Title>
      ) : (
        <PromoBannerMain
          lecturePromo={isWebinarShouldStart ? lecturePromo : nextLesson}
        />
      )}
    </PromoBannerContainer>
  );
};

export default PromoBanner;

const PromoBannerMain = ({
  lecturePromo,
}: {
  lecturePromo: LecturePromoType;
}) => {
  const history = useHistory();
  const [time, setTime] = useState((lecturePromo.time_start || 0) * 1000);

  useEffect(() => {
    const timer = () => {
      setTime((prev) => prev - 30000);
    };

    const intervalId = setInterval(timer, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleOnClick = () => {
    history.push("/online-lecture");
  };

  if (lecturePromo.webinar_started_at) {
    return (
      <>
        <Title>
          Лекция “{lecturePromo.topic.name}”
          <br /> уже идет
        </Title>
        <Caption>
          {lecturePromo.notification_before || defaultNotification}
        </Caption>
        <StyledButton buttonTheme="warning" withBorder onClick={handleOnClick}>
          Принять участие
        </StyledButton>
      </>
    );
  }

  const isDate = time - +new Date();
  return (
    <>
      {isDate > 0 ? (
        <Title>
          Лекция “{lecturePromo.topic.name}”
          <br /> начнется {moment(time).format("D MMMM")}
        </Title>
      ) : (
        <Title>В ближайшее время лекций нет</Title>
      )}
      {isDate > 0 ? (
        <>
          <Subtitle>{formatTimer(time)}</Subtitle>
          <Caption>
            {lecturePromo.notification_before || defaultNotification}
          </Caption>
        </>
      ) : null}
    </>
  );
};
