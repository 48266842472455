export const defaultNotification =
  "Вы сможете пообщаться с преподавателем в чате!";

export const formatTimer = (achiveDate: number) => {
  const nowDate = +new Date();

  let result = achiveDate - nowDate + 1000;

  let minutes = Math.floor((result / 1000 / 60) % 60);
  let hours = Math.floor((result / 1000 / 60 / 60) % 24);
  let days = Math.floor(result / 1000 / 60 / 60 / 24);

  return (
    "осталось " +
    days +
    ` ${formatDay(days)} ` +
    hours +
    " ч " +
    minutes +
    " мин"
  );
};

export const formatDay = (num: number) => {
  if (num === 0) {
    return "дней";
  }

  if (num === 1) {
    return "день";
  }

  if (num < 5) {
    return "дня";
  }

  return "дней";
};
