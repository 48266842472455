export const KEY = "authJwt";

const getToken = (): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${KEY}=`);
  if (parts.length === 2) {
    const p = parts.pop();
    if (p) {
      return p.split(";").shift() as string;
    }
    return null;
  }
  return null;
};

export default getToken;
