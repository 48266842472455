import styled from "styled-components";

export const HeaderListContainer = styled.div`
  margin-top: 8px;
  align-self: flex-start;
  user-select: none;
  width: 100%;
  cursor: move;
  padding: 0 24px 8px;
  box-sizing: border-box;
`;
