import { DefaultModalContainer } from "containers";
import styled from "styled-components";

export const Container = styled(DefaultModalContainer)`
  padding: 0 16px;
  height: 130px;
  overflow: none;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;
export const Title = styled.div`
  font-size: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px;
`;

export const ButtonWrapper = styled.div`
  padding: 0 5px 0 5px;
`;
