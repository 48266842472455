import styled from "styled-components";

type Props = {
  percent: number;
  indicatorCount?: number;
  className?: string;
};

const IndicatorContainer = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.colors.opacityGrey5};
  height: 10px;
  width: 100%;
  border-radius: 22px;
`;

const indicatorItem = styled.div<{ width: number; active: boolean }>`
  background-color: ${(props) =>
    props.active ? props.theme.colors.primaryGreen : "none"};
  height: 10px;
  width: ${(props) => `${props.width}%`};
  margin: 0 4px 0 4px;

  &:first-child {
    border-radius: 22px 0 0 22px;
    margin-left: 0;
  }
  &:last-child {
    border-radius: 0 22px 22px 0;
    margin-left: 0;
  }
`;

const Indicator = ({ percent, indicatorCount = 6, className }: Props) => {
  const indicatorCost = 100 / indicatorCount;
  const activeIndicatorsCount = percent / indicatorCost;

  const renderIndicator = new Array(indicatorCount).fill(indicatorItem);

  return (
    <IndicatorContainer className={className}>
      {renderIndicator.map((Item, index) => (
        <Item
          width={indicatorCost}
          active={index + 1 <= activeIndicatorsCount}
        />
      ))}
    </IndicatorContainer>
  );
};

export default Indicator;
