import { Icon } from "components";
import { PaperContainer } from "containers";
import styled from "styled-components";

export const Container = styled(PaperContainer)`
  height: 456px;
  width: 360px;
  margin-top: 16px;
`;

export const Header = styled.div`
  border-radius: 16px 16px 0 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.opacityGrey5};
  padding: 14px 16px 8px;
  box-sizing: border-box;
`;

export const HeaderTitle = styled.div`
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  display: flex;
  align-items: center;
  height: 37px;
`;

export const LeftArrow = styled(Icon)`
  margin-right: 8px;
  cursor: pointer;
`;

export const Body = styled.div`
  overflow: auto;
  height: calc(100% - 89px);
`;
