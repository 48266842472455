import { PropsWithChildren, ReactNode } from "react";
import MUIInputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import styled from "styled-components";

type Props = {
  leftZone?: ReactNode;
};

const InputContainer = styled.div`
  height: 44px;
  background-color: ${(props) => props.theme.colors.opacityLightGrey20};
  border-radius: 10px;
  display: flex;

  & .MuiInputBase-root {
    height: 44px;
    width: 100%;
    padding-left: 16px;
  }
`;

const Input = ({
  leftZone,
  children,
  className,
  ...props
}: PropsWithChildren<Props & InputBaseProps>) => {
  return (
    <InputContainer className={className}>
      <MUIInputBase {...props} />
      {leftZone}
    </InputContainer>
  );
};

export default Input;
