import { ContentContainer } from "containers";
import { PageTitle } from "shared";
import styled from "styled-components";
import { DocumentsList, NotificationList } from "./components";

const Container = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const Subtitle = styled.div`
  margin-top: 40px;
  font-family: ${(props) => props.theme.fonts.mainBoldFont};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.heading5FontSize};
`;

const Documents = () => {
  return (
    <ContentContainer>
      <Container>
        <PageTitle>Документы</PageTitle>
        <DocumentsList />
        <Subtitle>Уведомления</Subtitle>
        <NotificationList />
      </Container>
    </ContentContainer>
  );
};

export default Documents;
