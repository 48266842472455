import { Header } from "layout/ModalWindow/layout";
import { closeModal, openModal } from "layout/ModalWindow/redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { PaymentsInfoItem } from "./components";
import {
  AmountInput,
  Amount,
  AmountTitle,
  PaymentsInfo,
  StyledButton,
  Container,
  StyledServices,
} from "./styledComponents";
import styled from "styled-components";
import { payBillingItem } from "../../../../api/CabinetPromoApi";

type Props = {
  name: string;
  service?: string;
  item: any;
};

const PaymentsModal = ({ name, service, item }: Props) => {
  const [selectValue, setSelectValue] = useState(item.name);
  const [amount, setAmount] = useState("");

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal(name));
  };

  const handleOnChangeSelect = (e: any) => {
    setSelectValue(e.target.value);
  };

  const handleOnChangeAmountInput = (e: any) => {
    setAmount(e.target.value);
  };

  const payment = async () => {
    try {
      const resp = await payBillingItem(item.id, amount);
      window.location.href = resp.data.link;
    } catch (e) {
      dispatch(openModal({ name: "PaymentsErrorModal" }));
    }
  };

  return (
    <Container>
      <Header onClose={handleCloseModal} title="Оплатить услугу" />
      <Desc>
        Услугу можно оплатить частями, срок зачисления платежа — 10-30 минут.
      </Desc>
      <StyledServices
        onChange={handleOnChangeSelect}
        selectValue={selectValue}
        service={item.name}
      />
      <PaymentsInfo>
        <PaymentsInfoItem title="Стоимость" value={item.price} />
        <PaymentsInfoItem title="Оплачено" value={item.payed_sum} />
        <PaymentsInfoItem
          title="Задолженность"
          color="rgba(252, 94, 94, 1)"
          value={item.price - item.payed_sum}
        />
      </PaymentsInfo>
      <Amount>
        <AmountTitle>Сумма платежа</AmountTitle>
        <AmountInput
          placeholder="Введите сумму"
          value={amount}
          onChange={handleOnChangeAmountInput}
        />
      </Amount>
      <Controls>
        <StyledButton buttonTheme="secondary" onClick={handleCloseModal}>
          Отменить
        </StyledButton>
        <StyledButton onClick={payment}>Оплатить картой</StyledButton>
      </Controls>
    </Container>
  );
};

export default PaymentsModal;

const Desc = styled.div`
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
`;

const Controls = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: end;
  gap: 20px;
`;
