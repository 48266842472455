import { TChatMessages } from "api/models/chat";
import { fetchWebinarChatMessages } from "api/TeacherWebinarChatAPI";
import { howMuchTimeSpent } from "helpers/howMuchTimeSpent";
import { useChat } from "helpers/useChat";
import Message from "../WebinarTabContainer/components";
import { Container } from "./styledComponents";
import { getOnlineLectureWidgetChatMessages } from "layout/OnlineWebinarLectureWidget/redux";
import { openModal } from "../../../../layout";
import { useDispatch } from "react-redux";

const ChatTabPanel = () => {
  const dispatch = useDispatch();
  const { chatContainerRef, chatMessages } = useChat<TChatMessages>({
    fetchChatMessage: fetchWebinarChatMessages,
    chatMessageSelector: getOnlineLectureWidgetChatMessages,
  });

  const handleMute = (
    userId: number,
    username: string,
    fullName: string,
    messageId: number
  ) => {
    dispatch(
      openModal({
        name: "UserMuteConfirmModal",
        userId: userId,
        username: username,
        fullName: fullName,
        messageId: messageId,
      })
    );
  };

  return (
    <Container ref={chatContainerRef}>
      {chatMessages?.map((message) => (
        <Message
          key={message.id}
          author={`${message.firstname ? message.firstname + " " : ""}${
            message.lastname ? message.lastname : ""
          }`}
          mute={() =>
            handleMute(
              message.id,
              message.username,
              `${message.firstname} ${message.lastname}`,
              message.id
            )
          }
          message={message.message}
          time={howMuchTimeSpent(message.created_at)}
          isYour={false}
        />
      ))}
    </Container>
  );
};

export default ChatTabPanel;
