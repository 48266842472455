import styled from "styled-components"
// import logo from './assets/images/logo.svg'
import vk from './assets/images/VK_Logo_1.svg'
import whatsapp from './assets/images/whatsapp-icon-logo-s.svg'
import telegram from './assets/images/Frame.svg'
import empty from './assets/images/empty.png'

export default function SocialsComponent ({Title,primary,link,green, logo, data}) {
const Container = styled.div`
background: #f5f5f5;
border-radius: 24px;
padding: 60px 40px 40px 70px;
display: flex;
gap: 50px;
margin-bottom: 70px;
justify-content: space-between;
@media (max-width: 768px){
max-width: calc(100vw - 20px);
margin: 0 auto;
gap: 30px;
margin-bottom: 30px;
padding: 30px;
flex-direction: column-reverse;
}
`;
const LogoImage = styled.div`
    background: url(${logo !== '' ? logo : empty});
    position: relative;
    width: 271px;
    min-width: 271px;
    height: 269px;
    background-size: contain;
    background-repeat: no-repeat;

    
    @media (max-width: 768px){ 
    width: 171px;
    min-width: 171px;
    height: 153px;
    }
`
const SupportContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 10px;
`
const SupportText = styled.h6`
font-size: 20px;
line-height: 26px;
color: ${primary};
font-weight: 600;
@media (max-width: 768px){
font-size: 14px;
line-height: 20px;
}
`;
const SupportLink = styled.a`
color: ${link};
font-size: 36px;
line-height: 41px;
font-weight: 600;
transition: .2s;

&:hover {
color: ${green};
-moz-box-shadow: inset 0 -1px 0 0 #00ab6d;
    -moz-box-shadow: inset 0 -1px 0 0 #00ab6d;
-webkit-box-shadow: inset 0 -1px 0 0 #00ab6d;
}
@media (max-width: 768px) {
font-size: 28px;

}
`

const Text = styled.p`
font-size: 18px;
font-weight: 400;
line-height: 28px;
color: ${primary}
`
const HorizontalWrapper = styled.div`
display: flex;
gap: 20px;
@media (max-width: 768px){flex-wrap: wrap;}

`;
const IconImage = styled.div`
background: url(${props=>props.src});
background-size: cover;
width: 70px;
height: 70px;
@media (max-width: 768px){
width: 60px;
height: 60px;

}

`;
const ContentWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
max-width: 680px;

`

    return (
        <>
        <Container id="consult">
<ContentWrapper>
            <Title>
            Поможем решить все вопросы
            </Title>
            <Text>Если вы хотите больше узнать о ПДД.ТВ или не знаете, какую программу обучения выбрать, напишите — и мы ответим на все вопросы
            </Text>
            <HorizontalWrapper>
            <IconImage  as='a' href={`${data.partner_whatsapp}?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82%20%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%BF%D0%BB%D0%B0%D1%82%D1%84%D0%BE%D1%80%D0%BC%D0%B5%20%D0%9F%D0%94%D0%94%2C%D0%A2%D0%92`} target="_blank" src={whatsapp}></IconImage>

<IconImage as='a' href={data.partner_vkontakte} target="_blank" src={vk}></IconImage>
<IconImage  as='a' href={data.partner_telegram} target="_blank" src={telegram}></IconImage>
                {data.partner_phone_school !== '' &&
                    <SupportContainer>
                        <SupportText>Служба поддержки автошколы
                        </SupportText>
                        <SupportLink as='a' href={`tel:+${data.partner_phone_school.replace(/[^\d]/g, '')}`}>{data.partner_phone_school}</SupportLink>
                    </SupportContainer>
                }
            </HorizontalWrapper>

            </ContentWrapper>
            <LogoImage/>
        </Container>
        </>
    )
}
