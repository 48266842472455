import NotificationListItem from "../NotificationListItem";

const NotificationList = () => {
  return (
    <>
      <NotificationListItem
        name="Задолженность 3 500 руб!"
        description="Задолженность по образовательной услуге по договору №1483 Категории ”B”"
      />
    </>
  );
};

export default NotificationList;
