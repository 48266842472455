import {
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Loader,
} from "components";
import {
  Name,
  Numbers,
  StyledButton,
  Container,
  NumbersBold,
} from "./styledComponents";
import { AllPayments } from "../Payments/styledComponents";

type Props = {
  onPay: (item: any) => void;
  load: boolean;
  data: any[];
};

const PaymentsTable = ({ onPay, data, load }: Props) => {
  return (
    <Container>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Дата оформления</TableCell>
            <TableCell align="left" scope="row">
              Наименование
            </TableCell>
            <TableCell>Стоимость</TableCell>
            <TableCell align="center">Оплачено</TableCell>
            <TableCell align="center">Задолженность</TableCell>
            <TableCell align="center">Оплата</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell align="left">{item.date}</TableCell>
              <TableCell align="left">
                <Name>{item.name}</Name>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Numbers>{item.price.toLocaleString("ru")} ₽</Numbers>
              </TableCell>
              <TableCell align="center" valign="middle">
                <Numbers>{item.payed_sum.toLocaleString("ru")} ₽</Numbers>
              </TableCell>
              <TableCell align="center" valign="middle">
                <NumbersBold>
                  {item.price - item.payed_sum === 0
                    ? ""
                    : (item.price - item.payed_sum).toLocaleString("ru")}
                  {"   ₽"}
                </NumbersBold>
              </TableCell>
              <TableCell align="center" valign="middle">
                {item.price - item.payed_sum === 0 ? (
                  <Name>Оплачено</Name>
                ) : (
                  <StyledButton size="small" onClick={() => onPay(item)}>
                    Оплатить
                  </StyledButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {load && <Loader style={{ marginTop: 10, marginBottom: 10 }} />}
      {!load && data.length === 0 && <AllPayments>Нет данных</AllPayments>}
    </Container>
  );
};

export default PaymentsTable;
