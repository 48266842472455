import React, { useEffect, useRef, useState } from "react";

import WaveSurfer from "wavesurfer.js";

const formWaveSurferOptions = (ref: HTMLDivElement) => ({
  container: ref,
  waveColor: "#eee",
  progressColor: "rgb(48,188,95)",
  cursorColor: "rgba(48,188,95, 0.1)",
  barWidth: 2,
  barRadius: 3,
  responsive: true,
  height: 40,
  normalize: true,
  partialRender: true,
});

type Props = {
  url: string;
  playAudio: boolean;
  onEnd: () => void;
};

export default function Waveform({ url, playAudio, onEnd }: Props) {
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const [volume, setVolume] = useState(1);

  useEffect(() => {
    if (waveformRef.current) {
      const options = formWaveSurferOptions(waveformRef.current);
      wavesurfer.current = WaveSurfer.create(options);
      wavesurfer.current.load(url);
      wavesurfer.current.on("ready", function () {
        if (wavesurfer.current) {
          wavesurfer.current.setVolume(1);
          setVolume(volume);
        }
      });
      wavesurfer.current.on("finish", function () {
        onEnd();
      });
    }

    return () => wavesurfer?.current?.destroy();
  }, [url]);

  useEffect(() => {
    if (playAudio) {
      wavesurfer?.current?.play();
    } else {
      wavesurfer?.current?.pause();
    }
  }, [playAudio]);

  return (
    <div>
      <div id="waveform" ref={waveformRef} />
    </div>
  );
}
