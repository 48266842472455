import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading5FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const AllServices = styled.div`
  color: ${(props) => props.theme.colors.primaryGreen};
  font-size: ${(props) => props.theme.fontSizes.body1FontSize};
  font-weight: ${(props) => props.theme.fontWeights.skinnyFontWeight};
  margin-top: 24px;
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
