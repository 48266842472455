const CalendarLeftArrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.93339 3.60428C10.2795 3.94574 10.2795 4.50426 9.93339 4.84572L6.74751 7.98912C6.60206 8.13263 6.60206 8.36737 6.74751 8.51088L9.93339 11.6543C10.2795 11.9957 10.2795 12.5543 9.93339 12.8957C9.59383 13.2308 9.04807 13.2308 8.7085 12.8957L4.62136 8.86307C4.27955 8.52582 4.27955 7.97418 4.62136 7.63693L8.7085 3.60428C9.04807 3.26924 9.59383 3.26924 9.93339 3.60428Z"
        fill="#4AC365"
      />
    </svg>
  );
};

export default CalendarLeftArrow;
