import Day from "components/CalendarDatePicker/Day";
import {
  Calendar,
  CalendarLegendContainer,
  Container,
  Legend,
  Title,
} from "./styledComponents";

const MobileCalendar = () => {
  return (
    <Container>
      <CalendarLegendContainer>
        <Title>Календарь</Title>
        <Legend>
          <Day planned preview /> <span>Запланированные лекции</span>
        </Legend>
        <Legend>
          <Day passed preview /> <span>Пройденные лекции</span>
        </Legend>
      </CalendarLegendContainer>
      <Calendar />
    </Container>
  );
};

export default MobileCalendar;
