import { Message } from "./styledComponents";

const FeedbackPanelMessageHistory = () => {
  return (
    <>
      <Message
        time={20}
        author="Астафьев Евгений Иванович"
        message="Да, конечно, перезвоните по номеру +78126767070."
      />
      <Message
        time={20}
        author="Астафьев Евгений Иванович"
        message="Да, конечно, перезвоните по номеру +78126767070."
      />
      <Message
        time={20}
        author="Астафьев Евгений Иванович"
        message="Да, конечно, перезвоните по номеру +78126767070."
      />
      <Message
        time={20}
        author="Астафьев Евгений Иванович"
        message="Да, конечно, перезвоните по номеру +78126767070."
      />
      <Message
        time={20}
        author="Астафьев Евгений Иванович"
        message="Да, конечно, перезвоните по номеру +78126767070."
      />
      <Message
        time={20}
        author="Астафьев Евгений Иванович"
        message="Да, конечно, перезвоните по номеру +78126767070."
      />
      <Message
        time={20}
        author="Астафьев Евгений Иванович"
        message="Да, конечно, перезвоните по номеру +78126767070."
      />
    </>
  );
};

export default FeedbackPanelMessageHistory;
