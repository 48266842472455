import { Button, Input } from "components";
import { DefaultModalContainer } from "containers";
import styled from "styled-components";
import { Services } from "../components";

export const Container = styled(DefaultModalContainer)`
  width: 625px;
`;

export const StyledServices = styled(Services)`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

export const PaymentsInfo = styled.div`
  display: flex;
  margin-top: 15px;
  gap: 45px;
  border: 1px solid rgba(240, 242, 245, 1);
  border-radius: 25px;
  padding: 15px;
`;

export const Amount = styled.div`
  margin-top: 15px;
`;

export const AmountTitle = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  margin-bottom: 16px;
`;

export const AmountInput = styled(Input)`
  width: 50%;
`;

export const StyledButton = styled(Button)`
  padding: 25px 29px;
`;
