import { useDispatch, useSelector } from "react-redux";
import { AccountCard } from "../../components";
import { Icon } from "components";
import { toggleAppBarOpen } from "layout";
import { RootState } from "store";
import { userLogoutActionCreator } from "api/UserAPI";
import { Container, StyledButton } from "./styledComponents";
import { getUserRole } from "selectors/userInfo";
import { useHistory } from "react-router";
import { logout } from "reducers/userInfo";

const MainHeader = () => {
  const history = useHistory();
  const userRole = useSelector(getUserRole);
  const dispatch = useDispatch();
  const userName = useSelector((state: RootState) => state.userInfo.name);

  const handleOpenAppBar = () => {
    dispatch(toggleAppBarOpen());
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(userLogoutActionCreator());
    history.push("/login");
  };

  return (
    <Container>
      {userRole !== "teacher" ? (
        <StyledButton onClick={handleOpenAppBar}>
          <Icon icon="BurgerMenuIcon" />
        </StyledButton>
      ) : null}
      <AccountCard name={userName} onClickLogout={handleLogout} />
    </Container>
  );
};

export default MainHeader;
