import React, { useEffect, useRef, useState } from "react";
import { TitleFullStatisctic } from "./styledComponents";
import styled from "styled-components";

const getPercentsProgress = (total: number, success: number, error: number) => {
  return {
    success: (success / total) * 100,
    error: (error / total) * 100,
  };
};

interface IProps {
  title: string;
  description: string;
  total: number;
  success: number;
  error: number;
  icon: string;
  buttonText?: string;
  buttonClick?: () => void;
}

const DetailProgress = ({
  title,
  description,
  total,
  success,
  error,
  icon,
  buttonText,
  buttonClick,
}: IProps) => {
  const [onMouse, setOnMouse] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const data = getPercentsProgress(total, success, error);

  useEffect(() => {
    if (ref.current && buttonText) {
      ref.current.onmouseout = () => {
        setOnMouse(false);
      };

      ref.current.onmouseover = () => {
        setOnMouse(true);
      };
    }
  }, [ref, buttonText]);

  return (
    <Container ref={ref}>
      <IconBg>
        <Icon src={icon} />
      </IconBg>
      <TitleFullStatisctic>{title}</TitleFullStatisctic>
      <Description>
        {error + success} из {total}
      </Description>

      <Line>
        <Success style={{ width: `${data.success}%` }} />
        <Errors style={{ width: `${data.error}%` }} />
      </Line>
      <Description style={{ display: onMouse ? "none" : "block" }}>
        {description}
      </Description>

      {buttonText && (
        <Button
          onClick={buttonClick}
          style={{ display: onMouse ? "block" : "none" }}
        >
          Продолжить {buttonText}
        </Button>
      )}
    </Container>
  );
};

const Button = styled.button`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #3cb758;
  background: transparent;
  border: 0;
  margin: 6px 0 16px 0;
  padding: 0;
  cursor: pointer;
  transition: 0.6s;

  &:hover {
    color: #247035;
  }
`;

const IconBg = styled.div`
  background: #dadde4;
  border-radius: 40px;
  position: absolute;
  padding: 8px;
  right: 15px;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.6s;
`;

const Icon = styled.img`
  width: 25px;
  height: 25px;
`;

const Description = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1f2021;
  width: 100%;
  margin: 6px 0 16px 0;
`;

const Container = styled.div`
  background-color: #ffffff;
  border: 6px solid #fbfbfb;
  border-radius: 16px;
  box-shadow: 0px 10px 24px rgba(225, 225, 232, 0.55);
  position: relative;
  padding: 23px 22px;
  transition: 0.3s;

  &:hover {
    background: #eef9ef;
    border: 6px solid #eef9ef;
  }

  &:hover ${IconBg} {
    background: #55d071;
  }
`;

const Line = styled.div`
  display: flex;
  height: 8px;
  margin: 8px 0;
  width: 100%;
  overflow: hidden;
  background: rgba(218, 221, 228, 0.6);
  border-radius: 16px;
`;

const Success = styled.div`
  background: #87e09b;
`;

const Errors = styled.div`
  background: #ff7979;
`;

export default DetailProgress;
