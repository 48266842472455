import { Slider } from "components";
import { PaperContainer } from "containers";
import styled from "styled-components";

export const Container = styled(PaperContainer)`
  height: 80px;
  width: 560px;
  display: flex;
  justify-content: center;
  align-items: center;

  & div {
    margin-right: 24px;
  }
`;

export const StyledSlider = styled(Slider)`
  width: 248px !important;
`;
