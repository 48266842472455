import { createSlice } from "@reduxjs/toolkit";

const userInfoReducer = createSlice({
  name: "userInfoReducer",
  initialState: {
    name: "",
    role: "",
    email: "",
    username: "",
    id: null as null | number,
  },
  reducers: {
    setUserName: (state, action) => {
      return { ...state, name: action.payload };
    },
    setUserUsername: (state, action) => {
      state.username = action.payload;
    },
    setUserRole: (state, action) => {
      state.role = action.payload;
    },
    setUserId: (state, action) => {
      state.id = action.payload;
    },
    logout: () => {
      return {
        name: "",
        role: "",
        email: "",
        username: "",
        id: null,
      };
    },
  },
});

export const { setUserName, setUserUsername, setUserRole, setUserId, logout } =
  userInfoReducer.actions;

export default userInfoReducer.reducer;
