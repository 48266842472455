import React from "react";
import styled from "styled-components";
import { VideoPlayer } from "components";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getLectureVideo,
  getLectureVideoInfo,
} from "pages/LectureArchive/redux";
import { RootState } from "store";
import { useEffect, useMemo } from "react";
import { getLectureArchiveInfo } from "api/LecturesArchiveAPI";
import { Task } from "../../components/VideoPlayer/types";
import { removeModalRestrictions, setModalRestrictions } from "../../reducers";
import { getLectureArchiveList } from "api/UserAPI";

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.secondaryGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 88px);
`;

const NotFoundText = styled.p`
  color: #fff;
`;

const LectureVideo: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const video = useSelector((state: RootState) =>
    getLectureVideo(state, params.id)
  );
  const info = useSelector((state: RootState) =>
    getLectureVideoInfo(state, params.id)
  );

  useEffect(() => {
    dispatch(getLectureArchiveList());
    dispatch(setModalRestrictions("WebinarTaskModal"));
    return () => {
      dispatch(removeModalRestrictions("WebinarTaskModal"));
    };
  }, [dispatch]);

  useEffect(() => {
    if (params?.id) {
      dispatch(getLectureArchiveInfo(Number(params.id)));
    }
  }, [params, dispatch]);

  const tasks = useMemo(() => {
    if (info) {
      const items: Task[] = info.timings.reduce((result, item) => {
        if (item.entity_alias === "interactive_task") {
          result.push({
            start: item.time_start,
            end: item.time_finish,
            id: item.entity_id,
            text: "bla bla",
            color: "#000",
          });
        }
        return result;
      }, []);
      return items;
    }
    return [];
  }, [info]);

  return (
    <Container>
      {video ? (
        <VideoPlayer
          videoData={video}
          tasks={tasks}
          url={video.video_url}
          info={info}
          timeStart={video.webinar_started_at}
          timeEnd={video.webinar_finished_at}
        />
      ) : (
        <NotFoundText>Видео не найденно</NotFoundText>
      )}
    </Container>
  );
};

export default LectureVideo;
