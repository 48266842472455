import { RatingProps } from "@material-ui/lab";
import MUIRating from "@material-ui/lab/Rating";

type Props = RatingProps;

const Rating = ({ onChange, value, className, size, ...props }: Props) => {
  return (
    <MUIRating
      onChange={onChange}
      value={value}
      className={className}
      size={size}
      {...props}
    />
  );
};

export default Rating;
