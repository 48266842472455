import { Button } from "components";
import styled from "styled-components";

export const Text = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.subtitle1FontSize};
  font-weight: ${(props) => props.theme.fontWeights.superSkinnyFontWeight};
  color: ${(props) => props.theme.colors.secondaryGrey};
`;

export const BodyVoiceQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 26px;
`;

export const PrimaryButton = styled(Button)`
  margin-top: 48px;
`;

export const Image = styled.img`
  width: 185px;
  height: 130px;
`;
