const ArrowUpIcon = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.63553 10.9021C3.97699 11.2482 4.53551 11.2482 4.87697 10.9021L7.88993 7.84847C8.1052 7.63029 8.4573 7.63029 8.67257 7.84847L11.6855 10.9021C12.027 11.2482 12.5855 11.2482 12.927 10.9021C13.262 10.5626 13.262 10.0168 12.927 9.67725L9.176 5.87559C8.6838 5.37674 7.8787 5.37674 7.3865 5.87559L3.63553 9.67725C3.30049 10.0168 3.30049 10.5626 3.63553 10.9021Z"
    />
  );
};

export default ArrowUpIcon;
