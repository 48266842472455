import { secondaryLightGray } from "constants/color";
import {
  AccountCardContainer,
  FullName,
  Label,
  StyledIcon,
  UserInfo,
} from "./styeldComponents";

type Props = {
  name: string;
  className?: string;
  previewMode?: boolean;
  label?: string;
  src?: string;
  onClickLogout?: () => void;
};

const AccountCard = ({
  name,
  className,
  previewMode,
  label,
  onClickLogout,
}: Props) => {
  const renderInfo = () => {
    if (label) {
      return (
        <UserInfo>
          <div>
            <Label>{label}</Label>
            <FullName>{name}</FullName>
          </div>
        </UserInfo>
      );
    }

    return (
      <UserInfo>
        <FullName>{name}</FullName>
      </UserInfo>
    );
  };
  return (
    <AccountCardContainer className={className}>
      {renderInfo()}
      {!previewMode && (
        <StyledIcon
          icon="LogOutIcon"
          onClick={onClickLogout}
          color={secondaryLightGray}
        />
      )}
    </AccountCardContainer>
  );
};

export default AccountCard;
