import { QuestionResponse } from "api/models/ticket";
import { StatusQuestion, UserAnswer } from "pages";
import { useEffect, useState } from "react";

export const useGetQuestionAnswerStatus = (
  question: QuestionResponse,
  userAnswer?: UserAnswer
) => {
  const [result, setResult] = useState<StatusQuestion>("no-answer");

  useEffect(() => {
    if (userAnswer) {
      const findAnswer = question.answers.find(
        (item) => item.id === userAnswer.answerId
      );
      if (findAnswer) {
        setResult(findAnswer.correct ? "ok" : "wrong");
      }
    }
  }, [question, userAnswer]);

  return result;
};
