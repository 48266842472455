import { RootState } from "store";
import { createDraftSafeSelector } from "@reduxjs/toolkit";

export const getUserDevices = (state: RootState) => state.userInfo;

export const getUserRole = (state: RootState) => state.userInfo.role;

export const getUserName = (state: RootState) => state.userInfo.username;

const getUserInfo = (state: RootState) => state.userInfo;

export const getUserId = createDraftSafeSelector(
  getUserInfo,
  (state) => state.id
);
