import { Button, Select } from "components";
import { DefaultModalContainer } from "containers";
import { Indicator } from "shared";
import styled from "styled-components";

export const Container = styled(DefaultModalContainer)`
  width: 728px;
`;

export const SettingTitle = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading6FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const SettingRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 52px;

  &:first-of-type(1) {
    margin-top: 52px;
  }
`;

export const SelectWrapper = styled(Select)`
  min-width: 368px;
`;

export const IndicatorWrapper = styled(Indicator)`
  width: 368px;
  margin-top: 8px;
`;

export const IndicatorContainer = styled.div``;

export const IndicatorDescription = styled.div`
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  font-weight: ${(props) => props.theme.fontWeights.superSkinnyFontWeight};
  margin-top: 16px;
`;

export const AcceptButton = styled(Button)`
  margin: 56px auto 0;
  width: 152px;
  text-align: center;
`;
