import { primaryWhite } from "constants/color";
import { Icon } from "components";
import { ChatHeaderListItem } from "shared";
import { FoldedHeaderContainer } from "./styeldComponents";

const FoldedHeader = () => (
  <FoldedHeaderContainer>
    <ChatHeaderListItem active={false}>Общий чат</ChatHeaderListItem>
    <Icon icon="ArrowUpIcon" size="s" color={primaryWhite} />
  </FoldedHeaderContainer>
);

export default FoldedHeader;
