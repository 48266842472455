import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #fff;
`;

const loaderRotate = keyframes`
  0% {
    transform: rotate(0); 
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div<{ size?: number }>`
  width: ${({ size }) => size ?? "56"}px;
  height: ${({ size }) => size ?? "56"}px;
  border: 2px solid #4ac365;
  border-radius: 50%;
  position: relative;
  animation: ${loaderRotate} 1s ease-in-out infinite;
  top: 50%;
  left: 0;
  right: 0;
  margin: -28px auto 0 auto;

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #4ac365;
    position: absolute;
    top: -6px;
    left: 50%;
    margin-left: -5px;
  }
`;

const Preloader = () => {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
};

export default Preloader;
