import { PaperContainer } from "containers";
import styled from "styled-components";

export const Container = styled(PaperContainer)`
  min-width: 360px;
`;

export const Header = styled.div`
  height: 60px;
  border-bottom: 1px solid ${(props) => props.theme.colors.opacityGrey5};
  line-height: 60px;

  span {
    font-size: ${(props) => props.theme.fontSizes.subtitle1FontSize};
    font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
    color: ${(props) => props.theme.colors.primaryBlack};
    padding: 0 24px;
  }
`;

export const User = styled.div<{
  active: "online" | "offline" | "none";
  preview?: boolean;
}>`
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  color: ${(props) => props.theme.colors.primaryBlack};

  ${(props) =>
    props.preview
      ? ""
      : `
      ::before {
        display: block;
        content: "";
        width: 12px;
        height: 12px;
        background-color: ${
          props.active === "online"
            ? props.theme.colors.primaryGreen
            : props.active === "offline"
            ? props.theme.colors.secondaryRed
            : "#ccc"
        };
        border-radius: 50%;
        margin-right: 8px;
      }
  `}

  &:nth-child(2n + 1) {
    background-color: ${(props) => props.theme.colors.opacityLightGrey10};
  }
`;

export const ScrollContainer = styled.div`
  overflow: auto;
  margin-right: 8px;
  max-height: calc(100% - 60px);
`;
