import { useEffect, useRef } from "react";
import styled from "styled-components";

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 88px);
  overflow: hidden;
`;

const VideoElement = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background: #000;
`;

const VideoStream = ({
  lecturePromo,
  url,
  streamKey,
}: {
  lecturePromo: any;
  url: string;
  streamKey: string;
}) => {
  // const [webAdaptor, setWebAdaptor] = useState<WebRTCAdaptor | null>(null);
  const videoElement = useRef<HTMLDivElement>(null);
  // const [iceConnected, setIceConnected] = useState(false);

  useEffect(() => {
    // @ts-ignore
    let player: any;
    if (
      navigator.userAgent.indexOf("iPad") !== -1 ||
      navigator.userAgent.indexOf("iPhone") !== -1
    ) {
      // @ts-ignore
      player = new window.Clappr.Player({
        source: `${lecturePromo.video_stream_alt_url}/${lecturePromo.video_stream_alt_id}`,
        parentId: "#player",
        width: "100%",
        height: "100%",
        autoPlay: true,
      });
    } else {
      // @ts-ignore
      player = window.SLDP.init({
        container: "player",
        stream_url: `${url}/${streamKey}`,
        initial_resolution: "480p",
        buffering: 500,
        autoplay: true,
        width: "parent",
        height: "parent",
        controls: true,
      });
    }

    return () => {
      player.destroy();
    };
  }, []);

  return (
    <VideoWrapper>
      <VideoElement ref={videoElement} id="player" />
      test
    </VideoWrapper>
  );
};

export default VideoStream;
