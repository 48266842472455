export const prevSvg = () => {
  return (
    <svg width="26" height="30" viewBox="0 0 26 30" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.52466 26.268C6.52466 27.9983 5.12332 29.4002 3.39246 29.4002C1.66286 29.4002 0.260254 27.9976 0.260254 26.268V3.73181C0.260254 2.00158 1.66223 0.599609 3.39246 0.599609C5.12332 0.599609 6.52466 2.00158 6.52466 3.73181V26.268ZM24.084 29.1429C23.1368 29.5344 22.0462 29.3151 21.3233 28.5885L10.1338 17.3426C8.82578 16.0289 8.82578 13.9053 10.1338 12.591L21.3233 1.34579C22.0462 0.619121 23.1362 0.399867 24.084 0.791393C25.0318 1.18229 25.6501 2.10629 25.6501 3.13178V26.8031C25.6501 27.8273 25.0318 28.7526 24.084 29.1429Z"
        fill="white"
      />
    </svg>
  );
};

export const nextSvg = () => {
  return (
    <svg width="26" height="30" viewBox="0 0 26 30" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4301 26.268C19.4301 27.9983 20.8314 29.4002 22.5623 29.4002C24.2919 29.4002 25.6945 27.9976 25.6945 26.268V3.73181C25.6945 2.00158 24.2925 0.599609 22.5623 0.599609C20.8314 0.599609 19.4301 2.00158 19.4301 3.73181V26.268ZM1.87128 29.1432C2.81846 29.5348 3.90909 29.3155 4.632 28.5888L15.8215 17.343C17.1295 16.0293 17.1295 13.9057 15.8215 12.5914L4.632 1.34617C3.90909 0.619495 2.81908 0.40024 1.87128 0.791766C0.923473 1.18266 0.305176 2.10667 0.305176 3.13215V26.8035C0.305176 27.8277 0.923473 28.753 1.87128 29.1432Z"
        fill="white"
      />
    </svg>
  );
};

export const pauseSvg = (width: number = 20, height: number = 20) => {
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 30 48"
      fill="none"
    >
      {/* <svg width="30" height="48" viewBox="0 0 30 48" fill="none"> */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.600098 5.74247C0.600098 2.90258 2.90204 0.599609 5.74295 0.599609C8.5849 0.599609 10.8858 2.90155 10.8858 5.74247V42.7453C10.8858 45.5862 8.58387 47.8882 5.74295 47.8882C2.90204 47.8882 0.600098 45.5873 0.600098 42.7453V5.74247ZM19.1142 5.74286C19.1142 2.90297 21.4161 0.6 24.257 0.6C27.0979 0.6 29.3999 2.90297 29.3999 5.74286V42.7457C29.3999 45.5866 27.0979 47.8886 24.257 47.8886C21.4161 47.8886 19.1142 45.5866 19.1142 42.7457V5.74286Z"
        fill="white"
      />
    </svg>
  );
};

export const playSvg = (width: number = 20, height: number = 20) => {
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 28 49"
      fill="none"
    >
      <path
        d="M7.34369 47.7276C6.11673 48.9667 4.26565 49.3406 2.65806 48.673C1.0494 48.0075 0 46.4297 0 44.6831V4.31794C0 2.56925 1.0494 0.993611 2.65806 0.327036C4.26672 -0.340608 6.11673 0.0332725 7.34369 1.27242L26.335 20.4482C28.555 22.6894 28.555 26.3107 26.335 28.5507L7.34369 47.7276Z"
        fill="white"
      />
    </svg>
  );
};
