import { Header } from "layout/ModalWindow/layout";
import { closeModal } from "layout/ModalWindow/redux";
import { useDispatch } from "react-redux";
import { StyledButton, Container } from "./styledComponents";
import styled from "styled-components";
import { Checkbox } from "@material-ui/core";

import { useEffect, useState } from "react";
import {
  addBillingServiceUser,
  getBillingAllServices,
} from "../../../../api/CabinetPromoApi";
import { Loader } from "../../../../components";
import { setNotification } from "../../../Notifications";

type Props = {
  name: string;
  refetch: () => void;
};

export const PaymentListModal = ({ name, refetch }: Props) => {
  const [data, setData] = useState<any[]>([]);
  const [ids, setIds] = useState<number[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getBillingAllServices();
        setData(data.items);
      } catch (e) {
        console.log(e);
      }
    };

    getData();
  }, []);

  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal(name));
  };

  const handleChecked = (id: number) => {
    if (ids.includes(id)) {
      setIds(ids.filter((item) => item !== id));
    } else {
      setIds([...ids, id]);
    }
  };

  const addService = async () => {
    await addBillingServiceUser(ids);
    dispatch(
      setNotification({
        title: "Услуги добавлены",
        message: `Услуги добавлены`,
        status: "success",
      })
    );
    refetch();
    handleCloseModal();
  };

  return (
    <Container>
      <Header onClose={handleCloseModal} title="Доступные услуги" />
      <Desc>Вы можете добавить сразу несколько услуг.</Desc>

      <List>
        {data.length === 0 && <Loader />}

        {data.map((item) => (
          <Item key={item.id}>
            <Checkbox
              color="default"
              checked={ids.includes(item.id)}
              onClick={() => handleChecked(item.id)}
            />
            <Name>{item.name}</Name>
            <Price>{item.price.toLocaleString("ru")} ₽</Price>
          </Item>
        ))}
      </List>
      <Controls>
        <StyledButton buttonTheme="secondary" onClick={handleCloseModal}>
          Отменить
        </StyledButton>
        <StyledButton disabled={ids.length === 0} onClick={addService}>
          Добавить
        </StyledButton>
      </Controls>
    </Container>
  );
};

const List = styled.div`
  margin-top: 2em;
`;

const Item = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0;
  border-bottom: 1px solid rgba(240, 242, 245, 1);
`;

const Price = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  min-width: 80px;
  width: 15%;
  text-align: right;
`;

const Name = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  width: 100%;
`;

const Desc = styled.div`
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
`;

const Controls = styled.div`
  margin-top: 45px;
  display: flex;
  justify-content: end;
  gap: 20px;
`;
