import styled from "styled-components";
import { Button } from "../../../../../components";

export const Wrapper = styled.div`
  padding: 0 20px;
  width: calc(100vw - 40px);
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: ${(props) => props.theme.colors.opacityLightGrey20};
`;

export const Container = styled.div`
  max-width: 480px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  border-radius: 16px;
  padding: 30px 32px 24px;
  box-sizing: border-box;
`;

export const Subtitle = styled.div`
  margin-top: 24px;
  line-height: 27px;
  font-size: ${(props) => props.theme.fontSizes.subtitle1FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
`;

export const InputContainer = styled.div`
  display: block;
  margin-top: 40px;
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.secondaryRed};
`;

export const ErrorContainer = styled.div`
  height: 15px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 312px;
  height: 48px;
  margin: 33px auto 0;
  border-radius: 40px;
  background-color: #fc5e5e;
  border: none;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #fc5e5ecc;
  }
`;
