import { useEffect, useRef, useState } from "react";
import { ChatHeaderListItem } from "shared";
import FeedbackPanelConnections from "../FeedbackPanelConnections";
import FeedbackPanelMessageHistory from "../FeedbackPanelMessageHistory";
import FeedbackPanelNotificationsList from "../FeedbackPanelNotificationsList";
import {
  Body,
  Container,
  Header,
  HeaderTitle,
  LeftArrow,
} from "./styledComponents";
import { useSelector } from "react-redux";
import { getChatUserVoiceNotifications } from "../../redux";

type Tab = "notifications" | "connections" | "history";

const WebinarFeedbackPanel = () => {
  const feedbackPanelNotificationsList = useRef<any>(null);
  const [tab, setTab] = useState<Tab>("notifications");
  const chatUserVoiceNotifications = useSelector(getChatUserVoiceNotifications);

  useEffect(() => {
    scrollToContainerBottom();
  }, [chatUserVoiceNotifications]);

  const scrollToContainerBottom = () => {
    feedbackPanelNotificationsList.current?.scrollTo({
      top: feedbackPanelNotificationsList.current?.scrollHeight,
    });
  };

  const isTabNotifications = tab === "notifications" || tab === "history";

  const renderBody = () => {
    switch (tab) {
      case "notifications":
        return (
          <FeedbackPanelNotificationsList
            onClickOnHistory={() => setTab("history")}
          />
        );
      case "connections":
        return <FeedbackPanelConnections />;
      case "history":
        return <FeedbackPanelMessageHistory />;
    }
  };

  const renderHeader = () => {
    if (tab === "history") {
      return (
        <>
          <HeaderTitle>
            <LeftArrow
              icon="ArrowLeftIcon"
              onClick={() => setTab("notifications")}
            />
            Астафьев Евгений Иванович
          </HeaderTitle>
        </>
      );
    }

    return (
      <>
        <ChatHeaderListItem
          active={isTabNotifications}
          preview={!isTabNotifications}
          revertColor
          onClick={() => setTab("notifications")}
        >
          Уведомления
        </ChatHeaderListItem>
        {/*<ChatHeaderListItem*/}
        {/*  active={!isTabNotifications}*/}
        {/*  preview={isTabNotifications}*/}
        {/*  revertColor*/}
        {/*  onClick={() => setTab("connections")}*/}
        {/*>*/}
        {/*  Активные соединения*/}
        {/*</ChatHeaderListItem>*/}
      </>
    );
  };

  return (
    <Container>
      <Header>{renderHeader()}</Header>
      <Body ref={feedbackPanelNotificationsList}>{renderBody()}</Body>
    </Container>
  );
};

export default WebinarFeedbackPanel;
