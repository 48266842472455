import { Button, Rating } from "components";
import { closeModal } from "layout/ModalWindow/redux";
import { useDispatch } from "react-redux";
import {
  StyledRatingContainer,
  Title,
  Container,
  Subtitle,
  ButtonsContainer,
  SkipButton,
} from "./styledComponents";

type Props = {
  name: string;
};

const LectureRatingBeforeFinish = ({ name }: Props) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal(name));
  };

  return (
    <Container>
      <Title>Вы просмотрели лекцию</Title>
      <Subtitle>Пожалуйста, поставте свою оценку</Subtitle>
      <StyledRatingContainer>
        <Rating size="large" />
      </StyledRatingContainer>
      <ButtonsContainer>
        <SkipButton buttonTheme="secondary" onClick={handleCloseModal}>
          Пропустить
        </SkipButton>
        <Button onClick={handleCloseModal}>Готово</Button>
      </ButtonsContainer>
    </Container>
  );
};

export default LectureRatingBeforeFinish;
