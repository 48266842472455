import React, { useState } from "react";

import downLoadCircle from "assets/images/download_circle.svg";
import googlePlay from "assets/images/google_play.svg";
import googlePlayHovered from "assets/images/google_play_hover.svg";
import appStore from "assets/images/app_store.svg";
import appStoreHovered from "assets/images/app_store_hover.svg";

import qrCodePlayMarket from "assets/images/qr-code-play-market.png";
import qrCodeAppStore from "assets/images/qr-code-tmp.jpeg";

import mokap from "assets/images/mokap.svg";
import {
  FooterContainer,
  Header,
  Image,
  ImageMokap,
  Title,
  ContentBlock,
  LeftImageBlock,
  RightTextBlock,
  TitleRightBlock,
  PRightBlock,
  ButtonContainer,
  QRCodeContainer,
  QRCode,
} from "./styledComponents";

const Footer = () => {
  const [playMarketHover, setPlayMarketHover] = useState<boolean>(false);
  const [appStoreHover, setAppStoreHover] = useState<boolean>(false);
  const [currentButton, setCurrentButton] = useState<string>("");
  const googlePlayLink: string =
    "https://play.google.com/store/apps/details?id=ru.svetofor.app&hl=ru-ru";
  const appStoreLink: string =
    "https://apps.apple.com/ru/app/%D0%BF%D0%B4%D0%B4-%D1%82%D0%B2/id1597304100";

  const setMouseEnter = (event: React.MouseEvent) => {
    const { id } = event.currentTarget;
    if (id === "playMarket") {
      setPlayMarketHover(true);
      setCurrentButton("playMarket");
    } else {
      setAppStoreHover(true);
      setCurrentButton("appStore");
    }
  };

  const setMouseLeave = (event: React.MouseEvent) => {
    const { id } = event.currentTarget;
    if (id === "playMarket") {
      setPlayMarketHover(false);
      setCurrentButton("");
    } else {
      setAppStoreHover(false);
      setCurrentButton("");
    }
  };

  return (
    <FooterContainer>
      <Header>
        <Title>
          Онлайн лекции <br /> и билеты в твоем телефоне!
        </Title>
        <Image src={downLoadCircle} alt="side menu circle download" />
      </Header>
      <ContentBlock>
        <LeftImageBlock>
          <ImageMokap src={mokap} alt="side menu circle download" />
        </LeftImageBlock>
        <RightTextBlock>
          <TitleRightBlock>
            Автошкола в <br />
            кармане
          </TitleRightBlock>
          <PRightBlock>
            Теперь обучаться <br />
            еще удобнее. Скачивай мобильное приложение <br />
            на IOS и Android.
          </PRightBlock>
        </RightTextBlock>
      </ContentBlock>
      <ButtonContainer>
        <a href={googlePlayLink} target="_blank" rel="noreferrer">
          <img
            id="playMarket"
            src={!playMarketHover ? googlePlay : googlePlayHovered}
            alt="google play"
            onMouseEnter={setMouseEnter}
            onMouseLeave={setMouseLeave}
          />
        </a>
        <a href={appStoreLink} target="_blank" rel="noreferrer">
          <img
            id="appStore"
            src={!appStoreHover ? appStore : appStoreHovered}
            alt="google play"
            onMouseEnter={setMouseEnter}
            onMouseLeave={setMouseLeave}
          />
        </a>
        <QRCodeContainer
          isActive={playMarketHover || appStoreHover}
          currentButton={currentButton}
        >
          <QRCode
            src={
              currentButton === "playMarket" ? qrCodePlayMarket : qrCodeAppStore
            }
          />
        </QRCodeContainer>
      </ButtonContainer>
    </FooterContainer>
  );
};

export default Footer;
