import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

const loaderReducer = createSlice({
  name: "loader",
  initialState: { isLoad: true },
  reducers: {
    setLoader: (state, action: { payload: { value: boolean } }) => {
      return { ...state, isLoad: action.payload.value };
    },
  },
});

export const { setLoader } = loaderReducer.actions;

export const getLoader = (state: RootState) => state.loader.isLoad;

export default loaderReducer.reducer;
