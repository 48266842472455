import {
  BodyChat,
  BodyVoiceQuestion,
} from "layout/OnlineWebinarLectureWidget/components";
import { BodyContainer } from "./styledComponents";

type Props = {
  tab: string;
  onMouseUp?: () => void;
  onMouseDown?: () => void;
};

const Body = ({ tab }: Props) => {
  const renderLayout = () => {
    if (tab === "chat") {
      return <BodyChat />;
    }

    if (tab === "voiceQuestion") {
      return <BodyVoiceQuestion />;
    }
  };

  return <BodyContainer>{renderLayout()}</BodyContainer>;
};

export default Body;
