import { Header } from "layout/ModalWindow/layout";

import image from "assets/images/Success.png";
import { useDispatch } from "react-redux";
import { closeModal } from "layout/ModalWindow/redux";
import {
  Image,
  Title,
  Container,
  Subtitle,
  StyledButton,
} from "./styledComponents";

type Props = {
  name: string;
};

const PaymentsSuccessModal = ({ name }: Props) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal(name));
  };

  return (
    <Container>
      <Header onClose={handleClose} />
      <Image src={image} />
      <Title>Успешная оплата!</Title>
      <Subtitle>Ваша оплата была произведена успешно</Subtitle>
      <StyledButton onClick={handleClose}>Закрыть</StyledButton>
    </Container>
  );
};

export default PaymentsSuccessModal;
