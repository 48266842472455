import styled from "styled-components";
import WhyFaqToggle from "./WhyFaqToggle";

const ContainerTest = styled.div`
    max-width: calc(100vw - 20px);
    margin-bottom: 140px;
    width: 100%;
    margin-inline: auto;
    @media (max-width: 768px) {
      margin-bottom: 30px !important;
    }
  `;
export default function WhyComponent ({Title,primary, Text}) {
    const Title_ = styled(Title)`
    margin-bottom: 20px;
  `;
const Container_ = styled(ContainerTest)`
    border-radius: 24px;
    background-color: #f5f5f5;
  
    
    padding: 60px 40px 20px 40px;
         @media (max-width: 768px){
         padding: 30px;
         }
`;
const Text_ = styled(Text)`
color: ${primary};
max-width: 839px;
margin-bottom: 40px;
margin-top: 0;
font-size: 18px;
line-height: 28px;

`;
    return (
        <>
        <Container_>
            <Title_>Почему же именно ПДД.ТВ?
            </Title_>
            <Text_>В чем преимущества полноценного онлайн обучения по сравнению с обучением по видеороликам, которое предлагает большинство автошкол в качестве дистанционного обучения?</Text_>
       <WhyFaqToggle />
        </Container_>
        </>
    )
}
