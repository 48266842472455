import CanvasDraw from "react-canvas-draw";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const Canvas = styled(CanvasDraw)<{ scale?: number }>`
  position: absolute;
  z-index: 2;
  transform-origin: 0 0;
  transform: ${(props) => `scale(${props.scale}%)`};
  top: 0;
  left: 0;
  right: 0;
`;

export const Iframe = styled.iframe<{ scale?: number }>`
  height: 1080px;
  width: 1920px;
  position: absolute;
  transform-origin: 0 0;
  transform: ${(props) => `scale(${props.scale! - 0.1}%)`};
  z-index: 1;
`;

export const DrawField = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
`;
