import {
  FeedbackPanelConnection,
  FeedbackPanelNotification,
} from "../../components";
import { getChatUserVoiceNotifications } from "../../redux";
import { useSelector } from "react-redux";
import { OPERATION } from "pages/Webinars/types";

type Props = {
  onClickOnHistory: () => void;
};

const FeedbackPanelNotificationsList = ({ onClickOnHistory }: Props) => {
  const chatUserVoiceNotifications = useSelector(getChatUserVoiceNotifications);
  return (
    <>
      {chatUserVoiceNotifications.map(({ user, operation, id }) => {
        if (operation === OPERATION.NOTICE) {
          return (
            <FeedbackPanelNotification
              key={id}
              onClickOnHistory={onClickOnHistory}
              chatUserVoiceNotification={{ ...user, operation, messageId: id }}
            />
          );
        }
        if (operation === OPERATION.VOICE) {
          return (
            <FeedbackPanelConnection
              key={id}
              chatUserVoiceNotification={user}
            />
          );
        }
      })}
    </>
  );
};

export default FeedbackPanelNotificationsList;
