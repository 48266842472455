import styled from "styled-components"
import ProgressBlockDynamic from "./ProgressBlockDynamic";
import RightImage from './assets/images/Group_900-min.png'
import './ProgressBlock.css';
export default function ProgressBlock ({ExtraTitle,ConsultButton,primary, green}) {

    const Title = styled(ExtraTitle)`
    color: ${primary};  
    margin-bottom: 40px;
@media (max-width: 768px){
    margin-bottom: 30px!important;
}
    `

    const Container = styled.div`
    border-radius: 24px;
    background-color: #f5f5f5;
padding: 60px 40px 40px;
margin-bottom: 70px;
position: relative;
margin-top: 70px;
@media (max-width: 768px){
padding: 249px 30px 30px 30px!important;

}
    `;
    const Wrapper = styled.div`
    max-width: 560px;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px){
       max-width: 100%;
    }
    `;

const WhiteConsultButton = styled(ConsultButton)`
background: white;
max-width: 382px;
width: 100%;
padding-inline: 50px;
text-align: center;

`
const Image = styled.div`
position: absolute;
bottom: -20px;
right: -25px;
width: 646px;
height: 726px;
background: url(${RightImage});
background-size: cover;
    @media (max-width: 768px){
    bottom: auto!important;
    width: 320px!important;
    height: 360px!important;
    top: -137px!important;
    right: 10px!important;
    }
`
    return (
        <>
        <Container>
            <Wrapper>
        <Title>Мы упростили для вас процесс обучения</Title>
        <ProgressBlockDynamic green={green}/>
     <WhiteConsultButton as='a' href='#consult'>Нужна консультация → </WhiteConsultButton>

        </Wrapper>
        <Image></Image>
        </Container>
        </>
    )
}
