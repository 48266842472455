import { PaperContainer } from "containers";
import styled from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";

export const ProgressContainer = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  /* margin-top: 52px; */
  justify-content: space-between;

  @media screen and (min-width: 500px) {
    flex-flow: row nowrap;
  }
`;

export const ProgressBlock = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 100%;
  gap: 15px;

  @media screen and (min-width: 500px) {
    max-width: 48%;
  }

  @media screen and (min-width: 1550px) {
    max-width: 100%;
  }
`;

export const ProgressBlockTotal = styled(PaperContainer)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  flex: 1;
  padding: 0 20.3px 0 32px;
  margin-top: 16px;
  box-sizing: border-box;
  border-radius: 16px;

  @media screen and (max-width: 1024px) {
    padding: 0;
  }

  @media screen and (max-width: 500px) {
    padding: 0 25px 0 25px;
  }
`;

export const LeftVideoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding: 45px 20.3px 45px 32px;
  margin-top: 16px;
  box-sizing: border-box;
  height: 198px;
  box-shadow: 0px 10px 24px rgba(225, 225, 232, 0.55);
  border-radius: 16px;
`;

export const LeftStatistic = styled.div`
  flex: 2;
`;

export const TitleStatisctic = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1f2021;
  padding: 0;

  margin: 0;

  @media screen and (max-width: 1024px) {
    font-size: ${(props) => props.theme.fontSizes.heading7FontSize};
  }

  @media screen and (max-width: 500px) {
    font-size: ${(props) => props.theme.fontSizes.heading7FontSize};
  }
`;

export const TitleFullStatisctic = styled.div`
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.heading7FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  padding: 0;
  line-height: 22px;
  width: 100%;
  text-align: left;

  @media screen and (max-width: 1024px) {
    font-size: ${(props) => props.theme.fontSizes.heading7FontSize};
  }

  @media screen and (max-width: 500px) {
    font-size: ${(props) => props.theme.fontSizes.heading7FontSize};
  }
`;

export const Total = styled.div<{ performedPersent: number }>`
  color: ${(props) =>
    props.performedPersent > 51
      ? props.theme.colors.primaryGreen
      : props.theme.colors.secondaryRed};
  font-size: ${(props) => props.theme.fontSizes.heading40FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  padding: 0;
  margin-top: 16px;
  line-height: 49px;
  margin: 0;

  @media screen and (max-width: 1100px) {
    font-size: ${(props) => props.theme.fontSizes.heading2FontSize};
  }

  @media screen and (max-width: 500px) {
    font-size: ${(props) => props.theme.fontSizes.heading2FontSize};
  }
`;

export const RightStatistic = styled.div`
  flex: 0 0 107px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1100px) {
    flex: 0 0 60px;
  }
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.heading5FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  text-align: left;
  margin: 20px 0 5px 0;

  @media screen and (max-width: 500px) {
    font-size: ${(props) => props.theme.fontSizes.heading5FontSize};
  }
`;

export const DetailProgressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  margin-top: 32px;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 30px;
  box-sizing: border-box;

  @media screen and (max-width: 500px) {
    padding: 0 30px;
  }
`;

export const LinearProgressContainer = styled.div`
  width: 100%;
`;

export const LinearProgressCustom = styled(LinearProgress)`
  width: 100%;
  color: red;
`;

export const Label = styled.div`
  margin-top: 12px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding-top: 13px;
`;

export const TaskStatus = styled.div<{ isSolved: boolean }>`
  color: ${(props) =>
    props.isSolved
      ? props.theme.colors.primaryGreen
      : props.theme.colors.secondaryRed};
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  font-weight: 600;
  width: 100%;
  padding: 12px 24px;
`;
