import styled from "styled-components";

export const HeaderContainer = styled.div`
  height: 88px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;

  @media screen and (max-width: 500px) {
    padding: 0 8px;
    height: ${({ type }: { type?: "easyHeader" }) =>
      type === "easyHeader" ? "44px" : "88px"};
  }
`;
