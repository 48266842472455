import styled from "styled-components";
import { PropsWithChildren } from "react";
import image from "assets/images/attentionCircle.svg";

type Props = {
  time: string | number;
  author: string;
  message: string;
  mute?: () => void;
  className?: string;
  authorFontSize?: string;
  messageFontSIze?: string;
  timeFontSize?: string;
};

const ChatMessageContainer = styled.div`
  padding: 8px;
  background-color: ${(props) => props.theme.colors.opacityGreen10};
  border-radius: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Author = styled.div<{ fontSize?: string }>`
  font-size: ${(props) =>
    props.fontSize || props.theme.fontSizes.body1FontSize};
  color: ${(props) => props.theme.colors.primaryGreen};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

const Time = styled.div<{ fontSize?: string }>`
  font-size: ${(props) =>
    props.fontSize || props.theme.fontSizes.captionFontSize};
  font-weight: ${(props) => props.theme.fontWeights.superSkinnyFontWeight};
  color: ${(props) => props.theme.colors.secondaryGrey};
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Body = styled.div<{ fontSize?: string }>`
  flex: 1;
  margin-top: 8px;
  font-size: ${(props) =>
    props.fontSize || props.theme.fontSizes.body1FontSize};
  font-weight: ${(props) => props.theme.fontWeights.skinnyFontWeight};
  color: ${(props) => props.theme.colors.primaryBlack};
`;

const Button = styled.div`
  padding: 10px;
  :hover {
    color: ${(props) => props.theme.colors.secondaryRed};
    cursor: pointer;
  }
`;

export const Image = styled.img``;

const ChatMessage = ({
  time,
  author,
  message,
  mute,
  children,
  className,
  authorFontSize,
  messageFontSIze,
  timeFontSize,
}: PropsWithChildren<Props>) => {
  return (
    <ChatMessageContainer className={className}>
      <Header>
        <Author fontSize={authorFontSize}>{author}</Author>
        <Time fontSize={timeFontSize}>{time}</Time>
      </Header>
      <BodyWrapper>
        <Body fontSize={messageFontSIze}>{message || children}</Body>
        {mute && (
          <Button onClick={mute}>
            <Image width={20} height={20} src={image} />
          </Button>
        )}
      </BodyWrapper>
    </ChatMessageContainer>
  );
};

export default ChatMessage;
