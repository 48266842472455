import { ContentContainer } from "containers";
import { MainContainerWrapper } from "../Cabinet/components/MainContainer/styledComponents";
import { PageTitle } from "../../shared";
import React, { useEffect, useState } from "react";
import { getBaseCoursesLessonByTopic } from "../../api/CabinetPromoApi";
import { BaseCourseItem } from "./BaseCourseItem";
import styled from "styled-components";
import { useParams } from "react-router";
import Icon from "../../components/Icon";
import { useHistory } from "react-router-dom";

export const BaseCourseView = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    const get = async () => {
      const resp = await getBaseCoursesLessonByTopic(params.id);
      setList(resp.data.items);
    };

    if (params.id) {
      get();
    }
  }, [params.id]);

  return (
    <ContentContainer>
      <MainContainerWrapper>
        <Row>
          <Button>
            <LeftIcon
              icon="ArrowLeftIcon"
              size="m"
              color="#545454"
              onClick={() => history.goBack()}
            />
          </Button>

          <PageTitle>{list[0]?.topic.name}</PageTitle>
        </Row>

        <List>
          {list.map((item: any, index) => (
            <BaseCourseItem key={item.id} item={item} index={index} />
          ))}
        </List>
        {list.length === 0 && <NotFoundText>Лекций еще нет</NotFoundText>}
      </MainContainerWrapper>
    </ContentContainer>
  );
};

const Button = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const LeftIcon = styled(Icon)``;

const List = styled.div`
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 220px;
  margin-top: 2em;

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const NotFoundText = styled.p`
  color: #000;
  text-align: center;
`;
