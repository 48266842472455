import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { getUserRole } from "selectors/userInfo";

type Props = {
  skipRoles: string | Array<string>;
};

const DefenseRole = ({ skipRoles, children }: PropsWithChildren<Props>) => {
  const userRole = useSelector(getUserRole);

  //@ts-ignore
  const skipRolesArray: Array<string> = [].concat(skipRoles);

  if (skipRolesArray.includes(userRole)) return <>{children}</>;

  return null;
};

export default DefenseRole;
