import {
  addLectureWidgetChatMessage,
  deleteChatUserMessage,
  setLectureWidgetChatMessages,
} from "layout/OnlineWebinarLectureWidget/redux";
import { TGetState, TThunkDispatch } from "store";
import centrifuge from "./Centrifuge";
import { IChatMessage } from "api/models/chat";

let subscribtion: any = null;

export const subscribeToLectureChatChannel =
  (webinarChannel: string) => (dispatch: TThunkDispatch) => {
    subscribtion = centrifuge.subscribe(webinarChannel, (res) => {
      if (res?.data?.message) {
        dispatch(addLectureWidgetChatMessage(res.data.message));
      }
      if (res?.data?.operation === "chat/delete") {
        dispatch(
          deleteChatUserMessage({
            message_id: res.data.message_id,
          })
        );
      }
    });
  };

export const unsubscribeChat = () => {
  if (subscribtion !== null) {
    subscribtion.unsubscribe();
    subscribtion.removeAllListeners();
  }
};

export const postMessageToLectureChat =
  (message: string) => (dispatch: TThunkDispatch, getState: TGetState) => {
    const state = getState();
    if (state.cabinet.lecturePromo?.id) {
      const chatChannel = "chat" + state.cabinet.lecturePromo.id;
      const data = {
        method: "chat/send",
        data: { channel: chatChannel, message: message },
      };
      centrifuge.rpc(data).then(function (res) {
        console.log("res", res);
      });
    }
  };

export const getLectureChatMessages = () => (dispatch: TThunkDispatch) => {
  centrifuge.rpc({ method: "chat/get-all", data: {} }).then(function (res) {
    const messages: IChatMessage[] = res.data.messages;
    dispatch(setLectureWidgetChatMessages(messages));
  });
};
