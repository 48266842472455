import styled from "styled-components";

type Props = {
  direction?: "row" | "column";
  adaptive?: boolean;
};

const ContentContainer = styled.div<Props>`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  padding-left: 32px;
  padding-right: 32px;

  ${(props) =>
    props.adaptive &&
    `
    max-width: 1400px;
    margin: 0 auto;
  `}

  @media all and (min-width : 320px) and (max-width: 500px) {
    padding: 0 8px;
  }
`;

export default ContentContainer;
