import styled from "styled-components";

export const Container = styled.div<{ isOpen?: boolean }>`
  background-color: ${(props) => props.theme.colors.primaryWhite};
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 32px;
  position: fixed;
  bottom: 0;
  right: 32px;
  z-index: 1;

  & .react-resizable-handle {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;
