import { useDispatch, useSelector } from "react-redux";

import { Header } from "layout/ModalWindow/layout";
import { closeModal } from "layout/ModalWindow/redux";
import {
  Container,
  Subtitle,
  ButtonWrapper,
  CancelButton,
  ConfirmButton,
} from "./styledComponents";
import { getLecturePromoUsers } from "../../../../pages";
import { deleteMessage, muteUser } from "../../../../api/TeacherWebinarChatAPI";

type Props = {
  fullName: string;
  username: string;
  messageId: number;
};

const UserMuteConfirmModal = ({ fullName, username, messageId }: Props) => {
  const dispatch = useDispatch();
  const lecturePromoUsers = useSelector(getLecturePromoUsers);
  const userId = lecturePromoUsers.reduce((acc, item) => {
    if (item.email === username) {
      acc = item.id;
    }
    return acc;
  }, -1);

  const handleClose = () => {
    dispatch(closeModal("UserMuteConfirmModal"));
  };

  const handleMute = () => {
    dispatch(muteUser(userId, username));
    dispatch(closeModal("UserMuteConfirmModal"));
  };

  const handleDelete = () => {
    dispatch(deleteMessage(messageId));
    dispatch(closeModal("UserMuteConfirmModal"));
  };

  return (
    <Container>
      <Header
        onClose={handleClose}
        title={"Модерация"}
        font={"heading6FontSize"}
      />
      <Subtitle>
        {fullName} ({username}) нарушает правила вебинара?
      </Subtitle>
      <ButtonWrapper>
        <CancelButton onClick={handleDelete}>Удалить сообщение</CancelButton>
        <ConfirmButton onClick={handleMute}>
          Заблокировать пользователя
        </ConfirmButton>
      </ButtonWrapper>
    </Container>
  );
};

export default UserMuteConfirmModal;
