import styled from "styled-components";

export const Container = styled.div`
  padding: 14px 24px;
  font-size: ${(props) => props.theme.fontSizes.subtitle1FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.superSkinnyFontWeight};
  line-height: 27px;
  border: 0.5px solid ${(props) => props.theme.colors.secondaryLightGray};
  border-radius: 12px;
  cursor: pointer;
  background-color: ${(props: { status: "green" | "red" | null }) =>
    props.status === "green"
      ? //@ts-ignore
        props.theme.colors.opacityGreen20
      : props.status === "red"
      ? "rgba(252, 94, 94, 0.2)"
      : ""};
`;
