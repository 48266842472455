import styled from "styled-components";

import Button from "./CommonButton";

const PrimaryButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.primaryGreen};
  color: ${(props) => props.theme.colors.primaryWhite};
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  padding: 12px;
  border-radius: 8px;

  &:hover {
    background: ${(props) => props.theme.colors.primaryGreenHover};
  }

  &:active {
    background: ${(props) => props.theme.colors.primaryGreenPressed};
  }

  &:disabled {
    background: ${(props) => props.theme.colors.primaryBlack};
    cursor: not-allowed;
  }
`;

export default PrimaryButton;
