import { createSlice } from "@reduxjs/toolkit";

const clientOptionsReducer = createSlice({
  name: "clientOptionsReducer",
  initialState: { width: window.innerWidth },
  reducers: {
    setClientWidth: (state, action) => {
      return { ...state, width: action.payload };
    },
  },
});

export const { setClientWidth } = clientOptionsReducer.actions;

export default clientOptionsReducer.reducer;
