import { PaperContainer } from "containers";
import styled from "styled-components";

export const Container = styled(PaperContainer)`
  border-radius: 20px;
  margin-top: 40px;
  background-color: #ffffff;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: 0 4px 20px rgb(202 203 220 / 25%);

  @media (max-width: 1163px) {
    margin-bottom: 0;
  }
`;

export const FormTitle = styled.p`
  font-size: 16px;
  color: #1f2021;
  font-weight: 600;
`;

export const InputContainer = styled.div`
  display: block;
  margin-top: 9px;
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  border-radius: 10px;
  height: 44px;
  background-color: rgba(218, 221, 228, 0.2);
  padding: 0 16px;
  box-sizing: border-box;
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.secondaryRed};
`;

export const ErrorContainer = styled.div`
  height: 15px;
`;

export const RememberContainer = styled.div`
  text-align: right;
`;

export const ForgotButton = styled.button`
  margin-top: 8px;
  background-color: transparent;
  border: none;
  font-size: 12px;
  color: var(--primary-black);
  cursor: pointer;
`;

export const StyledButton = styled.button`
  margin-top: 32px;
  width: 100%;
  height: 48px;
  border-radius: 40px;
  background-color: #00ab6d;
  border: none;
  font-size: var(--small-text-size);
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #fc5e5ecc;
  }
`;
