import React from "react";
import {
  InfoPreviewImagesContainer,
  InfoPreviewImage,
  InfoPreviewPlayImage,
} from "./styledComponents";
import previewImage from "assets/images/infoVideoPreview.jpg";
import play from "assets/images/play.png";
import { openModal } from "layout";
import { useDispatch } from "react-redux";

interface Props {
  video: string;
}

const InfoPreviewImages = ({ video }: Props) => {
  const dispatch = useDispatch();

  const handlePlayButtonClick = (video: string) => {
    dispatch(openModal({ name: "VideoBoxModal", video }));
  };

  return (
    <InfoPreviewImagesContainer>
      <InfoPreviewImage src={previewImage} />
      <InfoPreviewPlayImage
        src={play}
        onClick={() => handlePlayButtonClick(video)}
      />
    </InfoPreviewImagesContainer>
  );
};

export default InfoPreviewImages;
