import { QuestionResponse } from "api/models/ticket";
import { Immutable } from "immer";
import { UserAnswer } from "pages";
import { memo } from "react";
import { useGetQuestionAnswerStatus } from "../hooks/useGetQuestionAnswerStatus";
import { Item } from "../styledComponents";

const QuestionItem = ({
  item,
  userAnswers,
  index,
}: {
  item: QuestionResponse;
  userAnswers: Immutable<UserAnswer[]>;
  index: number;
}) => {
  const answerStatus = useGetQuestionAnswerStatus(
    item,
    userAnswers.find((a) => a.questionId === item.id)
  );
  return (
    <Item status={answerStatus} key={item.id}>
      {index + 1}
    </Item>
  );
};

export default memo(QuestionItem);
