const LogOutIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 3C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H13.5C13.9142 21 14.25 20.6642 14.25 20.25C14.25 19.8358 13.9142 19.5 13.5 19.5H6C5.17157 19.5 4.5 18.8284 4.5 18V6C4.5 5.17157 5.17157 4.5 6 4.5H13.5C13.9142 4.5 14.25 4.16421 14.25 3.75C14.25 3.33579 13.9142 3 13.5 3H6ZM15.1989 6.99129C15.4799 6.68693 15.9543 6.66795 16.2587 6.9489L20.5367 10.8978C21.1801 11.4917 21.1801 12.5083 20.5367 13.1022L16.2587 17.0511C15.9543 17.3321 15.4799 17.3131 15.1989 17.0087C14.9179 16.7043 14.9369 16.2299 15.2413 15.9489L18.7068 12.75L9.75 12.75C9.33579 12.75 9 12.4142 9 12C9 11.5858 9.33579 11.25 9.75 11.25L18.7068 11.25L15.2413 8.05111C14.9369 7.77015 14.9179 7.29566 15.1989 6.99129Z"
        fill="#1F2021"
      />
    </svg>
  );
};

export default LogOutIcon;
