import { createSlice } from "@reduxjs/toolkit";
import { LessonsRequest } from "api/models/lessons";
import { RootState } from "store";

type LessonsReducer = {
  list: LessonsRequest;
};

const lessonsReducer = createSlice({
  name: "lessons",
  initialState: { list: [] } as LessonsReducer,
  reducers: {
    setLessons: (state, action: { payload: LessonsRequest }) => {
      state.list = action.payload;
    },
  },
});

export const { setLessons } = lessonsReducer.actions;

export const getLessons = (state: RootState) => state.lessons.list;

export default lessonsReducer.reducer;
