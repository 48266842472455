import { DefaultModalContainer } from "containers";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux";
import { Header } from "../../layout";
import image from "assets/images/ConfirmStartWebinarModal.svg";
import { postStartWebinarActionCreator } from "api/WebinarAPI";
import {
  CancelButton,
  Image,
  Title,
  Subtitle,
  Footer,
  AcceptButton,
} from "./styledComponents";
import { getLecturePromo } from "pages";

type Props = {
  name: string;
};

const ConfirmStartWebinarModal = ({ name }: Props) => {
  const dispatch = useDispatch();
  const lecturePromo = useSelector(getLecturePromo);

  const handleCloseModal = () => {
    dispatch(closeModal(name));
  };

  const handleStartWebinar = () => {
    if (lecturePromo) {
      dispatch(postStartWebinarActionCreator(lecturePromo.id));
    }
    dispatch(closeModal(name));
  };

  return (
    <DefaultModalContainer>
      <Header onClose={handleCloseModal} />
      <Image src={image} />
      <Title>Вы уверены, что хотите начать вебинар?</Title>
      <Subtitle>Нажмите на клавишу “Начать”, чтобы запустить вебинар </Subtitle>
      <Footer>
        <CancelButton
          onClick={handleCloseModal}
          size="medium"
          buttonTheme="secondary"
        >
          Отменить
        </CancelButton>
        <AcceptButton
          onClick={handleStartWebinar}
          buttonTheme="primary"
          size="medium"
        >
          Начать
        </AcceptButton>
      </Footer>
    </DefaultModalContainer>
  );
};

export default ConfirmStartWebinarModal;
