import styled from "styled-components";

export const InfoPreviewImagesContainer = styled.div`
  margin: 16px auto 0;
  max-width: 913px;
  width: 100%;
  position: relative;
  box-shadow: 0px 10px 24px rgba(225, 225, 232, 0.55);
`;

export const InfoPreviewImage = styled.img`
  width: 100%;
`;

export const InfoPreviewPlayImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -56px;
  margin-top: -56px;
  transition: transform 0.2s;
  cursor: pointer;
`;
