import { createSlice } from "@reduxjs/toolkit";
import { ChatVoiceNotification } from "../types";

const webinarsReducer = createSlice({
  name: "webinarsReducer",
  initialState: {
    isStarted: false,
    isComing: false,
    webinarName: null,
    load: false,
    id: null,
    chatMessages: new Array(0),
    interactiveTasks: new Array(0),
    teacherQuestions: {
      id: null as null | number,
      answers: [] as any[],
    },
    chatUserVoiceNotifications: [] as ChatVoiceNotification[],
  },
  reducers: {
    setWebinarIsStarted: (state, action) => {
      state.isStarted = action.payload;
    },
    setWebinarIsComing: (state, action) => {
      state.isComing = action.payload;
    },
    setWebinarName: (state, action) => {
      state.webinarName = action.payload;
    },
    setWebinarId: (state, action) => {
      state.id = action.payload;
    },
    setWebinarChatMessages: (state, action) => {
      state.chatMessages = action.payload;
    },
    setWebinarChatMessage: (state, action) => {
      state.chatMessages = [...state.chatMessages, action.payload];
    },
    setInteractiveTasks: (state, action) => {
      state.interactiveTasks = action.payload;
    },
    setTeacherQuestion: (state, action) => {
      state.teacherQuestions = {
        id: action.payload,
        answers: [],
      };
    },
    setTeacherQuestionAnswer: (state, action) => {
      state.teacherQuestions.answers = [
        ...state.teacherQuestions.answers,
        action.payload,
      ];
    },
    removeTeacherQuestion: (state) => {
      state.teacherQuestions = {
        id: null,
        answers: [],
      };
    },
    addChatUserVoiceNotification: (state, action) => {
      state.chatUserVoiceNotifications.push(action.payload);
    },
    setChatUserVoiceNotifications: (state, action) => {
      state.chatUserVoiceNotifications = action.payload;
    },
    deleteChatUserVoiceNotification: (state, action) => {
      state.chatUserVoiceNotifications = [
        ...state.chatUserVoiceNotifications.filter(
          (notification) =>
            !(
              notification.operation === action.payload.operation &&
              notification.id === action.payload.id
            )
        ),
      ];
    },
  },
});

export const {
  setWebinarIsStarted,
  setWebinarName,
  setWebinarId,
  setWebinarChatMessages,
  setWebinarChatMessage,
  setInteractiveTasks,
  setWebinarIsComing,
  setTeacherQuestion,
  setTeacherQuestionAnswer,
  removeTeacherQuestion,
  addChatUserVoiceNotification,
  setChatUserVoiceNotifications,
  deleteChatUserVoiceNotification,
} = webinarsReducer.actions;

export default webinarsReducer.reducer;
