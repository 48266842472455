import {
  setWebinarChatMessage,
  setTeacherQuestionAnswer,
  addChatUserVoiceNotification,
  deleteChatUserVoiceNotification,
  setChatUserVoiceNotifications,
} from "pages/Webinars/redux";
import { TGetState, TThunkDispatch } from "store";
import centrifuge from "./Centrifuge";
import { TChatMessages } from "./models/chat";
import {
  setLectureWidgetChatMessages,
  deleteChatUserMessages,
} from "layout/OnlineWebinarLectureWidget/redux";
import { ChatVoiceNotification, OPERATION } from "pages/Webinars/types";
import { setNotification } from "layout";

export const subscribeToWebinarChatChannel =
  (webinarChannel: string) => (dispatch: TThunkDispatch) => {
    centrifuge.subscribe(
      webinarChannel,
      function (res: { data: { message: TChatMessages } }) {
        dispatch(setWebinarChatMessage(res.data.message));
      }
    );
  };

export const subscribeTeacherChannel =
  (webinarChannel: string) =>
  (dispatch: TThunkDispatch, getState: TGetState) => {
    const { userInfo } = getState();
    if (userInfo.role === "teacher") {
      centrifuge.subscribe(webinarChannel, (res) => {
        if (res.data.operation === "task/solve") {
          dispatch(
            setTeacherQuestionAnswer({
              taskId: res.data.task_id,
              userId: res.data.user_id,
              correct: res.data.correct,
              answer: res.data.answer,
            })
          );
        }
        if (
          res?.data?.operation === OPERATION.NOTICE ||
          res?.data?.operation === OPERATION.VOICE
        ) {
          dispatch(
            addChatUserVoiceNotification({ ...res.data, id: res.offset })
          );
        }
      });
    }
  };

export const fetchWebinarChatMessages = () => (dispatch: TThunkDispatch) => {
  centrifuge.rpc({ method: "chat/get-all", data: {} }).then(function (res) {
    const messages: TChatMessages = res.data.messages;
    dispatch(setLectureWidgetChatMessages(messages));
  });
};

export const allowVoiceAnswer =
  ({
    user_id,
    operation,
    id,
  }: {
    user_id: number;
    operation: string;
    id: number;
  }) =>
  (dispatch: TThunkDispatch) => {
    centrifuge
      .rpc({ method: "chat/accept", data: { user_id: user_id } })
      .then(() => {
        // delete prev chat-notice
        dispatch(
          deleteChatUserVoiceNotification({
            operation,
            id,
          })
        );
      });
  };

export const rejectVoiceAnswer =
  ({
    user_id,
    operation,
    id,
  }: {
    user_id: number;
    operation: string;
    id: number;
  }) =>
  (dispatch: TThunkDispatch) => {
    centrifuge
      .rpc({
        method: "chat/cancel-notice",
        data: { user_id: user_id },
      })
      .then(() => {
        // delete prev chat-notice
        dispatch(
          deleteChatUserVoiceNotification({
            operation,
            id,
          })
        );
      });
  };

export const muteUser =
  (user_id: number, username: string) => (dispatch: TThunkDispatch) => {
    centrifuge
      .rpc({
        method: "chat/mute",
        data: { id: user_id },
      })
      .then(() => {
        dispatch(
          deleteChatUserMessages({
            username: username,
          })
        );
      })
      .catch((err) => {
        dispatch(
          setNotification({
            title: "Ошибка",
            message: `error code: ${err.code}, message: ${err.message}`,
            status: "error",
          })
        );
      });
  };

export const deleteMessage =
  (message_id: number) => (dispatch: TThunkDispatch) => {
    centrifuge
      .rpc({
        method: "chat/delete",
        data: { id: message_id },
      })
      .then(() => {})
      .catch((err) => {
        dispatch(
          setNotification({
            title: "Ошибка",
            message: `error code: ${err.code}, message: ${err.message}`,
            status: "error",
          })
        );
      });
  };

export const fetchWebinarVoiceMessages = () => (dispatch: TThunkDispatch) => {
  centrifuge
    .rpc({ method: "chat/get-all-by-type", data: { type: 3 } })
    .then(function (res) {
      const messages: TChatMessages = res.data.messages;
      const data = messages.map((message) => {
        return {
          id: message.id,
          user: {
            id: message.user_id,
            lastname: message.lastname,
            firstname: message.firstname,
            middlename: message.middlename,
            url: message.data?.url,
          },
          operation: OPERATION.VOICE,
        } as ChatVoiceNotification;
      });
      dispatch(setChatUserVoiceNotifications(data));
    });
};
