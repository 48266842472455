const Watched = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#3CB758" />
      <path
        d="M8.41174 15.6094C8.99753 15.0237 9.94728 15.0237 10.5331 15.6094L14.759 19.8354L22.9895 11.6049C23.5753 11.0191 24.5251 11.0191 25.1108 11.6049C25.6966 12.1907 25.6966 13.1404 25.1108 13.7262L16.2439 22.5931C15.4238 23.4132 14.0942 23.4132 13.2741 22.5931L8.41174 17.7308C7.82596 17.145 7.82596 16.1952 8.41174 15.6094Z"
        fill="white"
      />
    </svg>
  );
};

export default Watched;
