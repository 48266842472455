import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";

import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/ru";

import { ConnectedRouter } from "connected-react-router";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import store, { history } from "store";

import "./index.css";
import "./react-resizable.css";
import { ThemeProvider } from "styled-components";
import theme from "constants/theme";
import { PingOnlineStatusProvider } from "./providers/PingOnlineStatusProvider";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConnectedRouter history={history as any}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            libInstance={moment}
            locale={"ru"}
          >
            <PingOnlineStatusProvider>
              <App />
            </PingOnlineStatusProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
