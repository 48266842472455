import React, { useState } from "react";
import styled from "styled-components";
import { Video } from "./components/Video";
import { useParams } from "react-router";
import { useEffect } from "react";
import { getBaseCourseLessonById } from "../../api/CabinetPromoApi";

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.secondaryGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 88px);
`;

const NotFoundText = styled.p`
  color: #fff;
`;

export const BaseCourseVideo: React.FC = () => {
  const params = useParams<{ id: string }>();
  const [video, setVideo] = useState<null | any>(null);

  useEffect(() => {
    const get = async () => {
      const resp = await getBaseCourseLessonById(params.id);
      setVideo(resp.data);
    };

    if (params.id) {
      get();
    }
  }, [params.id]);

  return (
    <Container>
      {video ? (
        <Video videoData={video} url={video.video} info={video} />
      ) : (
        <NotFoundText>Видео не найденно</NotFoundText>
      )}
    </Container>
  );
};
