import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";
import { PropsWithChildren } from "react";

type Props = {
  open: boolean;
  onClose?: () => void;
  isMobile?: boolean;
};

const SidebarContainer = styled(Drawer)`
  width: ${(props: Props) => (props.open ? "280px" : "0px")};
  height: ${(props: Props) => (props.open ? "auto" : "0px")};
  transform: ${(props: Props) => (props.open ? "none" : "translate(-280px)")};
  transition: 0.25s;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  box-shadow: 3px 4px 42px ${(props) => props.theme.colors.shadowSideMenu};
  z-index: 999;

  & .MuiDrawer-paper {
    width: 100%;
    min-height: ${(props: Props) => (props.open ? "100vh" : "0px")};
    background-color: inherit;
    border: none;
    position: relative;
    overflow-x: auto;
  }
`;

const Sidebar = ({
  children,
  open,
  onClose = () => {},
  isMobile,
}: PropsWithChildren<Props>) => (
  <SidebarContainer
    open={open}
    onClose={onClose}
    variant={isMobile ? "temporary" : "persistent"}
  >
    {children}
  </SidebarContainer>
);

export default Sidebar;
