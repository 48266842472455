import styled from "styled-components";

const CustomScrollContainer = styled.div`
  overflow: auto;

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-thumb {
    max-height: 50px;
    border-radius: 8px;
    background: #6a7d9b;
  }
`;

export default CustomScrollContainer;
