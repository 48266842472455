import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BASE_COURSES } from "../../constants/paths";
import { Tooltip } from "@material-ui/core";
import { Icon } from "../../components";
import PlayMin from "../../icons/PlayMin";

const icons: any = {
  blocked: "Block",
  watched: "Watched",
  waiting: "Waiting",
};

export const BaseCourseItem: React.FC<{ item: any; index: number }> = ({
  item,
  index,
}) => {
  return (
    <Container
      to={
        item?.topic
          ? `${BASE_COURSES}/lesson/${item.id}`
          : `${BASE_COURSES}/${item.id}`
      }
      disabled={item.status === "blocked"}
    >
      <Image src={item.image} isWatched={item.status === "blocked"} />

      <PositionIcon icon={icons[item.status]} size="l" />

      <Row>
        <Desc>
          {item?.topic ? "Занятие" : "Лекция"} {index + 1}
        </Desc>
        {item?.topic ? (
          <Info style={{ letterSpacing: 0 }}>
            <MinIcon icon="Clock" size="l" /> {item.duration}
          </Info>
        ) : (
          <Info style={{ letterSpacing: 3 }}>
            <MinIcon icon="PlayMin" size="l" />
            {item.watched}/{item.count}
          </Info>
        )}
      </Row>
      <Tooltip title={item.name} placement="top-start" arrow>
        <Title>
          {item.name.length > 90 ? item.name.substr(0, 90) + "..." : item.name}
        </Title>
      </Tooltip>
    </Container>
  );
};

const MinIcon = styled(Icon)`
  width: 22px;
  height: 22px;
`;

const PositionIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

const Row = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  margin-top: 10px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Desc = styled.div``;

const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  margin-top: 10px;
  margin-left: 5px;
`;

const Image = styled.div<{ src: string; isWatched: boolean }>`
  background-image: ${({ src }) =>
    src === "" ? "rgba(218, 221, 228, 1)" : `url(${src})`};
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  border-radius: 16px;

  filter: grayscale(${({ isWatched }) => (isWatched ? "100%" : "0%")});
`;

const Container = styled(Link)<{ disabled: boolean }>`
  display: block;
  color: #000;
  text-decoration: none;
  position: relative;

  ${({ disabled }) =>
    disabled
      ? `
    pointer-events: none;
  cursor: not-allowed;
  `
      : ""}
`;
