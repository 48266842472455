import styled from 'styled-components';
import vector from  './assets/images/Vector.svg'
import subMenu1 from './assets/images/subMenu1.svg';
import subMenu2 from './assets/images/subMenu2.svg';
import subMenu3 from './assets/images/subMenu3.png';
export default function AboutComponent ({primary, ExtraTitle}) {
    const HorizontalWrapper = styled.div`
    display: flex;
    gap: 20px;
      @media (max-width: 768px){
  flex-direction: column;
  gap: 24px!important;
  }
    `;
    const Title = styled(ExtraTitle)`
font-size: 50px;
line-height: 55px;
font-weight: 600;
color: ${primary};
margin-bottom: 20px;

`;
const Text = styled.p`
font-size: 18px;
margin-bottom: 30px;
font-weight: 400;
line-height: 28px;
color: ${primary};
@media (max-width: 768px){
font-size: 14px;
line-height: 20px;
}
`;
const TitleImage = styled.div`
background: url(${vector});
width: 152px;
display: inline-block;
transform: translateY(12px);
margin-right: 4px;
height: 68px;
background-size: cover;
  @media (max-width: 768px){
  width: 97px;
  height: 43px;
  }
`;
const Container = styled.div`
margin-bottom: 70px!important;
max-width: calc(100vw - 20px);
width: 100%;
margin-inline: auto;
@media (max-width: 768px){
margin-bottom: 30px;

}
`
const LicenseItem = styled.div`
display: flex;
flex-direction: column;
gap: 12px;

`
const LicenseItemLink = styled.a`
font-size: 16px;
    font-weight: 500;
   text-decoration: underline!important;
   color: #232323;

`
const LicenseImage = styled.div`
background: url(${props=>props.src});
border-radius: 24px;

border: 1px solid ${primary};
width: ${props=>props.width}px;
height: 128px;
background-repeat: no-repeat;
background-position: center;
background-size: ${props=>props.width-40}px 88px;
  @media (max-width: 768px){
  background-size: 80%;
 width: 100% }
`
    return (
<>
<Container>
<Title><TitleImage/> это официальное обучение для изучения теории ПДД в автошколе </Title>
<Text>ООО «ПДД.ТВ» создано в 2010 году и преобразовано в ПАО «Светофор Групп» в 2017 году. ПАО "Светофор Групп" ведет разработку по направлениям: дистанционное обучение, виртуальная реальность (VR) и искусственный интеллект (ИИ). "Светофор Групп" аккредитована как IT-компания и является резидентом Фонда "Сколково". Продукты компании рекомендованы Агентством Инноваций Москвы. Акции компании торгуются на ММВБ под тикером SVET, а кредитный рейтинг компании ruBB+.</Text>
<HorizontalWrapper>
<LicenseItem>
    <LicenseImage src={subMenu1} width={359}/>
    <LicenseItemLink as="a" href="https://svetoforgroup.ru/img/content/svetofor-lic.pdf">
    Смотреть лицензию
    </LicenseItemLink>

</LicenseItem>
<LicenseItem>
    <LicenseImage src={subMenu2} width={355} >

    </LicenseImage>
    <LicenseItemLink as="a" href="https://navigator.sk.ru/orn/1120952">
    Смотреть свидетельство
    </LicenseItemLink>

</LicenseItem>
<LicenseItem>
    <LicenseImage src={subMenu3} width={391}  >

    </LicenseImage>
    <LicenseItemLink as="a" href="https://svetoforgroup.ru/img/content/AI_Moscow.jpg">
    Смотреть сертификат
    </LicenseItemLink>

</LicenseItem>
</HorizontalWrapper>
</Container>
</>
    )
}
