const SelectArrowIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.927 5.59786C12.5855 5.25179 12.027 5.25179 11.6855 5.59786L8.67257 8.65153C8.4573 8.86971 8.1052 8.86971 7.88993 8.65153L4.87697 5.59786C4.53551 5.25179 3.97699 5.25179 3.63553 5.59786C3.30049 5.93742 3.30049 6.48318 3.63553 6.82275L7.3865 10.6244C7.8787 11.1233 8.6838 11.1233 9.176 10.6244L12.927 6.82275C13.262 6.48318 13.262 5.93742 12.927 5.59786Z"
      fill="#1F2021"
    />
  </svg>
);

export default SelectArrowIcon;
