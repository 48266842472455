import React, { PropsWithChildren } from "react";
import Icon from "components/Icon";
import styled from "styled-components";
import { ButtonSize } from "./types";

const StyledButton = styled.button<Props>`
  background: none;
  border: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => {
    if (props.size === "large") {
      return "64px";
    }

    if (props.size === "small") {
      return "22px";
    }

    return "40px";
  }};
`;

type Props = {
  onClick?: (event: React.SyntheticEvent) => void;
  className?: string;
  leftIcon?: string;
  leftIconColor?: string;
  leftIconSize?: "s" | "m" | "l" | "xl";
  size?: ButtonSize;
  disabled?: boolean;
};

const LeftIcon = styled(Icon)`
  margin-right: 16px;
`;

const Button = ({
  children,
  onClick,
  className,
  leftIcon,
  leftIconColor,
  size = "medium",
  disabled,
}: PropsWithChildren<Props>) => {
  return (
    <StyledButton
      className={className}
      onClick={onClick}
      size={size}
      disabled={disabled}
    >
      {leftIcon && <LeftIcon icon={leftIcon} size="m" color={leftIconColor} />}
      {children}
    </StyledButton>
  );
};

export default Button;
