import { USER_ROLE } from "constants/role";
import RedirectForRole from "containers/RedirectForRole";
import { PropsWithChildren } from "react";

const RedirectForUser = ({ children }: PropsWithChildren<any>) => {
  return (
    <RedirectForRole rolesForRedirect={USER_ROLE} to="/cabinet">
      {children}
    </RedirectForRole>
  );
};

export default RedirectForUser;
