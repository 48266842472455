import styled from "styled-components";

export const Title = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  margin-bottom: 10px;
`;

export const Value = styled.div<{ color?: string }>`
  font-size: 22px;
  color: ${(props) => props.color ?? props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.boldFontWeight};
  font-family: ${(props) => props.theme.fonts.mainBoldFont};
`;
