import { Button } from "components";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 12px 16px;

  &:nth-child(2n) {
    background-color: ${(props) => props.theme.colors.opacityLightGrey10};
  }
`;

export const Avatar = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.body1FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};

  span {
    font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  }
`;

export const ControlPanel = styled.div`
  margin-top: 12px;
  display: flex;
`;

export const HistoryButton = styled(Button)`
  width: 100px;
  margin-right: 8px;
  padding: 0;
  font-size: ${(props) => props.theme.fontSizes.captionFontSize};
  border-radius: 8px;
`;

export const CancelButton = styled(Button)`
  width: 75px;
  padding: 0;
  margin-right: 8px;
  font-size: ${(props) => props.theme.fontSizes.captionFontSize};
  border-radius: 8px;
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const AcceptButton = styled(Button)`
  width: 75px;
  margin-right: 8px;
  font-size: ${(props) => props.theme.fontSizes.captionFontSize};
  padding: 0;
  border-radius: 8px;
`;
