import { useDispatch } from "react-redux";
import { ButtonTheme } from "components/Button/types";
import { Button } from "components";
import { Header } from "layout/ModalWindow/layout";
import {
  Container,
  Title,
  Text,
  WrapperPicture,
  WrapperButtons,
} from "./styled-components";
import { ReactElement, useCallback } from "react";
import { closeModal } from "layout";

export type AskModalProps = {
  name: string;
  imageSvg?: () => ReactElement;
  title?: string;
  text?: string;
  buttons: AstModalButton[];
};

type AstModalButton = {
  text: string;
  type?: ButtonTheme;
  onClick: () => void;
};

const AskModal = ({ imageSvg, title, text, buttons }: AskModalProps) => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(closeModal("AskModal"));
  }, [dispatch]);

  return (
    <Container>
      <Header onClose={handleClose} />
      {imageSvg && <WrapperPicture>{imageSvg()}</WrapperPicture>}
      {title && <Title>{title}</Title>}
      {text && <Text>{text}</Text>}
      <WrapperButtons>
        {buttons.map((item, index) => (
          <Button key={index} buttonTheme={item.type} onClick={item.onClick}>
            {item.text}
          </Button>
        ))}
      </WrapperButtons>
    </Container>
  );
};

export default AskModal;
