const PsychoTestIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      d="M12.017 4.7605H17.7713C18.5997 4.7605 19.2713 5.4319 19.2713 6.26033C19.2713 6.99567 19.2713 7.8451 19.2713 8.42307C17.3804 7.94724 15.3327 8.84347 15.3327 11.0791C15.3327 13.3146 17.9104 13.97 19.2713 13.4881C19.2713 14.1436 19.2713 15.3397 19.2713 16.3072C19.2713 17.1356 18.6 17.8068 17.7716 17.8068C16.8158 17.8068 15.5902 17.8068 14.6789 17.8068C15.2763 19.4364 14.5476 21.7785 12.063 21.7785C9.57841 21.7785 8.70859 19.4672 9.41308 17.8068C8.55078 17.8068 7.26374 17.8068 6.26211 17.8068C5.43368 17.8068 4.7627 17.1353 4.7627 16.3068L4.7627 6.2605C4.7627 5.43207 5.43427 4.7605 6.2627 4.7605H7.66583"
      stroke="#62686B"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default PsychoTestIcon;
