import styled from "styled-components";

export const Title = styled.div`
  font-family: ${(props) => props.theme.fonts.mainBoldFont};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.heading3FontSize};
`;

export const Container = styled.div`
  margin-top: 16px;
  width: 100%;
`;
