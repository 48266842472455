import styled from "styled-components";
import { CustomScrollContainer, DefaultModalContainer } from "containers";
import { TableCell, TableRow, Tabs } from "components";
import { Box } from "@material-ui/core";

export const Container = styled(DefaultModalContainer)`
  width: 840px;
  height: 720px;
`;

export const StyledTabs = styled(Tabs)`
  width: 549px;
  margin-top: 24px;
`;

export const TabsPanelContainer = styled.div`
  border: 0.5px solid ${(props) => props.theme.colors.secondaryLightGray};
  border-radius: 0 16px 16px 16px;
  height: calc(100% - 120px);
`;

export const AnswerTitle = styled.div`
  font-size: ${(props) => props.theme.fontSizes.heading5FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  padding: 24px 18px 0;
`;

export const AnswerTab = styled(CustomScrollContainer)`
  margin-right: 8px;
  height: 100%;
`;

export const StalledTableCell = styled(TableCell)`
  border-bottom: none !important;
  height: 56px;
  box-sizing: border-box;
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-child(2n) {
    background-color: ${(props) => props.theme.colors.opacityLightGrey10};
  }
`;

export const StyledCounter = styled.p<{ color: string }>`
  color: ${(props) => props.color};
`;

export const CommonTableCell = styled(StalledTableCell)`
  font-size: ${(props) => props.theme.fontSizes.body1FontSize} !important;
  font-weight: ${(props) =>
    props.theme.fontWeights.normalFontWeight} !important;
  color: ${(props) => props.theme.colors.primaryBlack} !important;
`;

export const ResultTableCell = styled(CommonTableCell)<{ correct: boolean }>`
  color: ${(props) =>
    props.correct
      ? props.theme.colors.primaryGreen
      : props.theme.colors.secondaryRed} !important;
`;

export const HeaderTableCell = styled(StalledTableCell)`
  font-size: ${(props) => props.theme.fontSizes.captionFontSize} !important;
  font-weight: ${(props) =>
    props.theme.fontWeights.skinnyFontWeight} !important;
  color: ${(props) => props.theme.colors.secondaryGrey} !important;
`;

export const CounterContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const StyledScrollContainer = styled(CustomScrollContainer)`
  border-radius: 0 16px 16px 16px;
  padding: 16px;
  height: 566px;
`;

export const ListContainer = styled(Box)`
  width: 35%;
`;

export const SelectedTaskContainer = styled(Box)`
  width: 65%;
`;
