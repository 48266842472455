export const ONLINE_LECTURE_PATH = "/online-lecture";
export const WEBINARS_PATH = "/webinars";
export const LOGIN_PATH = "/login/:id?";
export const DOCUMENTS_PATH = "/documents";
export const SERVICES_AND_PAYMENTS_PATH = "/services-and-payments";
export const LECTURE_ARCHIVE_PATH = "/lecture-archive";
export const LECTURE_VIDEO_PATH = "/lecture-archive/:id";
export const TESTS = "/tests";
export const START_EXAM = "/start_exam";
export const WORKOUT = "/workout";
export const TICKETS_THEMES = "/tickets_themes";
export const EXAM = "/exam";
export const BASE_COURSES = "/base_courses";
export const BASE_COURSES_VIEW = "/base_courses/:id";
export const BASE_COURSES_LESSON = "/base_courses/lesson/:id";

export const toLectureVideoPath = (id: number) => {
  return `${LECTURE_ARCHIVE_PATH}/${id}`;
};
