import { QuestionText, Container, Image, Video } from "./styledComponents";

type Props = {
  imageUrl?: string;
  videoUrl?: string;
  question?: string;
};

const TaskQuestion = ({ imageUrl, videoUrl, question }: Props) => {
  const renderPreview = () => {
    if (imageUrl) {
      return <Image src={imageUrl} />;
    }

    if (videoUrl) {
      return <Video src={videoUrl} autoPlay controls />;
    }

    return null;
  };
  return (
    <Container>
      {renderPreview()}
      <QuestionText>{question}</QuestionText>
    </Container>
  );
};

export default TaskQuestion;
