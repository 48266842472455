import { RootState } from "store";
import { createSelector } from "reselect";

export const getModals = (state: RootState) => state.modals;

const getModalName = (state: RootState, name: string) => name;

export const getModalState = createSelector(
  [getModals, getModalName],
  (items, name) => items.find((item) => item.name === name)
);
