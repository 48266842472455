import { Button } from "components";
import styled from "styled-components";

export const WebinarHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: 3px 4px 42px rgba(225, 225, 232, 0.4);
  height: 88px;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
`;

export const TaskListButton = styled(Button)`
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize} !important;
  width: 168px !important;
`;

export const CompleteWebinarButton = styled(Button)`
  padding: 12px;
  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize} !important;
  margin-left: 24px;
  width: 168px !important;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
