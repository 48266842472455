import { ContentContainer } from "containers";
import { MainContainerWrapper } from "../Cabinet/components/MainContainer/styledComponents";
import { PageTitle } from "../../shared";
import React, { useEffect, useState } from "react";
import {
  getBaseCoursesTopics,
  getBaseCourseProgress,
} from "../../api/CabinetPromoApi";
import { BaseCourseItem } from "./BaseCourseItem";
import styled from "styled-components";
import { Button, Loader } from "../../components";
import { useHistory } from "react-router";

export const BaseCourses = () => {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [load, setLoad] = useState(true);
  const [progress, setProgress] = useState({
    all: 0,
    done: 0,
    id: 0,
  });

  useEffect(() => {
    const get = async () => {
      try {
        setLoad(true);
        const resp = await getBaseCoursesTopics();
        const respProgress = await getBaseCourseProgress();
        setList(resp.data.items);
        setProgress(respProgress.data);
        setLoad(false);
      } catch {
        setLoad(false);
      }
    };

    get();
  }, []);

  const goCurrentLecture = () => {
    history.push(`/base_courses/lesson/${progress.id}`);
  };

  return (
    <ContentContainer>
      <MainContainerWrapper>
        <Head>
          <PageTitle>Базовый курс</PageTitle>

          {progress?.id !== 0 && (
            <Button onClick={goCurrentLecture}>
              {progress.id === 0 ? "Начать обучение" : "Продолжить обучение"}
            </Button>
          )}
        </Head>

        <InfoProgress>
          Прогресс прохождения курса: {progress.done} из {progress.all} лекций
        </InfoProgress>
        <Line>
          <Success
            style={{ width: `${(progress.done / progress.all) * 100}%` }}
          />
        </Line>

        <List>
          {list.map((item: any, index) => (
            <BaseCourseItem key={item.id} item={item} index={index} />
          ))}
        </List>
        {load && <Loader />}
      </MainContainerWrapper>
    </ContentContainer>
  );
};

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoProgress = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  margin: 1em 0;
  color: rgba(31, 32, 33, 1);
`;

const Line = styled.div`
  display: flex;
  height: 16px;
  margin: 16px 0;
  width: 100%;
  overflow: hidden;
  background: rgba(218, 221, 228, 0.6);
  border-radius: 16px;
`;

const Success = styled.div`
  background: #87e09b;
`;

const List = styled.div`
  display: grid;
  gap: 86px 25px;
  grid-template-columns: repeat(auto-fill, 310px);
  margin-top: 10px;
  grid-auto-rows: minmax(220px, 10vmax);

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
