import styled from "styled-components";
import { Icon } from "../../../../../components";

export const Overlay = styled.div`
  padding: 0 20px;
  width: calc(100vw - 40px);
  height: 100vh;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: ${(props) => props.theme.colors.opacityLightGrey20};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Container = styled.div`
  max-width: 1140px;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  border-radius: 20px;
`;

export const Video = styled.video`
  width: 100%;
  border-radius: 20px;
`;

export const CloseButton = styled(Icon)`
  transform: scale(1.5);
  position: absolute;
  right: -8px;
  top: 15px;
  cursor: pointer;
  fill: #000;
`;
