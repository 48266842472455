import { ProgressCircle } from "components";
import { LabelContainer } from "containers";
import styled from "styled-components";

interface StatusProps {
  status: number;
}

export const Label = styled(LabelContainer)<StatusProps>`
  color: ${(props) =>
    props.status === 3
      ? props.theme.colors.primaryGreen
      : props.theme.colors.secondaryYellow};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  height: 32px;
  padding: 0 8px;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  background: ${(props) =>
    props.status === 3
      ? props.theme.colors.opacityGreen10
      : props.theme.colors.opacityYellow10};
  border: 1px solid
    ${(props) =>
      props.status === 3
        ? props.theme.colors.primaryGreen
        : props.theme.colors.secondaryYellow};
  box-sizing: border-box;
  border-radius: 18px;
`;

export const Progress = styled(ProgressCircle)`
  width: 40px;
  height: 40px;
  display: block;
  margin: 0 auto;
`;

export const DateBlock = styled.div`
  font-size: 14px;
`;

export const Status = styled.div`
  font-size: ${(props) => props.theme.fontSizes.captionFontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

// props.theme.colors.primaryGreen
//opacityGreen20
// secondaryYellow
//opacityYellow20

export const Theme = styled.div`
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  max-width: 600px;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.primaryBlack};
  font-size: ${(props) => props.theme.fontSizes.heading4FontSize};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};

  @media screen and (max-width: 500px) {
    font-size: ${(props) => props.theme.fontSizes.heading5FontSize};
  }
`;
