const BurgerMenuIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C3 6.44772 3.44772 6 4 6H19.4912C20.0435 6 20.4912 6.44772 20.4912 7C20.4912 7.55228 20.0435 8 19.4912 8H4C3.44772 8 3 7.55228 3 7ZM4 11C3.44772 11 3 11.4477 3 12C3 12.5523 3.44772 13 4 13H19.4912C20.0435 13 20.4912 12.5523 20.4912 12C20.4912 11.4477 20.0435 11 19.4912 11H4ZM4 16C3.44772 16 3 16.4477 3 17C3 17.5523 3.44772 18 4 18H19.4912C20.0435 18 20.4912 17.5523 20.4912 17C20.4912 16.4477 20.0435 16 19.4912 16H4Z"
        fill="#1F2021"
      />
    </svg>
  );
};

export default BurgerMenuIcon;
