import { makeStyles } from "@material-ui/core/styles";
import MUIPopper, { PopoverProps } from "@material-ui/core/Popover";
import styled from "styled-components";

const PopperContainer = styled(MUIPopper)``;

const useStyles = makeStyles({
  root: {
    background: "none",
    overflow: "visible",
  },
  elevation8: {
    boxShadow: "none",
  },
});

const PopUp = (props: PopoverProps) => {
  const classes = useStyles();
  return (
    <PopperContainer
      {...props}
      PaperProps={{
        classes: { root: classes.root, elevation8: classes.elevation8 },
      }}
    />
  );
};

export default PopUp;
