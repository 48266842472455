import { useEffect, useState } from "react";
import "./ProgressBlock.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
export default function ProgressBlockDynamic({ green }) {
    const titles = ['Приступайте к обучению сразу',
        'Теория в удобном формате',
        'Начинаете уроки вождения',
        'Сдаете экзамены в автошколе',
        'Сдадите экзамен в ГИБДД',
    ]
    const texts = ['Можете сразу учиться, а документы предоставить позже. Мы вам в этом поможем!',
'Без скучных лекций и непонятных картинок. Обучаем в режиме вебинара, как в классе.',
'Выберите инструктора по отзывам, рейтингу и даже району. Практика в удобное для вас время.',
'Теория и практика — звучит сложно, но мы поможем вам достичь результата.',
'Пойдём вместе с вами — и поддержим.95% наших учеников уже справились — теперь ваша очередь'
    ]
    const [isAnimated,setAnimated] = useState(false);
    const [selectedProgress,setSelectedProgress] = useState(0);
const [progress,setProgress] = useState(0);
    useEffect(()=>{
      var int = setInterval(()=>{
            setProgress(progress=>progress+1);
            
        },50);
        return ()=>{
            clearInterval(int);
        }
},[]);
useEffect(()=>{
setAnimated(true);
setTimeout(()=>{
  setAnimated(false);
},1000);
},[selectedProgress])
useEffect(()=>{
    if (progress >= 100){
        setProgress(0);
        if (selectedProgress == 4) setSelectedProgress(0); else setSelectedProgress(selectedProgress+1); 
    }
},[progress]);

  return (
    <>
      <div className="progress-block" style={{ marginBottom: 40,display: "flex", gap: 35, padding: 20 }}>
     {[0,0,0,0,0].map((k,i)=>
 <div onClick={()=>{
    setProgress(0);
    setSelectedProgress(i);
 }} className={selectedProgress == i?"progress-block__item active":"progress-block__item"}>
 <CircularProgressbar
 
   maxValue={92}
   value={selectedProgress == i ? progress : 0}
   text={``}
   background={true}
   strokeWidth={4}
   
 />
 <span>{(i+1)}</span>
</div>
     )}
       
 
     
      </div>
      
      <div className={isAnimated ? 'progress-block__elem animate__animated animate__fadeInRight animate__faster' : 'progress-block__elem'} style={{display: 'flex',marginBottom: 60,gap: 20,flexDirection: 'column',maxWidth: 517}}>

<h5 style={{fontWeight: 600,fontSize: 26, lineHeight: '30px'}}>{titles[selectedProgress]}</h5>
<p style={{fontSize: 18, lineHeight: '28px'}}>{texts[selectedProgress]}</p>
      </div>
    </>
  );
}
