import { DefaultModalContainer } from "containers";
import { Header } from "layout/ModalWindow/layout";
import { closeModal } from "layout/ModalWindow";
import { useDispatch } from "react-redux";
import { Button } from "components";
import { Body, CloseButton } from "./styledComponents";

type Props = {
  name: string;
  onSubmit: () => void;
};

const ActivateMicrophoneErrorModal = ({ name, onSubmit }: Props) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal(name));
  };

  const handleSubmit = () => {
    handleCloseModal();
    onSubmit();
  };

  return (
    <DefaultModalContainer>
      <Header title="Подключите микрофон" />
      Подлючите микрофон и нажмите кнопку повторить для повторной активации
      <Body>
        <CloseButton onClick={handleCloseModal} buttonTheme="error">
          Закрыть
        </CloseButton>
        <Button onClick={handleSubmit} buttonTheme="primary">
          Повторить
        </Button>
      </Body>
    </DefaultModalContainer>
  );
};

export default ActivateMicrophoneErrorModal;
