import {
  BASE_COURSES,
  LECTURE_ARCHIVE_PATH,
  ONLINE_LECTURE_PATH,
} from "constants/paths";
import { USER_WITH_ADMIN } from "constants/role";
import { DefenseRole } from "containers";
import { useLocation } from "react-router-dom";
import ListItem from "../ListItem";
import { LinkContainer } from "./styledComponents";
import { useEffect, useState } from "react";
import { getSchoolInfo } from "../../../../api/UserAPI";
// import { ISchool, validSubdomain } from "../../../../pages/Login/LoginDefault";

export const subdomain = window.location.hostname.split(".")[0];
export const validSubdomain =
    subdomain === "localhost" || subdomain === "frontend"
        ? "svetofor"
        : subdomain;


export const List = () => {
  const { pathname } = useLocation();
  const [school, setSchool] = useState<any | undefined>(undefined);

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await getSchoolInfo(validSubdomain);

        setSchool(resp.data);
      } catch (e) {
        console.log(e);
      }
    };

    getData();
  }, []);
  return (
    <div>
      <DefenseRole skipRoles={USER_WITH_ADMIN}>
        <LinkContainer to="cabinet">
          <ListItem
            active={pathname.includes("cabinet") || pathname === "/"}
            icon="MyAccountIcon"
          >
            Мой кабинет
          </ListItem>
        </LinkContainer>
        {/* <LinkContainer to="info">
          <ListItem icon="GeneralInfoIcon" active={pathname.includes("info")}>
            Общая информация
          </ListItem>
        </LinkContainer> */}
        <LinkContainer to={ONLINE_LECTURE_PATH}>
          <ListItem
            active={pathname.includes(ONLINE_LECTURE_PATH)}
            icon="OnlineLectureIcon"
          >
            Онлайн-лекция
          </ListItem>
        </LinkContainer>
        <LinkContainer to={LECTURE_ARCHIVE_PATH}>
          <ListItem
            icon="VideoArchiveIcon"
            active={pathname.includes(LECTURE_ARCHIVE_PATH)}
          >
            Видеоархив лекций
          </ListItem>
        </LinkContainer>
        {school && school?.use_base_course && (
          <LinkContainer to={BASE_COURSES}>
            <ListItem icon="Courses" active={pathname.includes(BASE_COURSES)}>
              Базовый курс
            </ListItem>
          </LinkContainer>
        )}
        {school && school?.use_billing && (
          <LinkContainer to="/payments">
            <ListItem icon="Wallet" active={pathname.includes("/payments")}>
              Услуги и платежи
            </ListItem>
          </LinkContainer>
        )}
        {/* <LinkContainer to="task">
          <ListItem
            icon="InteractiveTasksIcon"
            active={pathname.includes("task")}
          >
            Интерактив. задания
          </ListItem>
        </LinkContainer> */}
        <LinkContainer to="tests">
          <ListItem
            icon="TestsIcon"
            active={
              pathname.includes("/workout") ||
              pathname.includes("/start_exam") ||
              pathname.includes("/tickets_themes")
            }
          >
            Тесты по билетам
          </ListItem>
        </LinkContainer>
      </DefenseRole>
      {/* <LinkContainer to="psycho-tests">
          <ListItem
            icon="PsychoTestIcon"
            active={pathname.includes("psycho-tests")}
          >
            Психофизиолог. тесты
          </ListItem>
        </LinkContainer>
        <LinkContainer to="services-and-payments">
          <ListItem
            icon="ServicesAndPayIcon"
            active={pathname.includes("services-and-payments")}
          >
            Услуги и платежи
          </ListItem>
        </LinkContainer>
        <LinkContainer to="documents">
          <ListItem
            icon="DocumentsIcon"
            active={pathname.includes("documents")}
          >
            Документы
          </ListItem>
        </LinkContainer>
        <LinkContainer to={GIBDD_PATH}>
          <ListItem icon="DataIcon" active={pathname.includes(GIBDD_PATH)}>
            Данные для ГИБДД
          </ListItem>
        </LinkContainer>
        <LinkContainer to={ONLINE_WEBINAR}>
          <ListItem
            icon="OnlineLectureIcon"
            active={pathname.includes(ONLINE_WEBINAR)}
          >
            Онлайн вебинар
          </ListItem>
        </LinkContainer>
      </DefenseRole>
      <DefenseRole skipRoles={TEACHER_WITH_ADMIN}>
        <LinkContainer to={WEBINARS_PATH}>
          <ListItem
            icon="OnlineLectureIcon"
            active={pathname.includes(WEBINARS_PATH)}
          >
            Вебинары
          </ListItem>
        </LinkContainer>
      </DefenseRole> */}
    </div>
  );
};
