import { getWorkoutList } from "pages/Tests/redux";
import { PropsWithChildren, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { finishWorkout } from "pages";
import type { Workouts } from "pages/Tests/redux/reducer";
import { Immutable } from "immer";
import { closeModal, openModal } from "layout";
import { useHistory, useRouteMatch } from "react-router";
import { EXAM } from "constants/paths";
import { useGetQuery } from "pages/Tests/useQuestionsFromQuery";
import { setActiveWorkoutFinished } from "utils/localStorage.util";

const ContentContainerBlock = styled.div`
  width: 100%;
  min-width: 0;
  min-height: 100vh;
`;

const ContentContainer = ({ children }: PropsWithChildren<{}>) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const workouts = useSelector(getWorkoutList);
  const prevWorkout = useRef<Immutable<Workouts> | null>(null);
  const isExam = useRouteMatch(EXAM);
  const queries: {
    type?: "workout" | "exam";
    ticket?: string;
    lesson?: string;
  } = useGetQuery();

  useEffect(() => {
    if (workouts.length && !prevWorkout.current) {
      const activeWorkout = workouts.find((item) => !item.finished);
      if (
        activeWorkout &&
        (!isExam || (isExam && queries.type !== "workout"))
      ) {
        dispatch(
          openModal({
            name: "AskModal",
            title: "У вас активная тренировка, вы хотите вернуться?",
            buttons: [
              {
                text: "Завершить",
                onClick: () => {
                  if (activeWorkout) {
                    dispatch(
                      finishWorkout({
                        id: activeWorkout.workoutId,
                        finished: Date.now(),
                      })
                    );
                    setActiveWorkoutFinished(activeWorkout.workoutId);
                  }
                  dispatch(closeModal("AskModal"));
                },
                type: "secondary",
              },
              {
                text: "Продолжить тест",
                onClick: () => {
                  history.push(
                    `${EXAM}?type=workout&ticket=${activeWorkout.workoutId}`
                  );
                  dispatch(closeModal("AskModal"));
                },
              },
            ],
          })
        );
      }
    }
    prevWorkout.current = workouts;
  }, [workouts, isExam]);

  return <ContentContainerBlock>{children}</ContentContainerBlock>;
};

export default ContentContainer;
