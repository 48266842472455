import { createSlice } from "@reduxjs/toolkit";

export type LecturePromoType = {
  id: number;
  time_start: number;
  notification_before: string;
  topic: {
    name: string;
  };
  combo_group: {
    name: string;
  };
  pause?: {
    name: string;
    time: string;
    image: string;
  };
  webinar_started_at: string;
  webinar_finished_at: string;
  video_studio: {
    video_stream_id: string;
    video_stream_url: string;
  };
};

interface ILectureUser {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
}

const cabinetReducer = createSlice({
  name: "cabinetReducer",
  initialState: {
    lecturePromo: null as LecturePromoType | null,
    loadLecturePromoUsers: false,
    lecturePromoUsers: [] as ILectureUser[],
    loadLecturePromo: true,
    webinarsList: [] as LecturePromoType[],
  },
  reducers: {
    setCabinetData: (state, action) => {
      state.lecturePromo = action.payload;
      state.loadLecturePromo = false;
    },
    removePromoLecture: (state) => {
      state.lecturePromo = null;
      state.lecturePromoUsers = [];
    },
    setCabinetLecturePromoLoad: (state, action) => {
      state.loadLecturePromo = action.payload;
    },
    setWebinarList: (state, action) => {
      state.webinarsList = action.payload;
    },
    setLoadLecturePromoUsers: (state, action) => {
      state.loadLecturePromoUsers = action.payload;
    },
    setLecturePromoUsers: (state, action) => {
      state.lecturePromoUsers = action.payload;
      state.loadLecturePromoUsers = false;
    },
    setStatusWebinar: (state, action) => {
      if (state.lecturePromo) {
        state.lecturePromo.pause = action.payload;
      }
    },
    // setLoadLecturePromoUsersOnline: (state, action) => {
    //   state.lecturePromoUsers = state.lecturePromoUsers.map((item) => {
    //     item.isOnline = action.payload.some(
    //       (uOnline: any) => uOnline.user_id === item.id
    //     );
    //     return item;
    //   });
    // },
  },
});

export const {
  setCabinetData,
  removePromoLecture,
  setCabinetLecturePromoLoad,
  setLoadLecturePromoUsers,
  setWebinarList,
  setLecturePromoUsers,
  setStatusWebinar,
} = cabinetReducer.actions;

export default cabinetReducer.reducer;
