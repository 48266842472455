import { useState } from "react"

export default function WhyFaqToggle () {
    const mobile = window.innerWidth<=768;
    const FaqBlock = ({question, answer}) =>{
    const [isOpen,setOpen] = useState(false);
        return (
            <>
            <div style={{position: 'relative', borderBottom: '1px solid #eee'}}>
                <div onClick={()=>{
                setOpen(!isOpen);
            }}  style={{cursor: 'pointer',height: 90, display: 'flex',justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                    <h3 style={{fontSize: mobile ? 16 : 26,fontWeight: 600}}>{question}</h3>
                    <div className="svg--open--" style={{  width: '40px', minWidth: 40,
  height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center',
  transition: '.3s',
  transform: isOpen ? 'rotate(45deg)' : 'none',
  borderRadius: '50%'}}>
              <svg  role="presentation" focusable="false" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><g stroke="none" strokeWidth="1px" fill="none" fillRule="evenodd" strokeLinecap="square"><g transform="translate(1.000000, 1.000000)" stroke="#222222"><path d="M0,11 L22,11" /><path d="M11,0 L11,22" /></g></g></svg>
 </div>
 </div>
  <div style={{maxHeight: isOpen ? '75px' : '0',transition: '1s',overflow: "hidden",marginBottom: isOpen ? mobile ? 20 : 40 : 0}}>
   <p> {answer} </p>
    </div>
            </div>
            </>
        )
    }
    return (
        <>
        <div style={{display: 'flex', flexDirection: 'column',paddingInline: 10}}>
<FaqBlock question={'Соответствие Закону "О безопасности дорожного движения"'} answer={'В соответствии с Законом "О безопасности дорожного движения" самоподготовка при обучении по профессии "водитель" запрещена. Короткие видеоролики не могут являться основой теоретического обучения, поскольку не обеспечивают соответствие курса программам подготовки водителей, утвержденным Министерством Образования.'} />
<FaqBlock question={'Реальное взаимодействие в процессе обучения'} answer={'Наша организация имеет образовательную лицензию, студии для проведения онлайн занятий и аудитории, собственный штат преподавателей и инструкторов.'} />
<FaqBlock question={'Интерактивные задания и контроль восприятия'} answer={'Преподаватель, в процессе проведения занятия, может выставлять интерактивные задания и получать ответы в реальном времени, контролируя уровень восприятия информации и вовлеченность слушателей.'} />
<FaqBlock question={'Мобильные приложения для обучения в реальном времени'} answer={'Сервис обеспечивает низкую (3-5 секунд) задержку в процессе трансляции, что позволяет преподавателю и слушателям взаимодействовать в реальном времени, реализуя положительные эффекты группового занятия в аудитории. Наши слушатели могут задавать вопросы и получать ответы сразу, в контексте занятия, что является важной составляющей образовательного процесса.'} />
<FaqBlock question={'Соответствие Закону "О безопасности дорожного движения"'} answer={'В отличии от большинства других (в том числе, не только автошкольных) систем дистанционного обучения у нас есть мобильные приложения для IOS и Android, в которых можно так же проходить занятия в режиме онлайн, в реальном времени.'} />
<FaqBlock question={'Доступ к архиву занятий'} answer={'Если вы пропустили занятие, то оно доступно в режиме архива. В данном режиме вы видите трансляцию, видите чат, который соответствует трансляции и можете отвечать на интерактивные задания, которые выставлялись преподавателем во время онлайн занятия.'} />
   

        </div>
        </>
    )

}