const initCanvasDrawSettings = () => {
  if (!localStorage.getItem("savedDrawing")) {
    localStorage.setItem(
      "savedDrawing",
      JSON.stringify({ height: "100%", lines: [], width: "100%" })
    );
  }

  if (!localStorage.getItem("offsetWidth")) {
    localStorage.setItem("offsetWidth", "100");
  }
};

export default initCanvasDrawSettings;
