import { TEACHER_ROLE } from "constants/role";
import RedirectForRole from "containers/RedirectForRole";
import { PropsWithChildren } from "react";

type Props = {
  to?: string;
};

const RedirectForManager = ({
  children,
  to = "/",
}: PropsWithChildren<Props>) => {
  return (
    <RedirectForRole rolesForRedirect={TEACHER_ROLE} to={to}>
      {children}
    </RedirectForRole>
  );
};

export default RedirectForManager;
