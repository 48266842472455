import { PropsWithChildren, SyntheticEvent } from "react";
import ErrorButton from "./ErrorButton";
import SecondaryButton from "./SecondaryButton";
import WarningButton from "./WarningButton";
import PrimaryButton from "./PrimaryButton";
import ErrorButtonSecondary from "./ErrorButtonSecondary";
import { ButtonSize, ButtonTheme } from "./types";

type Props = {
  onClick?: (event: SyntheticEvent) => void;
  className?: string;
  leftIcon?: string;
  leftIconColor?: string;
  leftIconSize?: "s" | "m" | "l" | "xl";
  size?: ButtonSize;
  buttonTheme?: ButtonTheme;
  withBorder?: boolean;
  disabled?: boolean;
};

const Button = (props: PropsWithChildren<Props>) => {
  const buttonTheme = props.buttonTheme;
  if (buttonTheme === "primary") return <PrimaryButton {...props} />;
  if (buttonTheme === "secondary") return <SecondaryButton {...props} />;
  if (buttonTheme === "warning") return <WarningButton {...props} />;
  if (buttonTheme === "error") return <ErrorButton {...props} />;
  if (buttonTheme === "errorSecondary")
    return <ErrorButtonSecondary {...props} />;

  return <PrimaryButton {...props} />;
};

export default Button;
