import { Value, Title } from "./styledComponents";

type Props = {
  title: string;
  value: number;
  color?: string;
};

const PaymentsInfoItem = ({ title, value, color }: Props) => {
  return (
    <div>
      <Title>{title}</Title>
      <Value color={color}>{value.toLocaleString("ru")} руб</Value>
    </div>
  );
};

export default PaymentsInfoItem;
