import { createSlice } from "@reduxjs/toolkit";

const appWidgetReducer = createSlice({
  name: "appWidgetReducer",
  initialState: { isOpen: false },
  reducers: {
    toggleAppWidgetOpen: (state) => {
      state.isOpen = !state.isOpen;
    },
    setAppWidgetOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { toggleAppWidgetOpen, setAppWidgetOpen } =
  appWidgetReducer.actions;

export default appWidgetReducer.reducer;
