import styled from "styled-components";
import WebinarGroupInfo from "../../WebinarGroupInfo";

export const StyledWebinarGroupInfo = styled(WebinarGroupInfo)`
  height: 452px;
`;

export const SidebarWrapper = styled.div`
  max-width: 30%;
`;
