import { useEffect, useState } from "react";
import styled from "styled-components";
import Tracks from "./Tracks";

const ContainerTest = styled.div`
    max-width: calc(100vw - 20px);
    margin-bottom: 140px;
    width: 100%;
    margin-inline: auto;
    @media (max-width: 768px) {
      margin-bottom: 30px !important;
    }
  `;

const SliderBlockWrapper = styled(ContainerTest)`

position: relative;
display: flex;
gap: 40px;

flex-direction: column;
@media (max-width: 768px){
gap: 10px;
}
    `
export default function SliderComponent({Title,primary, green, data}){




    return(
        <>
         <SliderBlockWrapper>
        <Title>{data.partner_zagolovok_opit}
        </Title>


        <Tracks primary={primary} green={green} data={data}/>


    </SliderBlockWrapper>

        </>
    )
}
