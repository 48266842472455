import { createSlice } from "@reduxjs/toolkit";

const userDevicesReducer = createSlice({
  name: "userDevicesReducer",
  initialState: { devices: [], permissions: {} },
  reducers: {
    setUserDevices: (state, action) => {
      return action.payload;
    },
  },
});

export const { setUserDevices } = userDevicesReducer.actions;

export default userDevicesReducer.reducer;
