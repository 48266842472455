import {useEffect, useState} from "react";
import empty from "./assets/images/empty.png";
const green = "#00ab6d";

const primary = "#17171b";
const Slide = ({ img, title, text }) => {
    return (
        <li className="slider-item"
            style={{
                display: "flex",
                gap: 20,
                maxWidth: 1200,
                width: 1200,
                minWidth: 1200,
            }}
        >
            <img src={img !== '' ? img : empty} style={{borderRadius: 24, minWidth: 470,width: 470, height: 328, objectFit: 'cover'}} />
            <div style={{background: '#f5f5f5', borderRadius: 24,width: '100%', display: 'flex', flexDirection: 'column', padding: '40px 30px', gap: 20}}>
                <h3 style={{fontSize: 30, lineHeight: '35px', fontWeight: 600, color: primary}}>{title}
                </h3>
                <p style={{fontSize: 18, lineHeight: '28px', fontWeight: 400, color: primary}}> {text}</p>
            </div>
        </li>
    );
};
export default function Tracks({data}) {
  const [sliderPageProgress, setSliderPageProgress] = useState(0); // 0 - 100




  useEffect(() => {
    if (sliderPageProgress >= 400) {
      setSliderPageProgress(0);
    }
  }, [sliderPageProgress]);

  useEffect(() => {

    const sliderIncreaseInterval = setInterval(() => {
      setSliderPageProgress((sliderPageProgress) => sliderPageProgress + 2);
    }, 100);
    return () => {
      clearInterval(sliderIncreaseInterval);
    };
  }, []);

  return (
    <>
      <div style={{ position: "relative", maxWidth: "100%", width: "100%" }}>
        <ul
          style={{
            display: "flex",
            gap: "100%",
            maxWidth: 999999,
            transition: '.6s',
            transform:
              "translateX(-" + Math.floor(sliderPageProgress / 100) * 200 + "%",
          }}
        >

          <Slide
            img={data.partner_photo_opit}
            title={data.partner_zagolovok_opit}
            text={data.partner_opisanie_opit}
          ></Slide>
          <Slide
            img={data.partner_photo_kachestvo}
            title={data.partner_zagolovok_kachestvo}
            text={data.partner_opisanie_kachestvo}
          ></Slide>
          <Slide
            img={data.partner_photo_service}
            title={data.partner_zagolovok_service}
            text={data.partner_opisanie_service}
          ></Slide>
          <Slide
            img={data.partner_photo_rezultat}
            title={data.partner_zagolovok_rezultat}
            text={data.partner_opisanie_rezultat}
          ></Slide>
        </ul>
      </div>
      <div style={{ display: "flex", gap: 20 }}>
      <div onClick={()=>{setSliderPageProgress(0); }}
          style={{
            display: "flex",
            cursor: 'pointer',
            flexDirection: "column",
            width: "100%",
            gap: 10,
          }}
        >
          <p
            style={{
              fontWeight: 600,
              color:  sliderPageProgress < 100 ? green : primary,
              lineHeight: "25px",
              fontSize: 16,
            }}
          >
           Опыт
          </p>
          <div
            style={{
              background: `${primary}`,
              borderRadius: 10,
              width: "100%",
              overflow: 'hidden',
              height: 10,
              position: "relative",
            }}
          >
            <div
              style={{
                transition: ".4s",
                background: `${green}`,
                borderRadius: 10,
                width: `${ sliderPageProgress < 100 ? Math.min(100, sliderPageProgress) : 0}%`,
                height: "100%",
              }}
            ></div>
          </div>
        </div>
        <div onClick={()=>{setSliderPageProgress(100); }}
          style={{
            cursor: 'pointer',
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 10,
          }}
        >
          <p
            style={{
              fontWeight: 600,
              color:  sliderPageProgress < 200 && sliderPageProgress > 100 ? green : primary,
              lineHeight: "25px",
              fontSize: 16,
            }}
          >
           Качество
          </p>
          <div
            style={{
              background: `${primary}`,
              borderRadius: 10,
              width: "100%",
              overflow: 'hidden',
              height: 10,
              position: "relative",
            }}
          >
            <div
              style={{
                transition: ".4s",
                background: `${green}`,
                borderRadius: 10,
                width: `${ sliderPageProgress < 200 && sliderPageProgress > 100 ? sliderPageProgress-100 : 0}%`,
                height: "100%",
              }}
            ></div>
          </div>
        </div>
        <div onClick={()=>{setSliderPageProgress(200); }}
          style={{
            display: "flex",
            cursor: 'pointer',
            flexDirection: "column",
            width: "100%",
            gap: 10,
          }}
        >
          <p
            style={{
              fontWeight: 600,
              color:  sliderPageProgress < 300 && sliderPageProgress > 200 ? green : primary,
              lineHeight: "25px",
              fontSize: 16,
            }}
          >
           Сервис
          </p>
          <div
            style={{
              background: `${primary}`,
              borderRadius: 10,
              width: "100%",
              overflow: 'hidden',
              height: 10,
              position: "relative",
            }}
          >
            <div
              style={{
                transition: ".4s",
                background: `${green}`,
                borderRadius: 10,
                width: `${ sliderPageProgress < 300 && sliderPageProgress > 200 ? sliderPageProgress-200 : 0}%`,
                height: "100%",
              }}
            ></div>
          </div>
        </div>
        <div onClick={()=>{setSliderPageProgress(300); }}
          style={{
            cursor: 'pointer',
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 10,
          }}
        >
          <p
            style={{
              fontWeight: 600,
              color: sliderPageProgress > 300 ? green : primary,
              lineHeight: "25px",
              fontSize: 16,
            }}
          >
           Результат
          </p>
          <div
            style={{
              background: `${primary}`,
              borderRadius: 10,
              width: "100%",
              overflow: 'hidden',
              height: 10,
              position: "relative",
            }}
          >
            <div
              style={{
                transition: ".4s",
                background: `${green}`,
                borderRadius: 10,
                width: `${sliderPageProgress > 300  ? sliderPageProgress-300 : 0}%`,
                height: "100%",
              }}
            ></div>
          </div>
        </div>


      </div>
    </>
  );
}
