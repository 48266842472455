import {
  AcceptButton,
  CancelButton,
  Container,
  ControlPanel,
  HistoryButton,
  Title,
} from "./styledComponents";
import {
  allowVoiceAnswer,
  rejectVoiceAnswer,
} from "../../../../api/TeacherWebinarChatAPI";
import { useDispatch } from "react-redux";
import { ChatUserVoiceNotification } from "../../types";

type Props = {
  onClickOnHistory: () => void;
  chatUserVoiceNotification: ChatUserVoiceNotification & {
    messageId: number;
    operation: string;
  };
};

const FeedbackPanelNotification = ({
  onClickOnHistory,
  chatUserVoiceNotification,
}: Props) => {
  const dispatch = useDispatch();

  const onClickDecline = () => {
    dispatch(
      rejectVoiceAnswer({
        user_id: chatUserVoiceNotification.id,
        operation: chatUserVoiceNotification.operation,
        id: chatUserVoiceNotification.messageId,
      })
    );
  };

  const onClickAllow = () => {
    dispatch(
      allowVoiceAnswer({
        user_id: chatUserVoiceNotification.id,
        operation: chatUserVoiceNotification.operation,
        id: chatUserVoiceNotification.messageId,
      })
    );
  };
  return (
    <Container>
      <div>
        <Title>
          <span>
            {[
              chatUserVoiceNotification.lastname,
              chatUserVoiceNotification.firstname,
              chatUserVoiceNotification.middlename,
            ].join(" ")}
          </span>{" "}
          запросил доступ задать
          <span> Вопрос голосом</span>
        </Title>
        <ControlPanel>
          <HistoryButton size="small" onClick={onClickOnHistory}>
            История сообщений
          </HistoryButton>
          <CancelButton
            size="small"
            onClick={onClickDecline}
            buttonTheme="secondary"
          >
            Отклонить
          </CancelButton>
          <AcceptButton size="small" onClick={onClickAllow}>
            Разрешить
          </AcceptButton>
        </ControlPanel>
      </div>
    </Container>
  );
};

export default FeedbackPanelNotification;
