const PlayMin = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6667 20.1C17.1402 20.1 20.7667 16.4735 20.7667 12C20.7667 7.52649 17.1402 3.89999 12.6667 3.89999C8.19317 3.89999 4.56668 7.52649 4.56668 12C4.56668 16.4735 8.19317 20.1 12.6667 20.1ZM16.693 12.5878C16.9246 12.4609 16.9246 12.1391 16.693 12.0122L10.1795 8.44369C9.95068 8.31832 9.66668 8.47769 9.66668 8.73146V15.8685C9.66668 16.1223 9.95068 16.2817 10.1795 16.1563L16.693 12.5878Z"
        fill="#62686B"
      />
    </svg>
  );
};

export default PlayMin;
