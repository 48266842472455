import { useState, useMemo } from "react";
import { setTaskSolve } from "api/InteractiveTaskAPI";
import { TTaskAnswers, TAnswer } from "api/models/task";
import { useDispatch } from "react-redux";
import { StyledAnswer, Container, HintBlock } from "./styledComponents";

type Props = {
  webinarId: number;
  id: number;
  questionId: number;
  hint?: string;
  answers: TTaskAnswers;
  disabled?: boolean;
};

const TaskAnswers = ({
  webinarId,
  id,
  hint,
  questionId,
  answers,
  disabled,
}: Props) => {
  const [activeAnswer, setActiveAnswer] = useState<TAnswer | null>(null);
  const dispatch = useDispatch();

  const handleSetAnswer = (answer: TAnswer) => {
    if (activeAnswer === null && !disabled) {
      dispatch(setTaskSolve(webinarId, id, questionId, answer.id));
      setActiveAnswer(answer);
    }
  };

  const hintBlock = useMemo(() => {
    if (activeAnswer || disabled) {
      return <HintBlock>{hint}</HintBlock>;
    }
    return null;
  }, [disabled, activeAnswer, hint]);

  return (
    <>
      {hintBlock}
      <Container>
        {answers
          ? answers.map((answer) => (
              <div key={answer.id} onClick={() => handleSetAnswer(answer)}>
                <StyledAnswer
                  id={answer.id}
                  active={activeAnswer}
                  // @ts-ignore
                  right={answer.correct}
                  answer={answer.answer}
                  // @ts-ignore
                  tabIndex={0}
                />
              </div>
            ))
          : null}
      </Container>
    </>
  );
};

export default TaskAnswers;
