import { useMemo } from "react";
import { Container } from "./styledComponents";
import { TAnswer } from "../../api/models/task";

type Props = {
  id: number;
  answer: string;
  right?: boolean;
  className?: string;
  active: TAnswer | null;
};

const TaskAnswer = ({ id, answer, active, right, className }: Props) => {
  const status = useMemo(() => {
    if (!active) {
      return null;
    }
    if (id === active.id && right) {
      return "green";
    }
    if (id === active.id && !right) {
      return "red";
    }
    return null;
  }, [id, active, right]);

  return (
    <Container status={status} className={className} tabIndex={0}>
      {answer}
    </Container>
  );
};

export default TaskAnswer;
