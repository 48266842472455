import { createSlice } from "@reduxjs/toolkit";

const onlineLectureReducer = createSlice({
  name: "onlineLectureReducer",
  initialState: {
    isLectureComing: false,
    lectureName: null,
    lectureId: null,
    startDate: null,
    startTime: null,
    loaded: false,
    loading: true,
    isStart: true,
    teacher: { name: "" },
  },
  reducers: {
    toggleLectureComing: (state) => {
      state.isLectureComing = !state.isLectureComing;
    },
    setIsLectureComing: (state, action) => {
      state.isLectureComing = action.payload;
    },
    setLectureName: (state, action) => {
      state.lectureName = action.payload;
    },
    setLectureStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setLectureStartTime: (state, action) => {
      state.startTime = action.payload;
    },
    setOnlineLectureLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setOnlineLectureLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLectureTeacherName: (state, action) => {
      state.teacher.name = action.payload;
    },
    setLectureId: (state, action) => {
      state.lectureId = action.payload;
    },
  },
});

export const {
  toggleLectureComing,
  setOnlineLectureLoaded,
  setOnlineLectureLoading,
  setIsLectureComing,
  setLectureTeacherName,
  setLectureId,
  setLectureName,
  setLectureStartDate,
  setLectureStartTime,
} = onlineLectureReducer.actions;

export default onlineLectureReducer.reducer;
