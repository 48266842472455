import { Children, cloneElement, PropsWithChildren } from "react";
import styled from "styled-components";

type Props = {
  value: any;
  onChange: (tab: any) => void;
  className?: string;
};

const TabsContainer = styled.div`
  height: 65px;
  display: flex;
  background-color: ${(props) => props.theme.colors.opacityGreen10};
  border-radius: 16px 16px 0 0;
  text-align: center;
  line-height: 65px;
  cursor: pointer;
`;

const Tabs = ({
  value,
  onChange,
  children,
  className,
}: PropsWithChildren<Props>) => {
  const handleOnChange = (tabValue: any) => {
    if (value === tabValue) {
      return;
    }
    onChange(tabValue);
  };

  return (
    <TabsContainer className={className}>
      {Children.map(children, (child: any) =>
        cloneElement(child, { onClick: handleOnChange, value })
      )}
    </TabsContainer>
  );
};

export default Tabs;
