import styled from "styled-components";
import { Button } from "components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 0 32px;
  position: absolute;
  box-sizing: border-box;
`;

export const Picture = styled.img`
  margin: auto auto 16px;
  display: block;
  width: 480px;

  @media screen and (max-width: 944px) {
    width: 100%;
  }
`;

export const Text = styled.p`
  font-size: 1.25em;
  font-weight: 600;
  margin: 0 0 16px;
  @media screen and (max-width: 944px) {
    font-size: 0.85em;
  }
`;

export const Info = styled.div`
  width: 100%;
  padding: 14px 24px;
  border-radius: 12px;
  background: rgba(241, 195, 61, 0.2);
  box-sizing: border-box;

  @media screen and (max-width: 944px) {
    font-size: 1em;
  }
`;

export const WrapperOptions = styled.div`
  margin: 16px 0;
`;

export const OptionItem = styled.div`
  width: 100%;
  background: ${({ status }: { status: "ok" | "wrong" | "no-answer" }) =>
    status === "ok"
      ? "rgba(74, 195, 101, 0.2)"
      : status === "wrong"
      ? "rgba(252, 94, 94, 0.2)"
      : "transparent"};
  display: flex;
  align-items: center;
  padding: 0 24px;
  border: 1px solid #dadde4;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 12px;
  cursor: pointer;
  box-sizing: border-box;

  @media screen and (max-width: 944px) {
    height: 39px;
    padding: 0 16px;
    font-size: 0.85em;
  }
`;

export const Send = styled(Button)`
  width: 137px;
  display: block;
  margin: 106px auto auto;

  @media screen and (max-width: 944px) {
    width: 100%;
    height: 48px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
`;
