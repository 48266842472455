import { ChatHeaderListItem } from "shared";
import { HeaderListContainer } from "./styledComponents";

type Props = {
  tab?: string;
  onTabClick?: (tabName: string) => void;
  onMouseDown?: (e: any) => void;
};

const HeaderList = ({ tab, onTabClick, onMouseDown }: Props) => {
  return (
    <HeaderListContainer onMouseDown={onMouseDown}>
      <ChatHeaderListItem
        active={tab === "chat"}
        preview={tab !== "chat"}
        onClick={() => onTabClick!("chat")}
      >
        Общий чат
      </ChatHeaderListItem>
      <ChatHeaderListItem
        active={tab === "voiceQuestion"}
        preview={tab !== "voiceQuestion"}
        onClick={() => onTabClick!("voiceQuestion")}
      >
        Вопрос голосом
      </ChatHeaderListItem>
    </HeaderListContainer>
  );
};

export default HeaderList;
