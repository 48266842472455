import { Icon } from "components";
import { PaperContainer } from "containers";
import styled from "styled-components";

export const Container = styled(PaperContainer)`
  display: flex;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px;
`;

export const Name = styled.div`
  font-size: ${(props) => props.theme.fontSizes.subtitle1FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: ${(props) => props.theme.fontSizes.subtitle2FontSize};
  width: 90px;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
