import { DefaultModalContainer } from "containers";
import { Header } from "layout/ModalWindow/layout";
import { closeModal, openModal } from "layout/ModalWindow/redux";
import { useDispatch } from "react-redux";
import { Button } from "components";
import styled from "styled-components";

type Props = {
  name: string;
};

const Container = styled(DefaultModalContainer)`
  max-width: 500px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const ADMINWebinarTaskChoiceModal = ({ name }: Props) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal(name));
  };

  const handleOpenQuestionModalWithText = () => {
    dispatch(
      openModal({
        name: "WebinarTaskModal",
        question:
          "Должен ли водитель синего автомобиля, поворачивая направо, уступить дорогу пешеходам, которые переходят дорогу?",
        answers: [
          {
            answer:
              "Обязывает водителя уступить дорогу транспортным средствам, которые подъезжают к нерегулируемому перекрестку по пересекаемой дороге с обязательной остановкой перед разметкой ”Стоп-линия”",
            right: true,
          },
          {
            answer: "Только синему автомобилю",
            right: true,
          },
          {
            answer: "Только красному автомобилю",
            right: true,
          },
        ],
      })
    );
  };

  const handleOpenQuestionModalWithVideo = () => {
    dispatch(
      openModal({
        name: "WebinarTaskModal",
        videoUrl: "VIDEO_URL_HERE",
        question:
          "Должен ли водитель синего автомобиля, поворачивая направо, уступить дорогу пешеходам, которые переходят дорогу?",
        answers: [
          {
            answer: "Только трамваю",
            right: true,
          },
          {
            answer: "Только синему автомобилю",
            right: true,
          },
        ],
      })
    );
  };

  const handleOpenQuestionModalWithImage = () => {
    dispatch(
      openModal({
        name: "WebinarTaskModal",
        imageUrl: "IMAGE_URL",
        question:
          "Должен ли водитель синего автомобиля, поворачивая направо, уступить дорогу пешеходам, которые переходят дорогу?",
        answers: [
          {
            answer: "Только трамваю",
            right: true,
          },
          {
            answer: "Только синему автомобилю",
            right: true,
          },
          { answer: "Обоим транспортным средствам", right: true },
        ],
      })
    );
  };

  const handleOpenQuestionModalWithManyAnswers = () => {
    dispatch(
      openModal({
        name: "WebinarTaskModal",
        imageUrl: "IMAGE_URL",
        question:
          "Должен ли водитель синего автомобиля, поворачивая направо, уступить дорогу пешеходам, которые переходят дорогу?",
        answers: [
          {
            answer: "Только трамваю",
            right: true,
          },
          {
            answer: "Только синему автомобилю",
            right: true,
          },
          { answer: "Обоим транспортным средствам", right: true },
          {
            answer: "Только трамваю",
            right: true,
          },
          {
            answer: "Только синему автомобилю",
            right: true,
          },
          { answer: "Обоим транспортным средствам", right: true },
          {
            answer: "Только трамваю",
            right: true,
          },
          {
            answer: "Только синему автомобилю",
            right: true,
          },
          { answer: "Обоим транспортным средствам", right: true },
        ],
      })
    );
  };

  const handleOpenRatingLectureModal = () => {
    dispatch(openModal({ name: "LectureRatingBeforeFinish" }));
  };

  return (
    <Container>
      <Header title="Выберите модальное окно" onClose={handleClose} />
      <StyledButton onClick={handleOpenQuestionModalWithText}>
        Открыть модалку вопроса с текстом
      </StyledButton>
      <StyledButton onClick={handleOpenQuestionModalWithImage}>
        Открыть модалку вопроса с картинкой
      </StyledButton>
      <StyledButton onClick={handleOpenQuestionModalWithVideo}>
        Открыть модалку вопроса с видео
      </StyledButton>
      <StyledButton onClick={handleOpenQuestionModalWithManyAnswers}>
        Открыть модалку вопроса с множеством вариантов ответа
      </StyledButton>
      <StyledButton onClick={handleOpenRatingLectureModal}>
        Открыть модалку оценки лекции
      </StyledButton>
    </Container>
  );
};

export default ADMINWebinarTaskChoiceModal;
