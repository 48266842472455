import { CustomScrollContainer } from "containers";
import { ChatMessage } from "shared";
import styled from "styled-components";

export const BodyChatContainer = styled(CustomScrollContainer)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 10px 10px 18px 10px;
  box-sizing: border-box;
  position: relative;

  &::-webkit-scrollbar-track {
    margin-bottom: 24px;
  }

  & > :nth-child(1) {
    margin-top: auto !important;
  }
`;

export const Message = styled(ChatMessage)<{ isYour: boolean }>`
  margin-bottom: 16px;
  width: 80%;
  box-sizing: border-box;

  background-color: ${(props) =>
    props.isYour
      ? props.theme.colors.opacityGreen10
      : props.theme.colors.opacityGrey5};
  align-self: ${(props) => (props.isYour ? "flex-end" : "auto")};
`;

export const InputContainer = styled.div`
  position: sticky;
  background-color: ${(props) => props.theme.colors.primaryWhite};
  bottom: -19px;
  min-height: 62px;
  margin-bottom: -19px;
`;
