import { DefaultModalContainer } from "containers";
import styled from "styled-components";

import { Button } from "components";

export const Container = styled(DefaultModalContainer)`
  width: 728px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  width: 90px;
  height: 90px;
  margin-top: 30px;
`;

export const Title = styled.div`
  margin-top: 48px;
  font-size: ${(props) => props.theme.fontSizes.heading3FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.boldFontWeight};
`;

export const Subtitle = styled.div`
  margin-top: 24px;
  font-size: ${(props) => props.theme.fontSizes.heading6FontSize};
  color: ${(props) => props.theme.colors.primaryBlack};
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const StyledButton = styled(Button)`
  margin-top: 134px;
`;
