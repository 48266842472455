import { ChatInput } from "shared";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLectureChatMessages,
  postMessageToLectureChat,
} from "api/UserLectureChatAPI";
import { getOnlineLectureWidgetChatMessages } from "../../redux";
import { getUserName } from "selectors/userInfo";
import { TChatMessages } from "api/models/chat";
import { BodyChatContainer, InputContainer, Message } from "./styledComponents";
import { howMuchTimeSpent } from "helpers/howMuchTimeSpent";

const BodyChat = () => {
  const [inputValue, setInputValue] = useState("");
  const [, setTime] = useState(new Date());
  const chatMessages: TChatMessages = useSelector(
    getOnlineLectureWidgetChatMessages
  );
  const userName = useSelector(getUserName);

  const chatContainerRef = useRef<any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLectureChatMessages());
  }, [dispatch]);

  useEffect(() => {
    const id = setInterval(() => {
      setTime(new Date());
    }, 2000);

    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current?.scrollTo({
        top: chatContainerRef.current?.scrollHeight,
      });
    }
  }, [chatMessages]);

  const handleChangeInput = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSend = () => {
    dispatch(postMessageToLectureChat(inputValue));
    setInputValue("");
  };

  const handlePressEnter = (e: any) => {
    if (e.code === "Enter") {
      handleSend();
    }
  };

  return (
    <BodyChatContainer ref={chatContainerRef}>
      {chatMessages?.map((message) => (
        <Message
          key={message.message + userName}
          author={message.username === userName ? "Вы" : message?.username}
          message={message.message}
          time={howMuchTimeSpent(message.created_at)}
          isYour={message.username === userName}
        />
      ))}
      <InputContainer>
        <ChatInput
          onKeyUp={handlePressEnter}
          value={inputValue}
          onChange={handleChangeInput}
          onClick={handleSend}
        />
      </InputContainer>
    </BodyChatContainer>
  );
};

export default BodyChat;
