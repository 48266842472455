import { useState, useRef, useCallback, useEffect, useMemo } from "react";
import VideoPlayerControl from "./VideoPlayerControl";
import { playSvg, pauseSvg } from "./svgs";
import styled from "styled-components";
import { IChatMessage } from "api/models/chat";
import { Task } from "./types";
import {
  LectureArchiveInfo,
  LectureVideo,
} from "../../pages/LectureArchive/redux";
import { Chat } from "components/Chat";
import {
  sendUpdateTimeWatch,
  sendViewLectureWatched,
} from "../../api/LecturesArchiveAPI";
import ReactPlayer from "react-player";
import { closeModal, openModal } from "../../layout";
import { useDispatch } from "react-redux";
import { getLectureArchiveList } from "../../api/UserAPI";

type VideoPlayerProps = {
  messages?: IChatMessage[];
  url: string;
  tasks?: Task[];
  info?: LectureArchiveInfo;
  timeStart: number;
  timeEnd: number;
  videoData: LectureVideo;
};

export const rates: { [key: number]: number } = {
  1: 1,
  2: 1.25,
  3: 1.5,
  4: 1.75,
  5: 2,
};

const VideoPlayer = ({
  url,
  tasks,
  info,
  timeStart,
  timeEnd,
  videoData,
}: VideoPlayerProps) => {
  const dispatch = useDispatch();
  const videoElement = useRef<ReactPlayer | null>(null);
  const [isPlay, setPlay] = useState(false);
  const [activeTime, setActiveTime] = useState(0);
  const [rate, setRate] = useState(1);
  const [maxTime, setMaxTime] = useState(0);
  const [activeMessages, setActiveMessages] = useState<IChatMessage[]>([]);
  const [minute, setMinute] = useState<number>(
    (videoData.actions.archive_watch_time / 30) | 0
  );

  const isWatched = videoData.actions.is_archived_watched !== 0;

  useEffect(() => {
    const sendData = async (num: number) => {
      await sendUpdateTimeWatch(videoData.actions.lesson_id, num);
    };

    if (!isWatched) {
      if (activeTime >= 30 && activeTime / 30 > minute + 0.5) {
        setMinute(activeTime / 30 + 1);
        sendData(Math.trunc(activeTime));
      }
    }
  }, [minute, activeTime, videoData.actions, isWatched]);

  const handleTimeUpdate = useCallback((event: any) => {
    setActiveTime(event.playedSeconds);
  }, []);

  const onDuration = useCallback((val: number) => {
    setMaxTime(val);
  }, []);

  const setCurrentTime = (time: number, forced?: boolean) => {
    if (videoElement.current) {
      videoElement.current.seekTo(time);
    }
  };

  const endVideo = useCallback(() => {
    if (info) {
      sendViewLectureWatched(info.id);
      dispatch(getLectureArchiveList());
      setPlay(false);
      dispatch(
        openModal({
          name: "ArchivePlaybackModal",
          clickYes: handleClickYes,
        })
      );
      setActiveTime(0);
    }
  }, [dispatch, info]);

  const convertTime = useCallback(
    (createdTime: number): number => {
      return createdTime - timeStart;
    },
    [timeStart]
  );

  useEffect(() => {
    const currentTime = activeTime;
    const activeMessageFilter = info?.chat.filter((item) => {
      return convertTime(item.created_at) <= currentTime;
    });

    //@ts-ignore
    if (activeMessages?.length !== activeMessageFilter?.length)
      setActiveMessages(activeMessageFilter || []);
    //eslint-disable-next-line
  }, [info, activeTime, convertTime]);

  const play = useMemo(() => {
    return (
      <Play onClick={() => setPlay(!isPlay)}>
        {isPlay ? pauseSvg() : playSvg()}
      </Play>
    );
  }, [isPlay]);

  const playBigButton = useMemo(() => {
    return (
      <PlayBig onClick={() => setPlay(!isPlay)}>
        {!isPlay && playSvg(50, 50)}
      </PlayBig>
    );
  }, [isPlay]);

  const handleClickYes = () => {
    dispatch(closeModal("ArchivePlaybackModal"));
    setCurrentTime(0);
    setTimeout(() => {
      setPlay(true);
    }, 2000);
  };

  const setPlayTestFalse = (val: boolean) => {
    setPlay(val);
  };

  return (
    <Wrapper>
      <Backlog onClick={() => setPlay(!isPlay)} isPlay={isPlay} />
      <Player onClick={() => setPlay(!isPlay)}>
        <ReactPlayer
          controls={false}
          playbackRate={rates[rate]}
          playing={isPlay}
          ref={videoElement}
          onProgress={handleTimeUpdate}
          onDuration={onDuration}
          onReady={() => {
            const localSave = localStorage.getItem(
              `timeViewLast_${videoData.actions.lesson_id}`
            );

            if (localSave) {
              const data = JSON.parse(localSave);

              setMinute(data.time);
              setActiveTime(data.time);
              setCurrentTime(data.time, true);

              sendUpdateTimeWatch(videoData.actions.lesson_id, data.time).then(
                () => {
                  localStorage.removeItem(
                    `timeViewLast_${videoData.actions.lesson_id}`
                  );
                }
              );
            } else {
              const archiveProgress =
                videoData.actions.is_archived_watched === 0
                  ? videoData.actions.archive_watch_time | 0
                  : 0;
              if (archiveProgress !== 0) {
                setCurrentTime(archiveProgress);
              }
            }
          }}
          width="100%"
          height="100%"
          style={{ zIndex: 1, position: "relative" }}
          url={url}
          onEnded={endVideo}
        />
      </Player>
      <VideoButtonsWrapper>{play}</VideoButtonsWrapper>
      <VideoButtonsBigWrapper>{playBigButton}</VideoButtonsBigWrapper>
      <VideoPlayerControl
        rate={rate}
        setRate={setRate}
        tasks={tasks}
        activeTime={activeTime}
        maxTime={maxTime}
        setCurrentTime={setCurrentTime}
        info={info}
        setPlay={setPlayTestFalse}
      />
      <Chat messageList={activeMessages} title="История чата" type="history" />
    </Wrapper>
  );
};

export default VideoPlayer;

const Player = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

interface BacklogProps {
  isPlay: boolean;
}

const Backlog = styled.div<BacklogProps>`
  display: ${(props) => {
    return props.isPlay ? "none" : "block";
  }};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 5;
`;

const VideoButtonsWrapper = styled.div`
  position: absolute;
  z-index: 7;
  margin: auto;
  bottom: 11px;
  left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 456px;
  height: 100px;
`;

const Button = styled.button`
  border: 0 !important;
  outline: 0 !important;
  width: 50px;
  height: 50px;
  padding: 0;
  background: transparent;
  opacity: 0.8;
  cursor: pointer;
  z-index: 10;

  &:hover {
    opacity: 1;
  }
`;

const VideoButtonsBigWrapper = styled.div`
  position: absolute;
  z-index: 10;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 456px;
  height: 100px;
`;

// const Prev = styled(Button)``;
// const Next = styled(Button)``;
const Play = styled(Button)``;
const PlayBig = styled(Button)``;
