import styled from "styled-components"
import message from './assets/images/Message.png';
import frame from './assets/images/Frame_2524-min.jpg';
export default function FilledImageComponent ({Text,ExtraTitle}) {
    const Container = styled.div`
    display: flex;
    width: 100%;
    padding: 60px 40px;
    position: relative;
    margin-bottom: 140px;
    justify-content: flex-end;
    height: 598px;
    flex-direction: column;

        background: url(${frame});
            background-size: cover;
            background-position: center;
            border-radius: 24px;
               @media (max-width: 768px){
   max-width: calc(100% - 20px);
   margin-inline: auto;
   height: 532px;
   padding: 35px 20px;
   margin-bottom: 30px;
    }
    `
    const Message = styled.div`
    left: 712px;
    position: absolute;
    top: 88px;
    width: 357px;
    height: 92px;
    background: url(${message});
    background-size: cover;
       @media (max-width: 768px){
position: relative;
top: auto;
left: auto;
width: 185px;
height: 48px;
    }
    `
    const Title = styled(ExtraTitle)`
    margin-bottom: 20px;
    max-width: 544px;
    color: white;
    @media (max-width: 768px){
    margin-bottom: 12px;
    margin-top: 16px;
    }
    `;
    const Text_ = styled(Text)`
    color: white;
    max-width: 717px;
    margin: 0;
 
  `;
    return (
        <>
        <Container>
        <Message />
<Title>Общение, комьюнити и нетворкинг
</Title>
<Text_>Вебинары с экспертами и общение в чате с другими учениками</Text_>

        </Container>
        </>
    )
}
