import { createSlice } from "@reduxjs/toolkit";

const modalRestrictionsReducer = createSlice({
  name: "modalRestrictionsReducer",
  initialState: { restricted: [""] },
  reducers: {
    setModalRestrictions: (state, action) => {
      return { ...state, restricted: [...state.restricted, action.payload] };
    },
    removeModalRestrictions: (state, action) => {
      return {
        ...state,
        restricted: state.restricted.filter((item) => item !== action.payload),
      };
    },
  },
});

export const { setModalRestrictions, removeModalRestrictions } =
  modalRestrictionsReducer.actions;

export default modalRestrictionsReducer.reducer;
