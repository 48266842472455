import { ChatTabPanel } from "..";
import { Container, TabsPanelContainer } from "./styledComponents";

const WebinarTabContainer = () => {
  return (
    <Container>
      <TabsPanelContainer>
        <ChatTabPanel />
      </TabsPanelContainer>
    </Container>
  );
};

export default WebinarTabContainer;
