import { createSlice } from "@reduxjs/toolkit";
import {
  ExamStartResponse,
  ExtraTicketsResponse,
  QuestionsResponse,
  TicketsResponse,
} from "api/models/ticket";
import { UserAnswer } from "../Exam";

type WorkoutTypes = "ticket" | "lesson";
export type WorkoutId = `${WorkoutTypes}_${number}_id`;

export type Workouts = {
  workoutId: WorkoutId;
  started: number;
  finished?: number;
  answers: UserAnswer[];
}[];

export type Exam = ExamStartResponse & {
  started: number;
  finished?: number;
  passed?: boolean;
  answers: UserAnswer[];
};

type TestReducer = {
  tickets: TicketsResponse;
  questions: QuestionsResponse;
  exam: Exam | null;
  workouts: Workouts;
};

const localStorageExam = localStorage.getItem("exam");
const localStorageWorkout = localStorage.getItem("workouts");

const filterOldWorkout = (storage: string | null) => {
  if (storage) {
    let data = JSON.parse(storage) as Workouts;
    data.filter((item) => {
      if (item.finished) {
        return false;
      }
      const now = Date.now();
      const finish = new Date(
        new Date(item.started).getTime() + 20 * 60 * 1000
      ).getTime();
      return now > finish;
    });
    return data;
  }
  return [];
};

const testsReducer = createSlice({
  name: "tests",
  initialState: {
    tickets: [],
    questions: [],
    exam:
      localStorageExam && localStorageExam !== "null"
        ? JSON.parse(localStorageExam)
        : null,
    workouts: filterOldWorkout(localStorageWorkout),
  } as TestReducer,
  reducers: {
    setTickets: (state, action: { payload: TicketsResponse }) => {
      state.tickets = action.payload;
    },
    setQuestions: (state, action: { payload: QuestionsResponse }) => {
      state.questions = action.payload;
    },
    startExam: (state, action: { payload: ExamStartResponse }) => {
      state.exam = {
        ...action.payload,
        started: Date.now(),
        answers: [],
      };
    },
    setExtraTickets: (state, action: { payload: ExtraTicketsResponse }) => {
      if (state.exam) {
        state.exam = {
          ...state.exam,
          tickets: [...state.exam.tickets, ...action.payload.questions],
        };
      }
    },
    setAnswerExam: (state, action: { payload: { answer: UserAnswer } }) => {
      if (state.exam && !state.exam.finished) {
        state.exam.answers.push(action.payload.answer);
      }
    },
    finishExam: (
      state,
      action: { payload: { id: number; passed: boolean } }
    ) => {
      if (state.exam) {
        state.exam.finished = Date.now();
        state.exam.passed = action.payload.passed;
      }
    },
    removeExam: (state) => {
      localStorage.removeItem("exam");
      state.exam = null;
    },
    startWorkout: (state, action: { payload: { id: WorkoutId } }) => {
      const copyWorkout = [...state.workouts];
      const workout = copyWorkout.find(
        (item) => item.workoutId === action.payload.id
      );
      if (!workout) {
        copyWorkout.push({
          workoutId: action.payload.id,
          started: Date.now(),
          answers: [],
        });
      } else {
        if (workout.finished) {
          workout.started = Date.now();
          workout.answers = [];
          delete workout.finished;
        }
      }
      state.workouts = copyWorkout;
    },
    setAnswerWorkout: (
      state,
      action: { payload: { id: WorkoutId; answer: UserAnswer } }
    ) => {
      const copyWorkout = [...state.workouts];
      const workout = copyWorkout.find(
        (item) => item.workoutId === action.payload.id
      );
      if (workout) {
        const findAnswer = workout.answers.find(
          (item) => item.questionId === action.payload.answer.questionId
        );
        if (!findAnswer) {
          workout.answers.push(action.payload.answer);
        }
      }
      state.workouts = copyWorkout;
    },
    finishWorkout: (
      state,
      action: { payload: { id: WorkoutId; finished: number } }
    ) => {
      const copyWorkout = [...state.workouts];
      const workout = copyWorkout.find(
        (item) => item.workoutId === action.payload.id
      );
      if (workout) {
        workout.finished = action.payload.finished;
      }
      state.workouts = copyWorkout;
    },
  },
});

export const {
  setTickets,
  setQuestions,
  startExam,
  setAnswerExam,
  setExtraTickets,
  finishExam,
  removeExam,
  startWorkout,
  setAnswerWorkout,
  finishWorkout,
} = testsReducer.actions;
export default testsReducer.reducer;
