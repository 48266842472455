import { Button, DefaultTableContainer } from "components";
import styled from "styled-components";

export const Container = styled(DefaultTableContainer)`
  margin-top: 16px;
`;

export const Name = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  color: ${(props) => props.theme.colors.primaryBlack};
`;

export const NumbersBold = styled.div`
  font-family: "Montserrat Bold", serif !important;
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  color: ${(props) => props.theme.colors.primaryBlack};
`;

export const Numbers = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
  color: ${(props) => props.theme.colors.primaryBlack};
`;

export const StyledButton = styled(Button)`
  height: 32px;
`;
