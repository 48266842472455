import styled from "styled-components";
import { Select, Button, DefaultTableContainer } from "components";

export const SelectBlock = styled(Select)`
  width: 100%;
  height: 48px;
  font-size: 1em;

  .MuiInputBase-input {
    padding: 13px 0 13px 16px;
    max-height: 48px;
  }
`;

export const MainText = styled.div`
  font-size: 14px;
  font-weight: ${(props) => props.theme.fontWeights.normalFontWeight};
`;

export const StyledButton = styled(Button)`
  height: 32px;
  margin: 0 auto;
`;

export const TableWrapper = styled(DefaultTableContainer)`
  margin-top: 24px;
`;

export const ContainerExam = styled.div`
  height: calc(100vh - 88px);
  box-shadow: inset 3px 4px 42px rgba(225, 225, 232, 0.4);
`;

export const WrapperExam = styled.div`
  max-width: 960px;
  width: 100%;
  margin: auto;
`;

export const WrapperFinishExam = styled.div`
  width: 100%;
  height: 600px;
  padding-top: 160px;
`;
export const TextFinishExam = styled.p`
  text-align: center;
  font-size: 1.25em;
  font-weight: 600;
  margin-bottom: 0;
`;
export const TitleFinishExam = styled.p`
  font-size: 1.75em;
  font-weight: 700;
  text-align: center;
  margin-top: 72px;
  margin-bottom: 48px;
`;
export const ResultFinishExam = styled.div`
  width: 200px;
  height: 200px;
  margin: auto;
`;

export const ButtonFinishExam = styled(Button)`
  margin: 80px auto auto;
  display: block;
`;

export const ButtonRepeatExam = styled(Button)`
  margin: 40px auto auto auto;
  display: block;
  padding: 6px 10px;
`;

export const WorkoutListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
`;

export const WorkoutItemBlock = styled.div`
  border: 1px solid #dadde4;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  width: calc(14.28% - 16px);
  height: 72px;
  box-sizing: border-box;
  margin: 8px;
  cursor: pointer;

  @media screen and (max-width: 910px) {
    width: calc(50% - 16px);
  }
`;

export const WorkoutName = styled.p`
  font-size: 1.125em;
  font-weight: 600;
  margin: 15px 0 5px 0;
  text-align: center;
`;

export const WorkoutCount = styled.p`
  font-size: 0.75em;
  margin: 0;
  text-align: center;
`;
