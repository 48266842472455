import { createSlice } from "@reduxjs/toolkit";
import type { AskModalProps } from "../modals/AskModal/AskModal";

type openModalAction = {
  payload: { name: string; [key: string]: any } | AskModalProps;
};

type closeModalAction = {
  payload: string;
};

type State = Array<{ name: string; [key: string]: any }>;

const modalWindowReducer = createSlice({
  name: "modalWindowReducer",
  initialState: [] as State,
  reducers: {
    openModal: (state, action: openModalAction) => {
      if (!state.find((item) => item.name !== action.payload.name)) {
        return [...state, action.payload];
      }
    },
    closeModal: (state, action: closeModalAction) => {
      return state.filter((modal) => modal.name !== action.payload);
    },
    closeLastModal: (state) => {
      return [...state].slice(state.length - 1);
    },
  },
});

export const { openModal, closeModal, closeLastModal } =
  modalWindowReducer.actions;

export default modalWindowReducer.reducer;
