import { useState, useMemo, useRef, useEffect } from "react";
import styled from "styled-components";
import Slider from "rc-slider";
import { useDispatch, useSelector } from "react-redux";

import { Task } from "./types";

import { formatDuration } from "utils";
import { closeModal, openModal } from "../../layout";
import { LectureArchiveInfo } from "../../pages/LectureArchive/redux";
import { getModals } from "../../layout/ModalWindow/redux/selectors";
import "rc-slider/assets/index.css";
import { rates } from "./VideoPlayer";

const PADDING = 64;

type VideoPlayerControlProps = {
  activeTime: number;
  tasks?: Task[];
  maxTime: number;
  setCurrentTime: (time: number) => void;
  setPlay: (isPlay: boolean) => void;
  info?: LectureArchiveInfo;
  disabled?: boolean;
  setRate: (value: number) => void;
  rate: number;
};

const VideoPlayerControl = ({
  activeTime,
  tasks,
  maxTime,
  setCurrentTime,
  setPlay,
  info,
  disabled = false,
  rate,
  setRate,
}: VideoPlayerControlProps) => {
  const dispatch = useDispatch();
  const modals = useSelector(getModals);
  const wasPlayedModals = useRef<number[]>([]);
  const [sliderValue, setSliderValue] = useState(0);
  const [isDrag, setDrag] = useState(false);

  const newRate: number = rate + 1 > 5 ? 1 : rate + 1;

  const callbackClose = (endTime: number) => {
    setCurrentTime(endTime);

    setPlay(true);
  };

  useEffect(() => {
    if (tasks && info) {
      const activeTask = tasks.find(
        (item) => item.start <= activeTime && item.end >= activeTime
      );
      if (activeTask) {
        const playingNow = modals.find((item) => item.id === activeTask.id);
        if (!playingNow) {
          const wasPlayed = wasPlayedModals.current.find(
            (item) => item === activeTask.id
          );
          if (!wasPlayed) {
            const taskDetail = info.tasks.find(
              (item) => item.id === activeTask.id
            );
            if (taskDetail) {
              setPlay(false);

              dispatch(closeModal("ArchiveTaskModal"));
              dispatch(
                openModal({
                  webinarId: info.id,
                  name: "ArchiveTaskModal",
                  id: activeTask.id,
                  imageUrl: taskDetail.entity.image,
                  hint: taskDetail.entity.hint,
                  question: taskDetail.entity.question,
                  questionId: taskDetail.entity.id,
                  answers: taskDetail.entity.answers,
                  callback: () => callbackClose(activeTask.end),
                })
              );
              wasPlayedModals.current = [
                ...wasPlayedModals.current,
                activeTask.id,
              ];
            }
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [activeTime, info, tasks]);

  const renderActiveTime = useMemo(() => {
    return formatDuration(isDrag ? sliderValue : activeTime);
  }, [activeTime, sliderValue, isDrag]);

  const renderMaxTime = useMemo(() => {
    return formatDuration(maxTime - (isDrag ? sliderValue : activeTime));
  }, [maxTime, activeTime, sliderValue, isDrag]);

  return (
    <ControlPanel>
      <TimeInfoWrapper>
        <TimeInfo>{renderActiveTime}</TimeInfo>
        <TimeInfo>{renderMaxTime}</TimeInfo>
      </TimeInfoWrapper>
      <Controls>
        <Slider
          trackStyle={{ backgroundColor: "#4ac365", height: 5 }}
          handleStyle={{
            borderColor: "#fff",
            height: 15,
            width: 15,
            marginLeft: 7,
            marginTop: -5,
            opacity: 1,
            backgroundColor: "#f1c33d",
            border: "solid 2px #fff",
            boxShadow: "none",
          }}
          activeDotStyle={{ borderColor: "#fff" }}
          railStyle={{ backgroundColor: "#aaaaaa", height: 5 }}
          onChange={(nextValue) => {
            setSliderValue(nextValue as number);
          }}
          value={isDrag ? sliderValue : activeTime}
          onBeforeChange={() => {
            setPlay(false);
            setDrag(true);
          }}
          onAfterChange={(nextValue) => {
            setCurrentTime(nextValue as number);
            setPlay(true);
            setDrag(false);
          }}
          min={0}
          max={maxTime}
          defaultValue={0}
          step={1}
        />
        <Button
          onClick={() => {
            setRate(newRate);
          }}
        >
          X{rates[rate]}
        </Button>
      </Controls>
    </ControlPanel>
  );
};

export default VideoPlayerControl;

const Controls = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Button = styled.div`
  color: #fff;
  padding: 5px;
  font-size: 15px;
  cursor: pointer;
  background: #00000085;
  border-radius: 5px;
  margin-left: 5px;
  min-width: 40px;
  text-align: center;
`;

const ControlPanel = styled.div`
  width: calc(100% - ${PADDING * 2}px);
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
`;

const TimeInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  top: -55px;
`;

const TimeInfo = styled.p`
  font-size: 0.875em;
  color: #ffffff;
`;
