import styled from "styled-components";
import bg from "./assets/images/Frame_2528-min_3.png";
import qr from "./assets/images/qr_white.svg";
import googlePlayImage from "./assets/images/googlePlayWhite.svg";
import appStoreImage from "./assets/images/appStoreWhite.svg";

const ContainerTest = styled.div`
    max-width: calc(100vw - 20px);
    margin-bottom: 140px;
    width: 100%;
    margin-inline: auto;
    @media (max-width: 768px) {
      margin-bottom: 30px !important;
    }
  `;

export default function FillerCarComponent({ExtraTitle}) {
  const Container_ = styled(ContainerTest)`
    border-radius: 24px;
    background: url(${bg});
    background-size: cover;
    background-position: center;
    overflow: hidden;
    margin-bottom: 120px!important;
       &:before {
  width: 100%;
  max-width: 757px;
  opacity: .65;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 0; 
   background-image: linear-gradient(
      0.26turn,
      rgba(0, 0, 0, 1) 56%,
      rgba(23, 23, 27, 0) 100%
    );

}
    
    position: relative;
  `;
  const Wrapper = styled.div`

  position: relative;

 
    padding: 60px 40px;
    flex-direction: column;
    max-width: 757px;
    display: flex;
     @media (max-width: 768px){
    padding: 30px;
    }
  `;
  const Title = styled(ExtraTitle)`

    color: white;
    margin-bottom: 20px;
    @media (max-width: 768px){
    font-size: 30px!important;
    line-height: 32px!important;
    }
  `;
  const HugeNumber = styled.h1`
    font-size: 98px;
    line-height: 113px;
    font-weight: 600;
    color: white;
    margin-bottom: 6px;
    @media (max-width: 768px){
    font-size: 60px!important;
    line-height: 66px!important;
    }
  `;
  const BigText = styled.h6`
    color: white;
    line-height: 31px;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 40px;
      @media (max-width: 768px){
    font-size: 14px!important;
    line-height: 22px!important;
    }
  `;
  const RightImageCollapse = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
  `;
  const QrImage = styled.div`
    width: 277px;
    height: 277px;
    background: url(${qr});
    background-size: cover;
      @media (max-width: 768px){
    display: none;
    }
  `;
  const RightImageCollapseBottom = styled.div`
    display: flex;
    justify-content: space-between;
    width: 277px;
  `;
  const RightImageCollapseBottomImage = styled.div`
    width: 132px;
    height: 38px;
    background: url(${(props) => props.src});
    background-size: cover;
  `;
  return (
    <>
      <Container_>
        <Wrapper>
          <Title>Сдать на права - легко!</Title>
          <HugeNumber>30 000+ </HugeNumber>
          <BigText>учеников уже получили права после обучения в ПДД.ТВ</BigText>
          <RightImageCollapse>
            <QrImage />
            <RightImageCollapseBottom>
              <RightImageCollapseBottomImage
                src={appStoreImage}
              ></RightImageCollapseBottomImage>
              <RightImageCollapseBottomImage
                src={googlePlayImage}
              ></RightImageCollapseBottomImage>
            </RightImageCollapseBottom>
          </RightImageCollapse>
        </Wrapper>
      </Container_>
    </>
  );
}
