import { useState, useMemo, useEffect, useCallback } from "react";
import {
  Wrapper,
  Picture,
  WrapperOptions,
  OptionItem,
  Text,
  Info,
  Send,
} from "./styledComponents";
import type { AnswerResponse } from "api/models/ticket";
import { UserAnswer } from "pages";

type TaskProps = {
  id: number;
  imageSrc: string | null;
  text: string;
  options: AnswerResponse[];
  info?: string;
  handleNext: () => void;
  handleSetStatus: (data: UserAnswer) => void;
  modeHistory?: boolean;
  answerId?: number;
};

const Task = ({
  id,
  imageSrc,
  text,
  options,
  info,
  modeHistory,
  handleNext,
  handleSetStatus,
  answerId,
}: TaskProps) => {
  const [activeOptionId, setActiveOptionId] = useState<number | null>(() => {
    if (modeHistory && answerId !== undefined) {
      const o = options.find((value) => value.id === answerId);
      if (o) {
        return o.id;
      }
      return null;
    }
    return null;
  });

  const answer = useMemo(() => {
    return options.find((item) => item.id === activeOptionId);
  }, [activeOptionId, options]);

  useEffect(() => {
    if (!modeHistory && answer && answer.correct) {
      handleNext();
    }
  }, [modeHistory, answer, handleNext]);

  const getAnswer = useCallback(
    ({ questionId, answerId, correct }: UserAnswer) => {
      const a = options.find((item) => item.id === answerId);
      if (a && !activeOptionId) {
        setActiveOptionId(answerId);
        handleSetStatus({ questionId, answerId, correct });
      }
    },
    //eslint-disable-next-line
    [activeOptionId]
  );

  return (
    <Wrapper>
      {imageSrc ? <Picture src={imageSrc} /> : null}
      <Text>{text}</Text>
      <WrapperOptions>
        {options.map((item) => {
          const status =
            item.id === activeOptionId && item.correct
              ? "ok"
              : item.id === activeOptionId && !item.correct
              ? "wrong"
              : "no-answer";
          return (
            <OptionItem
              status={status}
              onClick={() =>
                getAnswer({
                  questionId: id,
                  answerId: item.id,
                  correct: item.correct,
                })
              }
              key={item.id}
            >
              {item.answer}
            </OptionItem>
          );
        })}
      </WrapperOptions>
      {answer && !answer.correct && info && <Info>{info}</Info>}
      {!modeHistory && answer && <Send onClick={handleNext}>Далее</Send>}
    </Wrapper>
  );
};

export default Task;
