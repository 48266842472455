import { getInteractiveTaskForTeacher } from "api/InteractiveTaskAPI";
import { ITask, TTaskEntity } from "api/models/task";
import { getWebinarInteractiveTaskList } from "pages/Webinars/redux";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Task, Container } from "./styledComponents";

type WebinarTaskListProps = {
  handleSelect: (entity: TTaskEntity, index: number) => void;
  selectedTask?: number;
};

const WebinarTaskList = ({
  handleSelect,
  selectedTask,
}: WebinarTaskListProps) => {
  const dispatch = useDispatch();
  const tasks: Array<ITask> = useSelector(getWebinarInteractiveTaskList);

  useEffect(() => {
    dispatch(getInteractiveTaskForTeacher());
  }, [dispatch]);

  const handleClick = ({
    entity,
    index,
  }: {
    entity: TTaskEntity;
    index: number;
  }) => {
    handleSelect(entity, index);
  };

  return (
    <Container>
      {tasks.map(({ entity }, index) => (
        <Task
          selected={selectedTask === index}
          onClick={() => handleClick({ entity, index })}
          key={entity.question + index}
        >
          {entity.question}
        </Task>
      ))}
    </Container>
  );
};

export default WebinarTaskList;
