import { createSlice } from "@reduxjs/toolkit";

const appBarReducer = createSlice({
  name: "appBarReducer",
  initialState: { isOpen: true },
  reducers: {
    toggleAppBarOpen: (state) => {
      state.isOpen = !state.isOpen;
    },
    setAppBarOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const { toggleAppBarOpen, setAppBarOpen } = appBarReducer.actions;

export default appBarReducer.reducer;
