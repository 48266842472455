import styled from "styled-components";
import { useMemo, useCallback } from "react";
import { Button } from "components";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "store";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & div {
    flex: 1;
  }
`;

const LeftBlock = styled.div``;

const RightBlock = styled.div``;

const Title = styled.p`
  font-size: 1.35em;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 944px) {
    font-size: 1em;
    margin: 0;
  }
`;

const ButtonBack = styled(Button)`
  @media screen and (max-width: 944px) {
    height: 24px;
    margin-left: 16px;
    padding: 0;
  }
`;

const EasyHeader = ({
  title,
  afterBack,
}: {
  title: string;
  afterBack?: () => void;
}) => {
  const history = useHistory();
  const width = useSelector((state: RootState) => state.clientOptions.width);
  const isMobile = useMemo(() => width < 944, [width]);

  const handleBack = useCallback(() => {
    if (afterBack) {
      afterBack();
    } else {
      history.push("/");
    }
    //eslint-disable-next-line
  }, [afterBack]);

  return (
    <Container>
      <LeftBlock>
        <ButtonBack
          leftIcon="ArrowLeftIcon"
          size="large"
          onClick={handleBack}
          buttonTheme="secondary"
        >
          {!isMobile ? "Назад" : ""}
        </ButtonBack>
      </LeftBlock>
      <Title>{title}</Title>
      <RightBlock />
    </Container>
  );
};

export default EasyHeader;
