import { useState } from "react";

import {
  ControlPanel,
  Image,
  Info,
  Title,
  Container,
  WaveContainer,
} from "./styledComponents";
import imageStopIcon from "../../../../assets/images/Error.png";
import imagePlayIcon from "../../../../assets/images/ConfirmStartWebinarModal.svg";
import { ChatUserVoiceNotification } from "../../types";
import { Waveform } from "../../../../components";

type Props = {
  chatUserVoiceNotification: ChatUserVoiceNotification;
};

const FeedbackPanelConnection = ({ chatUserVoiceNotification }: Props) => {
  const [playAudio, setPlayAudio] = useState(false);

  const onEnd = () => {
    setPlayAudio(false);
  };

  const togglePlayAudio = () => {
    setPlayAudio((prev) => !prev);
  };

  return (
    <Container>
      <Info>
        <Title>
          <span>
            {[
              chatUserVoiceNotification.lastname,
              chatUserVoiceNotification.firstname,
              chatUserVoiceNotification.middlename,
            ].join(" ")}
          </span>{" "}
          говорит
        </Title>
        <ControlPanel>
          <WaveContainer>
            <Waveform
              url={chatUserVoiceNotification.url}
              playAudio={playAudio}
              onEnd={onEnd}
            />
          </WaveContainer>
          <Image
            width={40}
            height={40}
            src={playAudio ? imageStopIcon : imagePlayIcon}
            onClick={togglePlayAudio}
          />
        </ControlPanel>
      </Info>
    </Container>
  );
};

export default FeedbackPanelConnection;
