import { RootState } from "store";

export const getWebinarIsStarted = (state: RootState) =>
  state.webinars.isStarted;
export const getWebinarName = (state: RootState) => state.webinars.webinarName;
export const getWebinarId = (state: RootState) => state.webinars.id;
export const getWebinarChatMessages = (state: RootState) =>
  state.webinars.chatMessages;
export const getWebinarInteractiveTaskList = (state: RootState) =>
  state.webinars.interactiveTasks;
export const getWebinarTeacherQuestion = (state: RootState) =>
  state.webinars.teacherQuestions;
export const getChatUserVoiceNotifications = (state: RootState) => {
  return state.webinars.chatUserVoiceNotifications;
};
