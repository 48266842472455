import PromoBanner from "../PromoBanner";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCabinetPromoLectureWithoutSubscribeActionCreator } from "api/CabinetPromoApi";
import { MediaQueryContainer } from "containers";
import MobileCalendar from "../MobileCalendar";
import { PageTitle } from "shared";
import { MainContainerWrapper } from "./styledComponents";
import Progress from "../Progress";

const MainContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCabinetPromoLectureWithoutSubscribeActionCreator());
  }, [dispatch]);

  return (
    <MainContainerWrapper>
      <PageTitle>Мой Кабинет</PageTitle>
      <PromoBanner />
      <MediaQueryContainer maxWidth={1245}>
        <MobileCalendar />
      </MediaQueryContainer>
      <Progress />
    </MainContainerWrapper>
  );
};

export default MainContainer;
